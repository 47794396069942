import WgJustifyContent from "../../components/ui/WgJustifyContent";
import WgInput from "../../components_only_this_platform/elements/WgInput";
import WgImage from "../../components_only_this_platform/elements/WgImage";
import WgPageBody from "../../components/ui/WgPageBody";
import { useContext,useEffect, useState } from "react";
import { wgfAuthenticationCode, wgfAxiosPost } from "../../components/functions/WgFunctions";
import { wgRouteAddresses } from "../../components/WgStaticValues";
import { WgAppContext } from "../../App";
import WgBox from "../../components_only_this_platform/elements/WgBox";

import { wgsButton } from "../../components/ui/WgStyles";
import WgButton from "../../components_only_this_platform/elements/WgButton";
import WgText from "../../components_only_this_platform/elements/WgText";

const ForgotPasswordView = () => {
    const { wgAppObjects } = useContext(WgAppContext);
    const [nameSurname, setNameSurname] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [newPasswordReEnter, setNewPasswordReEnter] = useState(null);
    const [authCode, setAuthCode] = useState(null);
    const [authCodeId, setAuthCodeId] = useState(null);
    const [phone, setPhone] = useState(''); //tel degistiginde dogurulama kodunun yeniden gonderilmesi istenilecek
    const [email, setEmail] = useState(''); //mail degistiginde dogurulama kodunun yeniden gonderilmesi istenilecek
    const refreshPassword = () => {
        wgfAxiosPost('account/refresh-password', {
            phone: phone,
            email: email,
            password:newPassword,
            authenticationCode:authCode,
            authenticationCodeId:authCodeId
        }, null, null, null, () => {
            setNameSurname(null);
            setNewPassword(null);
            setNewPasswordReEnter(null);
            setAuthCode(null);
            setAuthCodeId(null);
            setPhone(null);
            setEmail(null);
            setTimeout(() => {
                wgAppObjects.functions.goToLink(wgRouteAddresses.home.index);
            }, 5000)
        }, null, true);
    }
    useEffect(() => {
        setAuthCode(null);
        setAuthCodeId(null);
    }, [phone, email])
    const setAuthenticationCode = () => {
        wgfAuthenticationCode(phone, email, nameSurname, setAuthCodeId);
    }
    return (
        <WgPageBody>
            <WgJustifyContent mt="35px" mb="15px" justifyContent="center">
                <WgImage src="https://files.gidecekvar.com/images/system/password-security.png" style={{ maxWidth: '133px' }} />
            </WgJustifyContent>
            <WgBox style={{ paddingTop: '20px', maxWidth: '320px', margin: 'auto' }}>
                <WgInput placeholder="İsim Soyisim" validType="letter" propstate={[nameSurname, setNameSurname]} />
                <WgInput placeholder="Cep Telefonu" validType="phone" propstate={[phone, setPhone]} />
                <WgInput placeholder="Eposta" validType="email" propstate={[email, setEmail]} />
                {
                    (authCodeId == null) && (
                        <WgBox style={{ marginBottom: '9px' }}>
                            <WgText variant="info" size="xs" text="Telefonunuza veya mail adresinize doğrulama kodu gönderilecektir." />
                            <WgJustifyContent mt="0px" mb="15px" justifyContent="center">
                                <WgButton style={wgsButton.normal} onClick={setAuthenticationCode} title="Kod Gönder"/>
                            </WgJustifyContent>
                        </WgBox>
                    )
                }
                {
                    (authCodeId != null) && (
                        <>
                            <WgInput placeholder="Doğrulama Kodu" type="number" propstate={[authCode, setAuthCode]} />
                            <WgInput placeholder="Yeni Şifre" type="password" propstate={[newPassword, setNewPassword]} />
                            <WgInput placeholder="Yeni Şifre Tekrar" type="password" propstate={[newPasswordReEnter, setNewPasswordReEnter]} />
                            <WgJustifyContent mt="20px" mb="15px" justifyContent="center">
                                <WgButton style={wgsButton.normal} onClick={refreshPassword} title="Şifreyi Değiştir"/>
                            </WgJustifyContent>
                        </>
                    )
                }
            </WgBox>
        </WgPageBody>
    )
}
export default ForgotPasswordView;