import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { wgfGetPropStateValue, wgfPropsStateChange } from "../../components/functions/WgFunctions";
import { useEffect, useState } from "react";

const WgSwitch = (props) => {
    const [isChecked, setIsChecked] = useState(false);
    useEffect(() => {
         //ilk degerlerini al
        if (props.itemsthisid && props.itemsstate && props.itemssetstate) {
            let _f = props.itemsstate.find(item => item.id == props.itemsthisid);
            if (_f) {
                setIsChecked(_f.isChecked);
            }
        }
        else if (props.propstate) {
            //ilk degerini al
            setIsChecked(wgfGetPropStateValue(props));
        }
    }, [])
    const onChangeHandle = (e) => {
        let _checked = e.target.checked;
        if (props.propstate) {
            wgfPropsStateChange(e, props, 'boolean');
        }
        else if (props.itemsthisid && props.itemsstate && props.itemssetstate) {
            //liste halinde ise, listeden bulup degistirir
            let _temp = props.itemsstate.map(item => {
                if (item.id == props.itemsthisid) {
                    item.isChecked = _checked;
                }
                return item;
            });
            props.itemssetstate(_temp);
        }
        setIsChecked(_checked);
    }

    return (
        <FormGroup>
            <FormControlLabel size="small" style={{ fontSize: '14px' }} control={<Switch defaultChecked={isChecked} checked={isChecked} onChange={onChangeHandle} />} label={props.label} />
        </FormGroup>
    )
}
export default WgSwitch;