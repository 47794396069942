import { TextField } from "@mui/material";
import WgBox from "./WgBox";
import { wgfIsNotNull, wgfOnChangeEvent, wgfValidation } from "../../components/functions/WgFunctions";
import { WgControlsStyle, wgStaticInputTypes } from "../../components/WgStaticValues";
import { useState } from "react";
import { WgfSnackbarFunc } from "../../App";
import WgJustifyContent from "../../components/ui/WgJustifyContent";

import WgIconEye from "../icons/WgIconEye";
import WgIconEyeSlash from "../icons/WgIconEyeSlash";
import WgButton from "./WgButton";
const _style = {
    valid: { backgroundColor: '#fff' },
    invalid: { backgroundColor: '#ff1400', color: '#fff' }
}
/*
ReactNative'de password type yok, yerine attribute ile belirtmek gerekiyor
step: Html'de decimal icin attribute eklenirken Native'de bunun type'ı var
*/
const WgInput = ({ style, type, step, min, max, validType, propstate, placeholder }) => {
    const [currentStyle, setCurrentStyle] = useState(_style.valid);
    const [passwordShow, setPasswordShow] = useState(false);
    const onchangeEvent = (value) => {
        let _setStyle = _style.valid;
        let _value = value;
        if (wgfIsNotNull([_value]) && validType) {
            //aynı text birden fazlasi icnde kullanılabilecek
            let _types = validType.split(',');
            if (_types && _types.includes('phone')) {
                if (_value == '0') {
                    //ilk karakter 0 ise sil.
                    _value = '';
                }
            }
            let _isValid = false;
            _types.forEach(item => {
                if (_isValid == false) {
                    _isValid = wgfValidation[item](_value);
                }
            })
            _setStyle = (_isValid == true ? _style.valid : _style.invalid);
        }
        setCurrentStyle(_setStyle);
        let _props = { propstate: propstate };
        wgfOnChangeEvent(_props, _value, null, null)

    }
    const onblur = (value) => {
        let _value = value;
        if (currentStyle.backgroundColor == '#ff1400' && wgfIsNotNull([_value]) && validType) {
            _value = null;
            let _props = { propstate: propstate };
            wgfOnChangeEvent(_props, _value, null, null);
            WgfSnackbarFunc("Hatalı bilgileriniz silindi.Lütfen yeniden yazınız.", "info")
        }
    }

    /*
    NOT: Input nesnesi NULL olunca React hata mesajı vermekte. Bu yüzden [??''] ekledim
    https://bobbyhadz.com/blog/react-value-prop-on-input-should-not-be-null
    */
    const getInput = () => {
        let _inputProps = {};
        _inputProps['onBlur'] = (e)=>onblur(e.target.value);
        _inputProps['onChange'] = (e) => onchangeEvent(e.target.value);
        _inputProps['placeholder'] = placeholder ?? '';
        /*STYLE*****************************************/
        _inputProps['style'] = { ...WgControlsStyle, width: '100%', ...style };
        /*TYPE*****************************************/
        let _inputType = wgStaticInputTypes.js[type] ?? wgStaticInputTypes.js.text; //text ortak default type
        if (step) {
            _inputProps['step'] = step;
        }
        if (type == 'password') {
            if (passwordShow == true) {
                _inputType = wgStaticInputTypes.js.text;
            }
            else {
                _inputType = type;
            }
        }
        _inputProps['type'] = _inputType;
        /*VALUE****************************************/
        if (propstate) {
            if (propstate[2]) {
                _inputProps['value'] = propstate[0][propstate[2]] ?? '';
            } else {
                _inputProps['value'] = propstate[0] ?? '';
            }
        }
        /*****************************************/
        return _inputProps;
    }
    return (
        <WgBox style={{ borderBottom: 'none' }}>
            {
                (type == "password") ? (
                    <WgJustifyContent>
                        <WgBox style={{ width: '100%', position: 'relative' }}>
                            <TextField size="small" {...getInput()} />
                            <WgBox style={{ position: 'absolute', display: 'flex', alignItems: 'center', height: '100%', top: 0, right: 0 }}>
                                <WgButton size="small" style={{background:'transparent !important',boxShadow:'none',fontSize:'15px'}} onClick={() => setPasswordShow(!passwordShow)} icon={(passwordShow == true) ? (<WgIconEye />) : (<WgIconEyeSlash />)} />
                            </WgBox>
                        </WgBox>
                    </WgJustifyContent>
                ) : (<TextField size="small" {...getInput()} />)
            }
        </WgBox>


    )
}
export default WgInput;