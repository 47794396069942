import { createSlice } from "@reduxjs/toolkit";
const initialState = [];
const rdxToastMessages = createSlice({
    name: 'reduxToastMessage',
    initialState: initialState,
    reducers: {
        addToastMessage: (state, action) => {
            state.push({
                id: (new Date().getTime()),
                status: action.payload.status,
                heading: action.payload.heading,
                variant: action.payload.variant,
                message: action.payload.message
            });
        },
        deleteToastMessage: (state, action) => {
            if (state) {
                state = state.map(item => item.id != action.payload.id);
            }
        }
    }
})
export const { addToastMessage, deleteToastMessage } = rdxToastMessages.actions;
export default rdxToastMessages.reducer;