import { useState } from "react";
import { wgfAxiosPost, wgfFromToAddress, wgfObjectClone } from "../../functions/WgFunctions";
import { useContext } from "react";
import { WgAppContext } from "../../../App";
import WgSelectMultiItem from "../../../components_only_this_platform/elements/WgSelectMultiItem";

import WgWillGoCard from "../willGoDetails/WgWillGoCard";
import { useEffect } from "react";
import WgBox from "../../../components_only_this_platform/elements/WgBox";
import { wgfMapDatas, wgfMapGoToPosition } from "../../../components_only_this_platform/functions/WgMapScripts";
import WgJustifyContent from "../WgJustifyContent";
import WgImage from "../../../components_only_this_platform/elements/WgImage";
import WgDataNotFound from "../WgDataNotFound";

import { wgsButton } from "../WgStyles";
import WgButton from "../../../components_only_this_platform/elements/WgButton";
import WgText from "../../../components_only_this_platform/elements/WgText";

const WgForWmvWillGos = () => {
    const { wgAppObjects, appData, wgGenWillGos, setWgGenWillGos, setWgGenSelectedWillGo, setWgGenMarkerWillGos, } = useContext(WgAppContext);
    const [cities, setCities] = useState(wgfObjectClone(appData.cities));
    useEffect(() => {
        setWgGenWillGos(null); //ilk acilista null yap,(generic olgudu icin diger sayfalardan veriyi tasimasin)
    }, [])
    useEffect(() => {
        if (cities) {
            let _selectedCities = cities.filter(f => f.selected == true);
            if (_selectedCities && _selectedCities.length > 0) {
                if (wgAppObjects.mainMap.map) {
                    if (_selectedCities.length == 1) {
                        //bitne secilmis ise ona odakla
                        wgfMapGoToPosition(wgAppObjects.mainMap.map, _selectedCities[0].location);
                        if (wgAppObjects.mainMap.map.getZoom() < 10) {
                            setTimeout(() => {
                                wgAppObjects.mainMap.map.setZoom(10);
                            }, 2000)        
                        }
                    }
                    else {
                        _selectedCities.map(item => {
                            /*
                            Seçilen şehir haritada görünmüyorsa (-)zoom uygular.
                            */
                            const bounds = wgAppObjects.mainMap.map.getBounds();
                            const lngLat = [item.location.lng, item.location.lat];
                            if (bounds.contains(lngLat) == false) {
                                wgAppObjects.mainMap.map.setZoom(wgAppObjects.mainMap.map.getZoom() - 2);
                            }
                        })
                    }
                }
                wgAppObjects.route.clearRoute();
                let params = {
                    startCities: cities.filter(f => f.selected == true).map(item => item.value),
                    customerRatings: [1, 2, 3, 4, 5],/*müşteri puanına göre filtre icindi */
                    onlyRoundTrip: false
                }
                wgfAxiosPost('willgo/willgos-filter-formap', params, null, setWgGenWillGos, null);
            }
            else {
                setWgGenWillGos(null);
            }
        }
    }, [cities])

    useEffect(() => {
        if (wgGenWillGos) {
            let _markers = [];
            wgGenWillGos.map((item) => {
                _markers.push(
                    {
                        position: item.fromLocation,
                        iconUrl: appData.services.find(f => f.id == item.serviceId).logo,
                        iconSize: wgfMapDatas.markerIconSize,
                        callback: null,
                        popup: {
                            title: '',
                            body: (
                                <>
                                    <WgBox>{wgfFromToAddress(item)}</WgBox>
                                    <WgButton style={wgsButton.normal} size="small" onClick={() => {setWgGenSelectedWillGo(item)}} title="Detay"/>
                                </>
                            )
                        }
                    }
                )
            })
            setWgGenMarkerWillGos(_markers.length > 0 ? _markers : null);
        }
        else {
            setWgGenMarkerWillGos(null);
        }
    }, [wgGenWillGos])

    return (
        <>
            {
                (cities && cities.find(f => f.selected == true) == null) && (
                    <>
                        <WgJustifyContent justifyContent="center">
                            <WgImage style={{ width: '173px' }} src="https://img.freepik.com/free-vector/smartphone-mobile-navigation_1284-12708.jpg?w=740&t=st=1690618957~exp=1690619557~hmac=c418f822bacfd15eb5431aef25c017bdbcdeea3e6b0de48875ee47826408cb6e" />
                        </WgJustifyContent>
                        <WgText variant="info" align="center" text="İlanları görebilmek için şehir seçiniz." />
                    </>
                )
            }
            {
                cities && (
                    <WgSelectMultiItem label="Şehir Ekle.." items={cities} setItemsState={setCities} />
                )
            }
            {
                ((wgGenWillGos && wgGenWillGos.length > 0)) ? wgGenWillGos.map((item, index) => {
                    return (
                        <WgBox key={index} style={{ paddingBottom: '10px' }}>
                            <WgWillGoCard willGo={item} />
                        </WgBox>
                    );
                }) : (
                    ((cities.find(f => f.selected == true) != null)) && (
                        <WgDataNotFound />
                    )
                )
            }
        </>
    )
}
export default WgForWmvWillGos;