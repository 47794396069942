import { Fragment, useEffect } from "react";
import { useState } from "react";
import { wgfAxiosGet } from "../functions/WgFunctions";
import WgBox from "../../components_only_this_platform/elements/WgBox";


import WgText from "../../components_only_this_platform/elements/WgText";

const WgFaqs = () => {
    const [groups, setGroups] = useState(null);
    useEffect(() => {
        wgfAxiosGet('/faq/get-all-groups', null, setGroups, null, null, null);
    }, []);
    return (
        <>
            {
                groups && groups.map((item, index) => {
                    return (
                        <WgBox>
                            <WgText variant="h4" text={item.name} />
                            {
                                item.faqs && item.faqs.map((fitem, findex) => {
                                    return (
                                        <Fragment key={findex}>
                                            <WgText variant="h6" text={fitem.question} />
                                            <WgText variant="info" text={fitem.answer} />
                                        </Fragment>
                                    )
                                })
                            }
                        </WgBox>
                    )
                })
            }




        </>
    )
}
export default WgFaqs;