export const wgsButtonIcon = {
    width: '33px',
    height: '33px',
    background: '#fff',
    borderRadius: '100px',
    boxShadow: 'rgb(0, 0, 0) 4px 4px 16px -6px',
    padding: '0px',
    minWidth: '33px',
    maxWidth: '33px',
    fontSize: '21px',
    display: 'flex',
    margin: '3px',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#333'
}
export const wgsButtonStatic = {
    borderRadius: '7px',
    border: 'none',
    margin: '3px',
    padding: '7px 13px',
    minWidth: '47px',
    marginBottom:'10px',
    color: '#333',
    backgroundColor: '#fff',
    boxShadow: 'rgb(51, 51, 51) 0px 5px 15px -9px'
}
export const wgsButton = {
    normal: wgsButtonStatic,
    normalDarkGradient: {
        ...wgsButtonStatic,
        color: '#fff',
        backgroundImage: 'linear-gradient(225deg, #63656d 0%, #2d2e31 50%, #4b4c50 100%)'
    }
    ,
    active: {
        ...wgsButtonStatic,
        backgroundColor: '#ffd800'
    }
}
export const wgsGalleryBoxStyles = {
    container: { display: 'flex', flexWrap: 'wrap' },
    imageBox: { position: 'relative', overflow: 'hidden', width: '46%', paddingTop: '46%', marginRight: '2%', marginBottom: '5px', border: '2px solid #ccc', borderRadius: '10px', height: 'auto' },
    imageButtonArea: { position: 'absolute', zIndex: 3, top: 0, left: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' },
    imageButtonBox: { width: '34px', height: '34px' },
    deleteButtonArea: { position: 'absolute', zIndex: 4, top: '5px', right: '5px' },
    deleteButton: { backgroundColor: '#f00', color: '#fff' },
    image: { position: 'absolute', top: 0, left: 0, width: '100%' }
}