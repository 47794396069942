import { Drawer } from "@mui/material";
import WgJustifyContent from "../../components/ui/WgJustifyContent";
import WgIconXMark from "../icons/WgIconXMark";

import { wgsButtonIcon } from "../../components/ui/WgStyles";
import WgBox from "./WgBox";

import WgButton from "./WgButton";

/*["left", "right", "top", "bottom"] */
const WgDrawer = (props) => {
  const { drawerShow, setDrawerShow } = props.openState;
  const container = window !== undefined ? () => window.document.body : undefined;
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerShow(open);
  };

  return (
    <Drawer
      container={container}
      variant="temporary"
      anchor={props.anchor ?? "right"}
      open={drawerShow}
      onClose={toggleDrawer(false)}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        overflow: "hidden",
        overflowY: "scroll",
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 320 },
      }}
    >
      {
        (props.isAppendCloseBtn && props.isAppendCloseBtn == true) && (
          <WgBox style={{ paddingRight: '20px', paddingTop: '10px' }}>
            <WgJustifyContent>
              <WgButton onClick={() => setDrawerShow(false)} style={{...wgsButtonIcon,backgroundColor:'#ffd800'}} icon={<WgIconXMark />}/>
            </WgJustifyContent>
          </WgBox>
        )
      }

      {props.children}
    </Drawer>
  );
};
export default WgDrawer;
