/**
 * Kullanıcının konum bilgisini alır
 * @param {*} setState 
 * @param {*} callback 
 */
export const wgfGetCurrentPosition = (setState, callback) => {
    navigator.geolocation.getCurrentPosition((position) => {
        let _pos = { lat: position.coords.lat, lng: position.coords.lng };
        if (callback) {
            callback(_pos);
        }
        if (setState) {
            setState(_pos);
        }
    });
}
export const setLocalStorage=(key,value)=>{
    localStorage.setItem(key,value);
}
export const getLocalStorage=(key)=>{
   return localStorage.getItem(key);
}
export const removeLocalStorage=(key)=>{
    localStorage.removeItem(key);
}
export const getWindowWidth=()=>{
  return window.innerWidth;
}
export const getWindowHeight=()=>{
    return window.innerHeight;
}