

import { wgfOnChangeEvent } from "../../components/functions/WgFunctions";
/*
rows={5}
*/
const WgTextarea = ({ rows, value, propstate, placeholder, style, maxlength }) => {
    let _style = { marginBottom: '10px', width: '100%', minHeight: '99px', backgroundColor: '#fff', resize: 'none', ...style };
    const onchangeEvent = (e) => {
        let _props = { propstate: propstate };
        wgfOnChangeEvent(_props, e, null, null)
    }
    const getElement = () => {
        let _props = {};
        _props['value'] = value ?? '';
        _props['placeholder'] = placeholder ?? '';
        _props['rows'] = rows ?? 1;
        _props['onChange'] = (e) => onchangeEvent(e.target.value); //NOT:Event'i ayarla
        _props['style'] = _style;
        _props['maxLength'] = maxlength ?? 300;
        if (propstate) {
            if (propstate[2]) {
                _props['value'] = propstate[0][propstate[2]];
            }
            else {
                _props['value'] = propstate[0];
            }
        }
        return _props;
    }
    return (
        <textarea {...getElement()}></textarea>
    )
}
export default WgTextarea;