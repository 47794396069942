import { useContext, useEffect } from "react";
import WgWillGoCard from "../willGoDetails/WgWillGoCard";
import WgJustifyContent from "../WgJustifyContent";

import WgBox from "../../../components_only_this_platform/elements/WgBox";
import WgDataNotFound from "../WgDataNotFound";
import { wgsButton } from "../WgStyles";
import { WgAppContext } from "../../../App";
import WgButton from "../../../components_only_this_platform/elements/WgButton";

const WgForWmvMyOffers = ({ setActiveTab, willGosTabKey }) => {
    const { wgGenWillGos,wgAppObjects } = useContext(WgAppContext);
    useEffect(()=>{
        wgAppObjects.functions.getMyOffers();
    },[])
    return (
        <>
            {
                (wgGenWillGos && wgGenWillGos.length > 0) ? (
                    wgGenWillGos.map((item, index) => {
                        return (
                            <WgBox key={index} style={{ paddingBottom: '10px' }}>
                                <WgWillGoCard willGo={item} />
                            </WgBox>

                        )
                    })
                ) : (
                    <>
                        <WgDataNotFound />
                        <WgJustifyContent justifyContent="center" mt="30px">
                            <WgButton style={wgsButton.normal} onClick={() => setActiveTab(willGosTabKey)} title="İlanları Göster"/>
                        </WgJustifyContent>
                    </>
                )

            }
        </>
    )
}
export default WgForWmvMyOffers;