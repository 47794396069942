import { useContext } from "react";
import WgCarousel from "../../components_only_this_platform/elements/WgCarousel";
import WgJustifyContent from "../../components/ui/WgJustifyContent";
import WgLoginForm from "../../components/ui/WgLoginForm";
import { WgAppContext } from "../../App";
import WgImage from "../../components_only_this_platform/elements/WgImage";
import WgBox from "../../components_only_this_platform/elements/WgBox";
import WgPageBody from "../../components/ui/WgPageBody";

import WgText from "../../components_only_this_platform/elements/WgText";
import WgLogoSvgWithText from "../../components_only_this_platform/ui/WgLogoSvgWithText";
import WgSpaceVertical from "../../components_only_this_platform/elements/WgSpaceVertical";

const IndexView = () => {
  const { appData } = useContext(WgAppContext);
  let carouselServices = [];
  if (appData && appData.services) {
    appData.services.forEach(item => {
      carouselServices.push(
        <WgBox style={{ width: 'fit-content', minWidth: '60px' }}>
          <WgJustifyContent justifyContent="center">
            <WgImage src={item.logo} style={{ width: '45px' }} />
          </WgJustifyContent>
          <WgText variant="info" size="sm" align="center" text={item.name} />
        </WgBox>
      );
    })
  }
  return (
    <>
      <WgSpaceVertical />
      <WgJustifyContent justifyContent="center" mb="13px">
        <WgLogoSvgWithText color="#333" width={133} />
      </WgJustifyContent>
      <WgCarousel spaceBetween={13} items={carouselServices} />
      <WgBox style={{ maxWidth: '96%', margin: 'auto' }}>
        <WgText variant="h4" align="center" text="Ulaşım ihtiyanıcınız için ilan verin." />
        <WgText variant="info" align="center" text="En uygun teklifi değerlendirin." />
      </WgBox>
      <WgPageBody>
        <WgBox style={{ paddingTop: '20px', maxWidth: '320px', margin: 'auto' }}>
          <WgLoginForm />
        </WgBox>

      </WgPageBody>
    </>
  )
}
export default IndexView;