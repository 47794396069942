import { useContext, useEffect } from "react";
import { wgfDateToText } from "../../components/functions/WgFunctions";
import WgBox from "../../components_only_this_platform/elements/WgBox";
import WgText from "../../components_only_this_platform/elements/WgText";
import WgJustifyContent from "../../components/ui/WgJustifyContent";
import { WgAppContext } from "../../App";
import WgPageBody from "../../components/ui/WgPageBody";

import WgWillGoCard from "../../components/ui/willGoDetails/WgWillGoCard";


import WgDataNotFound from "../../components/ui/WgDataNotFound";

import WgButtonRemove from "../../components/elements/WgButtonRemove";
const NotificationsView = () => {
    const { wgAppObjects, wgGenNotifications, setWgGenNotifications } = useContext(WgAppContext);
    useEffect(() => {
        wgAppObjects.functions.getMyNotifications();
    }, []);
    const deleteEvent = (id) => {
        setWgGenNotifications(wgGenNotifications.filter(f => f.id != id))
    }
    return (
        <WgPageBody>
            {
                (wgGenNotifications && wgGenNotifications.length > 0) ? (
                    wgGenNotifications.map((item, index) => {
                        return (
                            <WgBox key={index} style={{ padding: '10px', marginBottom: '10px' }}>
                                <WgJustifyContent justifyContent="space-between" alignItems="flex-start">
                                    <WgBox>
                                        <WgText variant="h6" text={item.heading} />
                                        <WgText variant="info" size="xs" text={(`${item.subject} | ${wgfDateToText(item.date)}`)} />
                                    </WgBox>
                                    <WgBox style={{ width: '40px' }}>
                                        <WgButtonRemove url="notification/delete" title="Bildirim silinecek." message="Bildiriminiz silinecek. Devam edilsin mi?" id={item.id} callback={() => deleteEvent(item.id)}/>
                                    </WgBox>
                                </WgJustifyContent>
                                <WgText variant="info" style={{ fontSize: '15px' }} text={item.content}/>
                                {
                                    /**ilan açıklam kartı oluştur */
                                    (item.willGo && item.willGo.fromCityName) && (
                                        <WgWillGoCard willGo={item.willGo} />
                                    )
                                }
                            </WgBox>
                        )
                    })
                ) : (
                    <WgDataNotFound message="Bildiriminiz bulunmamakta." />
                )
            }
        </WgPageBody>
    )
}
export default NotificationsView;
