import { useContext, useEffect, useState } from 'react';
import { isHasLocationValues, wgGetAddresLocation, wgfAxiosGet, wgfAxiosPost, wgfIsNotNull, wgfObjectClone } from '../functions/WgFunctions';
import WgReadingInfo from '../../components_only_this_platform/elements/WgReadingInfo';

import { WgAppContext, WgfSnackbarFunc } from "../../App";
import WgSelect from "../../components_only_this_platform/elements/WgSelect";
import WgBox from "../../components_only_this_platform/elements/WgBox";
import { wgfMapDatas, wgfMapGoToPosition } from "../../components_only_this_platform/functions/WgMapScripts";
import WgInput from '../../components_only_this_platform/elements/WgInput';

import WgJustifyContent from '../ui/WgJustifyContent';

import WgCheckbox from '../../components_only_this_platform/elements/WgCheckbox';
import WgSwitch from '../../components_only_this_platform/elements/WgSwitch';

import { wgsButton } from '../ui/WgStyles';
import WgSpaceVertical from '../../components_only_this_platform/elements/WgSpaceVertical';
import WgButton from '../../components_only_this_platform/elements/WgButton';
import WgText from '../../components_only_this_platform/elements/WgText';

/*
City: şehir
Country: ilçe
District: mahalle/köy
Hamlet : mezra/küçük köy

*/

/*
  Adres bilgileri aşağıdaki model gibi gonderilecek
  address = {
      name:null, optional
      cityId: 0,
      cityName: '',
      countryId: 0,
      countryName: '',
      districtId: 0,
      districtName: '',
      address: '',
      location: ""
  }
  */
 
const WgAddress = ({ address, isSelectRegisteredAddress, isAddMyAddresses, callback, addressState, modalShow, modalShowState }) => {
    const newAddress = wgfObjectClone(address);
    const { appData, wgAppObjects, setWgGenMarkerAddress, wgGenMapSelectedLocation } = useContext(WgAppContext);
    const [countries, setCountries] = useState(null);
    const [districts, setDistricts] = useState(null);

    const [selectedAddressName, setSelectedAddressName] = useState((isAddMyAddresses == true && newAddress.name) ? newAddress.name : null);
    const [selectedCity, setSelectedCity] = useState((newAddress.cityId && newAddress.cityId > 0) ? newAddress.cityId : 34);
    const [selectedCountry, setSelectedCountry] = useState((newAddress.countryId && newAddress.countryId > 0) ? newAddress.countryId : null);
    const [selectedDistrict, setSelectedDistrict] = useState((newAddress.districtId && newAddress.districtId > 0) ? newAddress.districtId : null);
    const [selectedAddress, setSelectedAddress] = useState(newAddress.address);
    const [addMyAddresses, setAddMyAddresses] = useState(isAddMyAddresses ?? false);
    const [isSelectMyRegisteredAddress, setIsSelectMyRegisteredAddress] = useState(false); //kayıtlı adres seçilecek mi
    const [myAddresses, setMyAddresses] = useState(null);
    const [myAddress, setMyAddress] = useState(null);
    const [switchShow, setSwitchShow] = useState(isSelectRegisteredAddress??false);
    const [myAddressesForSelect, setMyAddressesForSelect] = useState([]);//adresleri select nesnesi icin duzenler
    const [selectedRegistredAddress, setSelectedRegistredAddress] = useState(null); //secilen kayitli address
    const [selectedAddressLocation, setSelectedAddressLocation] = useState(newAddress.location);//müşteriden konumu seçmesini istemeden önce mahlle/köy konumuna haritayı odaklat.
    //Generic State'e lokasyon eklenmis ise secili locationSatate'e yazdir.
    useEffect(() => {
        if (wgGenMapSelectedLocation) {
            setSelectedAddressLocation(wgGenMapSelectedLocation);
        }
    }, [wgGenMapSelectedLocation])
    useEffect(() => {
        /*Componnet kapatilirken isaretlenen adrelerini markerini temizle */
        return () => {
            setWgGenMarkerAddress(null);
        }
    }, [])
    useEffect(() => {
        if (isSelectMyRegisteredAddress == true && (!myAddresses || myAddresses.length == 0)) {
            setMyAddress(null);
            //manuel doldurulan adres verilerini sifirla
            clear(true);
            //kayıtlı adreslerini getir
            wgfAxiosGet('address/get-addresses', null, setMyAddresses, null);
        }
    }, [isSelectMyRegisteredAddress])

    useEffect(() => {
        if (myAddresses) {
            if(myAddresses.length > 0){
                setMyAddressesForSelect(myAddresses.map(item => {
                    return { text: item.name, value: item.id, selected: false }
                }));
            }
            else{
                setSwitchShow(false);
                setIsSelectMyRegisteredAddress(false); //eger kayitli adresi yoksa adres secimi yapilamaz
            }
        }
        
    }, [myAddresses])
    useEffect(() => {
        if (selectedRegistredAddress) {
            wgfAxiosPost('address/get', { id: selectedRegistredAddress }, null, setMyAddress, null);
        }
    }, [selectedRegistredAddress])
    useEffect(() => {
        if (myAddress) {
            createMarker(myAddress.location);
        }
    }, [myAddress])

    useEffect(() => {
        if (isHasLocationValues(selectedAddressLocation) || isHasLocationValues(address.location)) {
            createMarker(isHasLocationValues(address.location) === true ? address.location : selectedAddressLocation)
        }
    }, [selectedAddressLocation, address]);

    useEffect(() => {
        if (selectedCity) {
            wgfAxiosGet('country/getall/' + selectedCity, null, setCountries);
        }
    }, [selectedCity])

    useEffect(() => {
        if (selectedCountry) {
            wgfAxiosGet('district/getall/' + selectedCountry, null, setDistricts);
        }
    }, [selectedCountry])

    useEffect(() => {
        if (selectedDistrict && selectedCity && selectedCountry && appData.cities && countries && districts) {
            //mahalle-koy secimide yapilinca konum araması yap
            let _city = appData.cities.find(f => f.value == selectedCity ?? newAddress.cityId);
            let _country = countries.find(f => f.value == selectedCountry ?? newAddress.countryId);
            let _district = districts.find(f => f.value == selectedDistrict);
            if (_city && _country && _district) {
                wgGetAddresLocation(selectedDistrict, [_city.text, _country.text, _district.text], setSelectedAddressLocation, () => {
                    WgfSnackbarFunc("Haritadan adresin konumunu işaretleyiniz.", 'info');
                })
            }
        }
    }, [selectedDistrict])

    const clear = (isCountry = false) => {
        /*
          34 default değer olduğu için, başka bir şehir seçilmemiş ise [Temizle] butonuna tıklayınca ilçeler sıfırlanıyor,
           Şehir değişmeyeceği için ilçe tekrar yüklenemez, bu yüzden 34 harici ise ilçeleri sıfırlat.
         */
        if (isCountry == true) {
            //setCountries(null);
            setSelectedCountry(null);
        }
        /*Aşağıdakiler her halükarda null olacak */
        setDistricts(null);
        setSelectedDistrict(null);
        setSelectedAddressLocation(null);
        createMarker(null);
    }
    const save = () => {
        if (isSelectMyRegisteredAddress) {
            addressState(myAddress);
        }
        else {
            newAddress.cityId = 34;
            newAddress.cityName = 'İstanbul';
            newAddress.countryId = null;
            newAddress.countryName = null;
            newAddress.districtId = null;
            newAddress.districtName = null;
            newAddress.address = null;
            newAddress.location = null
            let _city = appData.cities.find(f => f.value == selectedCity ?? newAddress.cityId);
            if (_city) {
                newAddress.cityId = _city.value ?? 0;
                newAddress.cityName = _city.text ?? "";
                let _country = countries ? countries.find(f => f.value == selectedCountry ?? newAddress.countryId) : null;
                if (_country) {
                    newAddress.countryId = _country.value ?? 0;
                    newAddress.countryName = _country.text ?? "";
                    let _district = districts ? districts.find(f => f.value == selectedDistrict ?? newAddress.districtId) : null;
                    if (_district) {
                        newAddress.districtId = _district.value ?? 0;
                        newAddress.districtName = _district.text ?? "";
                    }
                }
            }
            newAddress.location = selectedAddressLocation ?? address.location;
            newAddress.address = selectedAddress;
            if (wgfIsNotNull([newAddress.location, newAddress.address])) {
                if (addMyAddresses == true) {
                    newAddress['name'] = selectedAddressName;
                    //adresi kaydet
                    wgfAxiosPost('address/set-address', newAddress, null, null, null, () => {
                        if (callback) {
                            callback();
                        }
                    });
                }
                addressState(newAddress);
            }
            else {
                WgfSnackbarFunc("Haritadan adresin konumunu işaretleyiniz.", 'info');
            }
        }
        exit();
    }
    const exit = () => {
        modalShowState(false);
    }
    const createMarker = (location) => {
        if (location) {
            setWgGenMarkerAddress(
                [
                    {
                        position: location,
                        iconUrl: wgfMapDatas.iconUrls.default,
                        iconSize: wgfMapDatas.markerIconSize,
                        callback: null,
                        popup: null
                    }
                ]
            )
            wgfMapGoToPosition(wgAppObjects.mainMap.map, location);
        }
        else {
            setWgGenMarkerAddress(null);
        }
    }
    return (
        (modalShow) && (
            <WgBox style={{ position: 'absolute', top: '20px', left: 0, width: '100%', overflow: 'auto', zIndex: 2000, height: '100%', backgroundColor: '#fff' }}>
                <WgSpaceVertical/>
                <WgBox style={{ maxWidth: '300px', paddingLeft: '10px', paddingBottom: '30px', margin: 'auto', maxHeight: `${wgAppObjects.currentStyleHeight}px`, overflow: 'auto' }}>
                    <WgBox>
                        {
                            (isSelectRegisteredAddress)&&(
                                (switchShow == true) ? (
                                    <WgSwitch label="Kayıtlı adreslerimden seçeceğim" propstate={[isSelectMyRegisteredAddress, setIsSelectMyRegisteredAddress]}></WgSwitch>
                                ):(
                                    <WgText variant="info" align="center" text="Kayıtlı adresiniz bulunmamaktadır."/>
                                )
                            )
                        }
                        {
                            (isSelectMyRegisteredAddress==true) ? (
                                <WgSelect style={{ color: '#fff' }} propstate={[selectedRegistredAddress, setSelectedRegistredAddress]} items={myAddressesForSelect} />
                            ) : (
                                <>
                                    {
                                        (appData && appData.cities) ? (
                                            <WgBox style={{ paddingTop: '15px' }}>
                                                <WgSelect
                                                    wgOnChange={() => clear(true)}
                                                    label="Şehir"
                                                    defaultValue={selectedCity}
                                                    propstate={[selectedCity, setSelectedCity]}
                                                    items={appData.cities}
                                                />
                                            </WgBox>
                                        ) : (
                                            <WgReadingInfo />
                                        )
                                    }
                                    {
                                        (countries != null) && (
                                            <WgSelect
                                                label="İlçe"
                                                wgOnChange={() => clear(false)}
                                                defaultValue={selectedCountry}
                                                propstate={[selectedCountry, setSelectedCountry]}
                                                items={countries}
                                            />
                                        )
                                    }
                                    {
                                        (districts != null) && (
                                            <WgSelect
                                                label="Mahalle/Köy"
                                                defaultValue={selectedDistrict}
                                                propstate={[selectedDistrict, setSelectedDistrict]}
                                                items={districts}
                                            />
                                        )
                                    }
                                    {
                                        (selectedDistrict != null) && (
                                            <WgInput propstate={[selectedAddress, setSelectedAddress]} placeholder="Adres" />
                                        )
                                    }
                                    {
                                        /*Eger adres adı girilecekse Adreslerime kaydet secenegini sunmaya gerek yok */
                                        ((selectedAddressLocation != null && selectedDistrict != null) && isAddMyAddresses != true) && (
                                            <WgCheckbox label="Adreslerime kaydet" propstate={[addMyAddresses, setAddMyAddresses]}></WgCheckbox>
                                        )
                                    }
                                    {
                                        (addMyAddresses == true) && (
                                            <WgInput placeholder="Adres Adını Yazınız*" propstate={[selectedAddressName, setSelectedAddressName]} />
                                        )
                                    }
                                    {
                                        (selectedAddressLocation != null && selectedDistrict != null) && (
                                            <WgText variant="info" text="Konumu haritadan işaretleyiniz."/>
                                        )
                                    }
                                </>
                            )
                        }
                        <WgJustifyContent mt="10px">
                            <WgButton onClick={() => modalShowState(false)} title="İptal"  style={{marginRight: '10px',...wgsButton.normal}}/>
                            {
                                /*kayıtlı adreste temizleme olmayacak*/
                                (isSelectMyRegisteredAddress == false) && (
                                    <WgButton onClick={() => clear(true)} style={{marginRight: '10px',...wgsButton.normal}} title="Temizle"/>
                                )
                            }

                            {
                                (((selectedCity && selectedCountry != null && selectedDistrict != null))||(myAddress!=null)) && (<WgButton onClick={save} style={wgsButton.normal} title="Tamam" />)
                            }
                        </WgJustifyContent>
                    </WgBox>
                </WgBox>
            </WgBox>
        )
    )
}
export default WgAddress;