import { useEffect, useState } from "react"
import { wgfAxiosGet } from "../functions/WgFunctions";
import WgModal from "../../components_only_this_platform/elements/WgModal";
import WgReadingInfo from "../../components_only_this_platform/elements/WgReadingInfo";
import WgText from "../../components_only_this_platform/elements/WgText";
import WgButton from "../../components_only_this_platform/elements/WgButton";

const WgKvkkOrTermsOfUse = ({ showState, setShowState, isKvkk }) => {
    const [text, setText] = useState(null);
    useEffect(() => {
        if (isKvkk == true) {
            wgfAxiosGet('app-data/get-kvkk', null, setText, null);
        }
        else {
            wgfAxiosGet('app-data/get-terms-of-use', null, setText, null);
        }
    }, [showState])
    return (
        <>
            <WgButton onClick={() => setShowState(!showState)} title={(isKvkk == true ? 'Kvkk Metni' : 'Kullanım Şartları')}/>
            {
                (showState) && (
                    <WgModal maxwidth={'700px'} open={showState} onClose={() => setShowState(false)}>
                        {
                            (text) ? (
                                <WgText text={text}/>
                            ) : (
                                <WgReadingInfo />
                            )
                        }
                    </WgModal>
                )
            }
        </>
    )
}
export default WgKvkkOrTermsOfUse;