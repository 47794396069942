import { wgfDateToText, wgfFromToAddress } from "../../functions/WgFunctions";
import WgJustifyContent from "../WgJustifyContent";
import { useContext } from "react";
import WgText from "../../../components_only_this_platform/elements/WgText";
import { WgAppContext } from "../../../App";
import WgImage from "../../../components_only_this_platform/elements/WgImage";
import WgBox from "../../../components_only_this_platform/elements/WgBox";
import { wgfMapGoToPosition } from "../../../components_only_this_platform/functions/WgMapScripts";
import { getWillGoStatusText } from "../../WgStaticValues";

import { wgsButtonIcon } from "../WgStyles";

import WgIconChevronRight from "../../../components_only_this_platform/icons/WgIconChevronRight";

import WgButton from "../../../components_only_this_platform/elements/WgButton";

const WgWillGoCard = ({ willGo }) => {
    const { wgAppObjects, wgGenWillGos, setWgGenWillGos, appData } = useContext(WgAppContext);
    const goToPosition = () => {
        wgfMapGoToPosition(wgAppObjects.mainMap.map, willGo.fromLocation)
    }
    const handeDetails = () => {
        wgAppObjects.functions.getWillGoDetails(willGo);
        if (willGo.isMyWillGo == false) {
            if (willGo.isIDetailViewed != true && wgGenWillGos) {
                let _temp = wgGenWillGos.map(item => {
                    if (willGo.id == item.id) {
                        item.isIDetailViewed = true;
                    }
                    return item;
                })
                setWgGenWillGos(_temp);
            }
        }
    }
    const getOfferText = () => {
        if (willGo.myOffer && willGo.myOffer > 0) {
            return <WgText variant="info" size="sm" text={`Teklifim:₺${willGo.myOffer}`} />
        }
        else if (willGo.selectedOfferPrice) {
            if(willGo.minOffer < willGo.selectedOfferPrice){
                return <WgText variant="info" size="sm" text={`Onaylanan:₺${willGo.selectedOfferPrice} / En düşük:${willGo.minOffer}`} />
            }
            else{
                return <WgText variant="info" size="sm" text={`Onaylanan:₺${willGo.selectedOfferPrice}`} />
            }
        }
        else {
            if (willGo.minOffer && willGo.minOffer > 0) {
                return <WgText variant="info" size="sm" text={`En düşük teklif:₺${willGo.minOffer}`} />
            }
            else {
                return <WgText variant="info" size="sm" text="Henüz teklif sunulmadı." />;
            }
        }
    }
    return (
        (appData && appData.services && willGo) && (
            <>
                <WgBox style={{ borderRadius: '10px', overflow: 'hidden' }}>
                    <WgBox style={{ background: '#fff', borderBottom: '1px solid #ccc', padding: '7px' }}>
                        <WgJustifyContent justifyContent="flex-start" alignItems="flex-start">
                            <WgBox onClick={goToPosition} style={{ width: '70px', cursor: 'pointer' }}>
                                <WgBox style={{ borderRight: '1px solid #ddd', marginRight: '7px', pointerEvents: 'none' }}>
                                    <WgJustifyContent justifyContent="center">
                                        <WgImage src={appData.services.find(f => f.id == willGo.serviceId).logo} width={40} />
                                    </WgJustifyContent>
                                    <WgText variant="body2" align="center" style={{ color: 'rgb(99, 99, 99)', marginTop: '3px' }} text={appData.services.find(f => f.id == willGo.serviceId).name}/>
                                </WgBox>
                            </WgBox>
                            <WgBox style={{ width: 'calc(100% - 70px)' }}>
                                <WgJustifyContent justifyContent="space-between">
                                    <WgBox onClick={goToPosition} style={{ cursor: 'pointer' }}>
                                        <WgBox style={{ pointerEvents: 'none' }}>
                                            <WgJustifyContent justifyContent="flex-start">
                                                <WgText variant="info" size="sm" text={getWillGoStatusText(willGo.status)} />
                                                <WgBox style={{ marginLeft: '7px', marginRight: '7px', width: '7px', height: '7px', borderRadius: '10px', backgroundColor: (([1, 2, 3].includes(willGo.status)) ? '#ffd800' : '#ccc') }}></WgBox>
                                                <WgText variant="info" size="sm" text={wgfDateToText(willGo.date)} />
                                            </WgJustifyContent>

                                            <WgText text={wgfFromToAddress(willGo)}/>
                                            {
                                                getOfferText()
                                            }
                                            {
                                                (willGo && willGo.isIDetailViewed == true) && (
                                                    <WgText variant="info" size="xs" text={"incelediniz."} />
                                                )
                                            }
                                        </WgBox>
                                    </WgBox>
                                    <WgBox style={{ width: '40px' }}>
                                        <WgButton style={wgsButtonIcon} onClick={handeDetails} icon={<WgIconChevronRight color="#333" />}/>
                                    </WgBox>
                                </WgJustifyContent>
                            </WgBox>
                        </WgJustifyContent>
                    </WgBox>
                </WgBox>
            </>
        )
    )
}
export default WgWillGoCard;