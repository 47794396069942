import { Fragment, useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { wfgScrollToBottom, wgfAxiosGet, wgfAxiosPost, wgfDateToText, wgfObjectClone, wgfTextCropAndDots } from "../../components/functions/WgFunctions";
import WgJustifyContent from "../../components/ui/WgJustifyContent";
import WgText from "../../components_only_this_platform/elements/WgText";
import { WgAppContext } from "../../App";
import WgBox from "../../components_only_this_platform/elements/WgBox";
import WgIconSend from "../../components_only_this_platform/icons/WgIconSend";
import WgAvatar from "../../components/elements/WgAvatar";
import WgInput from "../../components_only_this_platform/elements/WgInput";

import { WgControlsStyle } from "../../components/WgStaticValues";
import WgUserCardActionButtons from "../../components/ui/WgUserCardActionButtons";
import WgWillGoCard from "../../components/ui/willGoDetails/WgWillGoCard";
import WgRating from "../../components/elements/WgRating";



import WgIconTrash from "../../components_only_this_platform/icons/WgIconTrash";

import { wgsButtonIcon } from "../../components/ui/WgStyles";
import WgIconArrowLeft from "../../components_only_this_platform/icons/WgIconArrowLeft";
import WgButton from "../../components_only_this_platform/elements/WgButton";
import WgTextarea from "../../components_only_this_platform/elements/WgTextarea";

const MessageView = () => {
    const { wgAppObjects, setWgGenDialog, wgGenSignalRReceiveMessageId,
        wgGenSignalRReceiveMessage, wgGenSignalRRemoveMessageItemId, wgGenSignalRUserLeavedConId,
        wgGenSignalRUserJoinConId } = useContext(WgAppContext);
    const [messages, setMessages] = useState(null);
    const [userList, setUserList] = useState([]); //Listede gösterilecek kullanıcıları ayrı tut, filtreleme için de kullanılacak
    const [selectedMessage, setSelectedMessage] = useState(null);

    const scrollRef = useRef(null);
    const [showUserListArea, setShowUserListArea] = useState(true);
    const [messageTextArea, setMessageTextArea] = useState(null);
    const [search, setSearch] = useState('');
    useEffect(() => {
        getAllMessages();
    }, [])

    useEffect(() => {
        if (selectedMessage) {
            wfgScrollToBottom(scrollRef);
            setShowUserListArea(false);
        }
    }, [selectedMessage])
    //Kullanıcı Çevrim Dışı Olduğunda bul ve iconun rengini değiştir.
    useEffect(() => {
        if (messages) {
            let _messages = wgfObjectClone(messages);
            _messages.map(item => {
                if (item && item.users[0] && item.users[0].connectionId == wgGenSignalRUserLeavedConId) {
                    item.users[0].connectionId = null;
                }
            })
            setMessages(_messages);
        }
    }, [wgGenSignalRUserLeavedConId])
    //Kullanıcı Çevrim İçi Olduğunda bul ve iconun rengini değiştir.
    useEffect(() => {
        if (messages) {
            let _messages = wgfObjectClone(messages);
            _messages.map(item => {
                if (item && item.users[0] && item.users[0].connectionId && item.users[0].connectionId == wgGenSignalRUserJoinConId) {
                    item.users[0].connectionId = null;
                }
            })
            setMessages(_messages);
        }
    }, [wgGenSignalRUserJoinConId])
    useEffect(() => {
        if (wgGenSignalRReceiveMessageId) {
            if (selectedMessage && selectedMessage.id == wgGenSignalRReceiveMessageId) {
                getMessageItems(selectedMessage.id);
            }
            getAllMessages();
            wfgScrollToBottom(scrollRef);
        }
    }, [wgGenSignalRReceiveMessageId, wgGenSignalRReceiveMessage])
    useEffect(() => {
        if (wgGenSignalRRemoveMessageItemId && selectedMessage) {
            let _message = wgfObjectClone(selectedMessage);
            _message.messages = _message.messages.filter(f => f.id != wgGenSignalRRemoveMessageItemId);
            setSelectedMessage(_message);
        }
    }, [wgGenSignalRRemoveMessageItemId])

    useEffect(() => {

        if (messages) {
            let _userList = [];
            let _filter = [];
            if (search != '') {
                _filter = messages.filter(item => (item && item.users[0] && (item.users[0].firstName.toLowerCase().includes(search) || item.users[0].lastName.toLowerCase().includes(search))));
            }
            else {
                _filter = messages.filter(f => (f && f.users[0]));
            }
            if (_filter && _filter.length > 0) {
                _userList = _filter.map(item => {
                    return {
                        user: item.users[0],
                        id: item.id,
                        lastMessage: item.lastMessage,
                        lastMessageDate: wgfDateToText(item.lastMessageDate)
                    }
                });
            }
            setUserList(_userList);
        }
    }, [search, messages])
    /*************************************** */
    const getAllMessages = () => {
        wgfAxiosGet('message/get-all', null, setMessages, null);
    }
    const getMessageItems = (id) => {

        wgfAxiosGet('message/get-message-items/' + id, null, setSelectedMessage, null, () => {
            wgAppObjects.functions.updateAccountLayoutData();
        }, null, false);
    }
    const sendMessage = async () => {
        wgfAxiosPost("message/set-message", 
        {
            messageId: selectedMessage.id,
            message: messageTextArea
        }, null, null, null, () => {
            setMessageTextArea('');
            getMessageItems(selectedMessage.id);
        });
    }
    const removeMessage = (id) => {
        if (id) {
            setWgGenDialog({
                isShow: true,
                title: "Silinecek.",
                message: "Mesajınız silinecek. Devam edilsin mi?",
                yesEvent: () => {
                    wgfAxiosPost('message/remove-message', { value: id }, null, null, null, () => {
                        getMessageItems(selectedMessage.id);
                    })
                }
            })
        }
    }
    const messageBoxStyle = (isMyMessage) => {
        let _style = { backgroundColor: '#eee', minWidth: '200px', color: '#333', marginBottom: '10px', marginTop: '10px', borderRadius: '0px 15px 15px 15px', width: 'fit-content', maxWidth: '330px', padding: '10px 30px' }
        if (isMyMessage == true) {
            _style.backgroundColor = '#e5e5ff';
            _style.borderRadius = '15px 15px 0px 15px';
        }
        return _style;
    }
    const selectedUserActiveStyle = (item) => {

        let _style = {
            paddingBottom: '10px',
            padding: '3px',
            borderBottom: '1px solid #ccc',
            borderLeft: '5px solid #fff',
            cursor: 'pointer',
            color: '#333',
            backgroundColor: '#fff'
        }
        //&& selectedMessage.message && selectedMessage.id
        if (selectedMessage) {
            //_style.backgroundColor = selectedMessage.id == item.id ? '#ffd800' : '#fff';
            _style.borderLeft = selectedMessage.id == item.id ? '5px solid #ffd800' : '5px solid #fff';;
        }
        return _style;
    }

    return (
        <WgBox style={{ position: 'relative', width: '100%', height: `${wgAppObjects.currentStyleHeight}px` }}>
            <WgBox style={{ width: '100%', backgroundColor: '#fff', position: 'relative', overflowX: 'hidden', overflowY: 'auto', height: `${wgAppObjects.currentStyleHeight}px` }}>
                {
                    (userList && userList.length > 0) && (
                        <>
                            <WgBox style={{ width: '96%', height: '50px', margin: 'auto' }}>
                                <WgInput placeholder="Kişi Ara.." size="small" propstate={[search, setSearch]} />
                            </WgBox>
                            <WgBox style={{ height: `${wgAppObjects.currentStyleHeight - 60}px`, overflow: 'auto' }}>
                                {
                                    userList.map((item, index) => {
                                        return (

                                            <WgBox key={index} style={selectedUserActiveStyle(item)}>
                                                <WgJustifyContent justifyContent="flex-start" alignItems="flex-start">
                                                    <WgBox onClick={() => getMessageItems(item.id)} style={{ width: '50px' }}>
                                                        <WgAvatar src={item.user.profileImage} style={{ margin: '5px' }} />
                                                    </WgBox>
                                                    <WgBox style={{ width: 'calc(100% - 50px)' }}>
                                                        <WgBox onClick={() => getMessageItems(item.id)} >
                                                            <WgJustifyContent justifyContent="space-between" alignItems="flex-start">
                                                                <WgRating value={item.user.rating} />
                                                                <WgText variant="info" onClick={() => getMessageItems(item.id)} align="right" style={{ fontSize: '13px', color: 'rgb(99, 99, 99)' }} text={item.lastMessageDate} />
                                                            </WgJustifyContent>
                                                        </WgBox>
                                                        <WgJustifyContent justifyContent="space-between" alignItems="flex-start">
                                                            <WgBox onClick={() => getMessageItems(item.id)} >
                                                                <WgJustifyContent justifyContent="flex-start" alignItems="center">
                                                                    {
                                                                        /*<WgBox style={{ width: '9px', height: '9px', borderRadius: '20px', marginRight: '5px', backgroundColor: (item.user.connectionId ? '#ffd800' : '#ccc') }}></WgBox> */
                                                                    }
                                                                    <WgText text={item.user.firstName + " " + item.user.lastName} />
                                                                </WgJustifyContent>
                                                                <WgText variant="info" text={wgfTextCropAndDots(item.lastMessage, 53)} />
                                                            </WgBox>
                                                            <WgBox>
                                                                <WgBox style={{ width: '35px' }}>
                                                                    <WgUserCardActionButtons userData={item.user} hideMessage={true} hideLocation={true} />
                                                                </WgBox>
                                                            </WgBox>
                                                        </WgJustifyContent>
                                                    </WgBox>
                                                </WgJustifyContent>
                                            </WgBox>
                                        )
                                    })
                                }
                            </WgBox>
                        </>
                    )
                }
            </WgBox>
            <WgBox style={{ width: '100%', backgroundColor: '#cce5cd', position: 'absolute', zIndex: (selectedMessage != null ? 7 : -1), top: '0px', right: '0px', overflow: 'hidden', boxShadow: '-5px 0px 20px -15px #000', minWidth: (showUserListArea == true ? '0px' : '300px'), height: `${wgAppObjects.currentStyleHeight}px` }}>
                {
                    (selectedMessage && selectedMessage.users[0]) && (
                        <>
                            <WgBox style={{ backgroundColor: '#fff', paddingTop: '7px', paddingBottom: '3px' }}>
                                <WgJustifyContent justifyContent="flex-start" alignItems="flex-start">
                                    <WgBox style={{ width: '40px', paddingTop: '5px' }}>
                                        <WgButton style={{ ...wgsButtonIcon, backgroundColor: '#ffd800' }} onClick={() => setSelectedMessage(null)} icon={<WgIconArrowLeft />} />
                                    </WgBox>
                                    <WgBox style={{ width: '50px' }}>
                                        <WgAvatar src={selectedMessage.users[0].profileImage} style={{ margin: '5px' }} />
                                    </WgBox>
                                    <WgBox style={{ width: 'calc(100% - 90px)' }}>
                                        <WgJustifyContent justifyContent="space-between" alignItems="flex-start">
                                            <WgBox >
                                                <WgRating value={selectedMessage.users[0].rating} />
                                                <WgJustifyContent justifyContent="flex-start" alignItems="center">
                                                    {
                                                        /*<WgBox style={{ width: '9px', height: '9px', borderRadius: '20px', marginRight: '5px', backgroundColor: (item.user.connectionId ? '#ffd800' : '#ccc') }}></WgBox> */
                                                    }
                                                    <WgText text={selectedMessage.users[0].firstName + " " + selectedMessage.users[0].lastName} />
                                                </WgJustifyContent>
                                            </WgBox>
                                            <WgBox style={{ width: '35px' }}>
                                                <WgUserCardActionButtons userData={selectedMessage.users[0]} hideMessage={true} hideLocation={true} />
                                            </WgBox>
                                        </WgJustifyContent>
                                    </WgBox>
                                </WgJustifyContent>
                            </WgBox>
                            <div ref={scrollRef} style={{ height: `${wgAppObjects.currentStyleHeight - 170}px`, padding: '7px', overflow: 'auto' }}>
                                {
                                    (selectedMessage.messages && selectedMessage.messages.length > 0) ? (
                                        selectedMessage.messages.map((item, index) => {
                                            return (
                                                <Fragment key={index}>
                                                    {
                                                        /**ilan açıklama kartı oluştur */
                                                        (item.willGo && item.willGo.fromCityName) && (
                                                            <WgWillGoCard willGo={item.willGo} />
                                                        )
                                                    }
                                                    <WgJustifyContent justifyContent={item.isMyMessage == true ? 'flex-end' : 'flex-start'}>
                                                        <WgBox style={messageBoxStyle(item.isMyMessage)}>
                                                            <WgText text={item.content} />
                                                            <WgJustifyContent justifyContent="space-between" mt="20px">
                                                                <WgText style={{ fontSize: '13px', color: '#333' }} text={wgfDateToText(item.date)} />
                                                                {
                                                                    (item.isMyMessage) && (
                                                                        <WgButton size="small" onClick={() => removeMessage(item.id)} icon={<WgIconTrash color="#333" />} />
                                                                    )
                                                                }
                                                            </WgJustifyContent>
                                                        </WgBox>
                                                    </WgJustifyContent>
                                                </Fragment>
                                            )
                                        })
                                    ) : (<WgText text="Henüz mesaj yazılmamış..." />)
                                }
                            </div>
                            <WgBox style={{ backgroundColor: '#fff', paddingBottom:'20px' }}>
                                <WgBox style={{ position: 'relative', maxWidth: '90%', margin: 'auto' }}>
                                    <WgJustifyContent justifyContent="space-between">
                                        <WgTextarea propstate={[messageTextArea, setMessageTextArea]} rows="3" placeholder="Mesajınızı Buraya Yazınız..." style={{ ...WgControlsStyle, minHeight: '57px', maxHeight: '57px', marginBottom:'0px', border: 'none', fontSize: '15px' }}></WgTextarea>
                                        <WgButton onClick={sendMessage} style={{ ...wgsButtonIcon }} title={<WgIconSend size="md" />} />
                                    </WgJustifyContent>
                                </WgBox>
                            </WgBox>
                        </>
                    )
                }
            </WgBox>
        </WgBox>
    )
}
export default MessageView;
