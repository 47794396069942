import { useContext } from "react";
import { wgfAxiosDelete } from "../functions/WgFunctions";
import WgIconTrash from "../../components_only_this_platform/icons/WgIconTrash";

import { WgAppContext } from "../../App";
import { wgsButtonIcon } from "../ui/WgStyles";
import WgButton from "../../components_only_this_platform/elements/WgButton";

const WgButtonRemove = ({ url, id, callback, title, message }) => {
    const { setWgGenDialog } = useContext(WgAppContext);
    const remove = () => {
        setWgGenDialog({
            isShow: true,
            title: title ?? "Sil.",
            message: message ?? "Kayıt silinecek. Devam edilsin mi?",
            yesEvent: () => {
                wgfAxiosDelete(url, id, callback ?? null, null, null);
            }
        })
    }
    return (
        <WgButton style={{background:'#f00'}} onClick={remove} color="danger" icon={<WgIconTrash color="#fff"/>}/>
    )
}
export default WgButtonRemove;