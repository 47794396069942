import { useEffect, useState } from "react";
import { wgfAxiosGet, wgfAxiosPost, wgfObjectClone, wgfTimeToText } from "../../functions/WgFunctions";
import { WgAppContext, WgfSnackbarFunc } from "../../../App";
import WgCarousel from "../../../components_only_this_platform/elements/WgCarousel";
import WgJustifyContent from "../WgJustifyContent";

import WgTextarea from "../../../components_only_this_platform/elements/WgTextarea";
import WgInput from "../../../components_only_this_platform/elements/WgInput";
import WgText from "../../../components_only_this_platform/elements/WgText";
import WgImage from "../../../components_only_this_platform/elements/WgImage";
import WgBox from "../../../components_only_this_platform/elements/WgBox";
import WgButtonToggle from "../../../components_only_this_platform/elements/WgButtonToggle";
import { useContext } from "react";
import WgButtonRemove from "../../elements/WgButtonRemove";


import { wgsButton } from "../WgStyles";
import WgButton from "../../../components_only_this_platform/elements/WgButton";
const totalCalcInitial = {
    totalDistance: 0, //toplam mesafe km
    totalTime: 0, //toplam süre saat
    totalTimeText: '0 saat', //toplam süre saat
    totalFuelConst: 0, //toplam yakıt masrafı
    fuelType: null, //aracın yakıt tuketim tipine ait veriler
    totalAmountOfFuel: 0, //toplam yakıt miktarı
    totalEarnings: 0, //toplam kazanç
}
const offerValsLimits = {
    maxPrice: 9999999,
    minPrice: 0,
    descLength: 200
}

const WgEditOffer = ({ willGo, callback, isUpdate }) => {
    const { wgGenRoute, getMyOfferWillGos } = useContext(WgAppContext);
    const [vehicles, setVehicles] = useState(null); //hizmet verecek olan aracı seçmek için
    const [selectedVehicle, setSelectedVehicle] = useState(null); //hizmet verecek olan araç
    const [calcValues, setCalcValues] = useState(totalCalcInitial); //secilen rota bilgisine gore gerekli hesaplamaların degerleri
    const [fuelTariffs, setFuelTariffs] = useState(null); //tahmini masraf hesaplaması icin anlık petrol fiyatları

    const [createOffersStatus, setCreateOffersStatus] = useState(null);
    const [offerPrice, setOfferPrice] = useState(null);
    const [offerDescription, setOfferDescription] = useState(null);
    const [isEdit, setIsEdit] = useState(null); //ilk teklif sunulduktan sonra component kapatılmadan teklif guncellenirse onu takip etmek icin, yoksa guncelleme yapincada yeni teklif şeklinde gösterilmekteydi.
    const [currentId, setCurrentId] = useState(null);//id değişmediği zamanlarda güncellemeyi önlemek için
    useEffect(() => {
        wgfAxiosGet('fuel-type/getall-for-calc', null, setFuelTariffs, null);
    }, [])

    useEffect(() => {
        if (willGo) {
            if (willGo.id != currentId) {
                setCurrentId(willGo.id);
                wgfAxiosGet('chauffeur-vehicle/get-for-willgo-map', null, setVehicles, null);
            }
            if (willGo.myOffer) {
                setOfferPrice(willGo.myOffer.price);
                setOfferDescription(willGo.myOffer.description);
            }
        }
    }, [willGo]);
    useEffect(() => {
        if (vehicles && vehicles.length > 0) {
            if (willGo && willGo.myOffer) {
                setSelectedVehicle(vehicles.find(f => f.id == willGo.myOffer.vehicleId));
            }
            else {
                setSelectedVehicle(vehicles[0]);
            }
        }
    }, [vehicles])

    useEffect(() => {
        calc();
    }, [selectedVehicle, wgGenRoute, offerPrice]);

    const createOffers = () => {
        if (offerPrice) {
            if (offerPrice >= 10 && offerPrice <= 100000) {
                let _prms = {
                    willGoId: willGo.id,
                    price: offerPrice,
                    description: offerDescription ?? "",
                    vehicleId: selectedVehicle.id,
                    isUpdate: isEdit ? isEdit : (isUpdate ?? false) //guncelleme yapilmis ise ona gore mesaj gonderilir.
                }
                wgfAxiosPost('offer/edit-offer', _prms, createOffersStatus, setCreateOffersStatus, null, succededOffer, null);
            }
            else {
                WgfSnackbarFunc("Teklif miktarınız 10-100000 arasında olmalıdır.", "info");
            }
        }
        else {
            WgfSnackbarFunc("Teklifinizi yazınız.", "info");
        }
    }

    const succededOffer = () => {
        setIsEdit(true);
        getMyOfferWillGos(true);//Generic olarak saklanan tekliflerim kayıtını guncelle
        if (callback) {
            callback();
        }
    }
    const calc = () => {
        if (willGo) {
            setCalcValues(totalCalcInitial);//verileri sıfırla
            let _calcVals = wgfObjectClone(calcValues);
            //NOT: Eğer EstimatedDistance null ise routes.summary.totalDistance buradan oku
            //NOT: Eğer EstimetedTime null ise routes.summary.totalTime buradan oku
            _calcVals.totalDistance = Number((willGo.distance).toFixed(0));//toplam mesafeyi km cinsine cevirmek icin 1000'e boldum 
            _calcVals.totalTime = (willGo.time).toFixed(0);
            if (wgGenRoute && wgGenRoute.summary) {
                if (wgGenRoute.summary.totalDistance) {
                    _calcVals.totalDistance = Number(wgGenRoute.summary.totalDistance);
                }
                if (wgGenRoute.summary.totalTime) {
                    _calcVals.totalTime = Number(wgGenRoute.summary.totalTime);
                }
            }
            _calcVals.totalDistance = (_calcVals.totalDistance / 1000).toFixed(0); //km'ye cevir
            if (selectedVehicle != null) {
                _calcVals.totalAmountOfFuel = (_calcVals.totalDistance * selectedVehicle.fuelConsumption).toFixed(1);
                if (fuelTariffs != null) {
                    _calcVals.fuelType = fuelTariffs.find(f => f.id == selectedVehicle.fuelTypeId);
                    if (_calcVals.fuelType) {
                        _calcVals.totalFuelConst = (_calcVals.totalDistance * selectedVehicle.fuelConsumption * _calcVals.fuelType.price).toFixed(2);
                    }
                }
            }
            _calcVals.totalTimeText = wgfTimeToText(willGo.time);
            _calcVals.totalEarnings = Number(offerPrice ?? 0) - (Number(_calcVals.totalFuelConst));
            setCalcValues(_calcVals);
        }
    }
    let carouselVehicles = [];
    if (vehicles) {
        vehicles.forEach(item => {
            carouselVehicles.push(
                <WgButtonToggle onClick={() => setSelectedVehicle(item)} selected={(selectedVehicle ? (item.id == selectedVehicle.id) : (false))} value={item.id}>
                    <WgJustifyContent justifyContent="space-between">
                        <WgBox>
                            <WgImage src={item.image} width={60} style={{ pointerEvents: 'none', borderRadius: '7px', marginRight: '5px', maxWidth: '60px' }} />
                        </WgBox>
                        <WgBox style={{ maxWidth: '240px', overflow: 'hidden' }}>
                            <WgText variant="body2" text={item.name} />
                            <WgText variant="body2" style={{ textTransform: 'capitalize' }} text={item.licensePlate} />
                            <WgText variant="body2" style={{ textTransform: 'capitalize' }} text={`Tük. ${item.fuelConsumption} lt`} />
                        </WgBox>
                    </WgJustifyContent>
                </WgButtonToggle>
            )
        })
    }
    return (
        <WgBox style={{ paddingTop: '15px' }}>
            {
                vehicles ? (
                    <WgBox style={{ marginBottom: '13px' }}>
                        <WgText variant="info" text="Hizmeti vereceğiniz aracı seçiniz" />
                        <WgCarousel spaceBetween={7} items={carouselVehicles} />
                    </WgBox>
                ) : (<WgText text="Aracınız bulunmamaktadır. Şoför bilgileriniz sayfasından araç ekleyebilirsiniz.." />)
            }
            {
                (calcValues.fuelType) && (
                    <>
                        <WgText variant="info" size="xs" text={`${calcValues.fuelType.name} litre fiyatı ₺${calcValues.fuelType.price.toFixed(2)} | ${calcValues.totalDistance} km mesafe`} />
                        <WgText variant="info" size="xs" text={`Bu fiyat ${calcValues.fuelType.source} tarfından belirlenen genel fiyattır. Şehre ve satış istasyonlarına göre ufak farklılıklar oluşabilmektedir.`} />
                    </>
                )
            }
            {
                (calcValues.totalDistance) && (
                    <WgText variant="info" size="xs" text={``} />
                )
            }
            <WgJustifyContent justifyContent="flex-start">
                <WgText variant="info" text="Tahmini Yakıt Masrafı:" />
                <WgText variant="h6" text={`₺${calcValues.totalFuelConst}`} />
            </WgJustifyContent>
            <WgJustifyContent justifyContent="flex-start">
                <WgText variant="info" text="En düşük teklif:" />
                <WgText variant="h6" text={`₺${willGo.minOffer}`} />
            </WgJustifyContent>
            <WgInput type="number" propstate={[offerPrice, setOfferPrice]} placeholder="Teklifinizi yazın..." />
            <WgTextarea maxLength={200} propstate={[offerDescription, setOfferDescription]} placeholder="Açıklama ekleyebilirsiniz." />
            {
                offerDescription && (<WgText variant="body2" align="right" text={`Kalan karakter:${(offerValsLimits.descLength - (offerDescription ? offerDescription.length : 0))}`} />)
            }

            {
                /*
                <WgJustifyContent justifyContent="space-between" mb="30px">
                {(calcValues.totalEarnings) && (<WgText variant="body2">Kazanç:₺{calcValues.totalEarnings}</WgText>)}
            </WgJustifyContent>
                */
            }
            <WgJustifyContent mt="5px" mb="20px">
                <WgButton style={wgsButton.normal} onClick={createOffers} title={willGo.myOffer ? "Güncelle" : "Gönder"} />
                <WgButtonRemove title={"Teklif Silinecek!"} message={"Teklifiniz silinecek. Devam edilsin mi?"} id={willGo.id} url="offer/remove-offer" callback={succededOffer} />
            </WgJustifyContent>
            {
                /*
                selectedVehicle ? (
                    <>
                        <WgText variant="body2">Güncel {fuelTariffs[0].name} : <b>₺{fuelTariffs[0].price}</b></WgText>
                        <WgText variant="body2">Yakıt Tüketimi: <b>{calcValues.totalAmountOfFuel} litre</b><WgText variant="body2" style={{ paddingLeft: '5px' }}></WgText></WgText>
                        <WgText variant="body2">Yakıt Masrafı: <b>₺{calcValues.totalFuelConst}</b><WgText variant="body2" style={{ paddingLeft: '5px' }}></WgText></WgText>
                    </>
                ) : (<p>Hizmet verecek aracı yukarıdan seçiniz...</p>)
                */
            }

        </WgBox>
    )
}
export default WgEditOffer;