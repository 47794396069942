
import { useContext, useState } from "react";
import { useEffect } from "react";
import { WgAppContext } from "../../../App";
import { wgfMapDatas } from "../../../components_only_this_platform/functions/WgMapScripts";
import { wgfAxiosPost, wgfObjectClone } from "../../functions/WgFunctions";
import WgRating from "../../elements/WgRating";
import WgBox from "../../../components_only_this_platform/elements/WgBox";
import WgInput from "../../../components_only_this_platform/elements/WgInput";
import WgReadingInfo from "../../../components_only_this_platform/elements/WgReadingInfo";
import WgServiceToggleButtonCarousel from "../WgServiceToggleButtonCarousel";
import WgUserNameImageCard from "../WgUserNameImageCard";
import WgSelect from "../../../components_only_this_platform/elements/WgSelect";
import WgDataNotFound from "../WgDataNotFound";
import WgPageBody from "../WgPageBody";
import WgText from "../../../components_only_this_platform/elements/WgText";

const WgForWmvSearchService = () => {
    const { appData, setWgGenMarkerChauffeurs } = useContext(WgAppContext);
    const [filterService, setFilterService] = useState(null);
    const [chauffeurs, setChauffeurs] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [cities, setCities] = useState(wgfObjectClone(appData.cities));
    const [selectedServices, setSelectedServices] = useState(null);
    const [selectedCity, setSelectedCity] = useState(34);
    useEffect(() => {
        if (selectedServices) {
            let _idList = selectedServices.map(item => item.id);
            wgfAxiosPost('chauffeur/get-for-filter', { serviceId: _idList, cityId: selectedCity }, null, setChauffeurs, null);
        }
        else {
            setChauffeurs(null);
        }
    }, [selectedServices, selectedCity]);

    useEffect(() => {
        if (appData) {
            if (appData.services) {
                setFilterService(appData.services[0].id);
            }
        }
    }, [appData])
    useEffect(() => {
        /*API'ye sürekli istek gönderip servisi meşgul etmemesi için text'lere önbellekte olanlarda arasın*/
        if (chauffeurs) {
            if (searchText) {
                setChauffeurs(chauffeurs.map(item => {
                    item.isShow = textIncludes(searchText, [item.firstName, item.lastName, item.phone, item.email, item.webSite]);
                    return item;
                }))
            }
            else {
                setChauffeurs(chauffeurs.map(item => {
                    item.isShow = true;
                    return item;
                }))
            }
        }
    }, [searchText])

    /**
     * şoförün bilgileri içinde aranacak kerimeyi arar.
     * @param {*} findText 
     * @param {*} items 
     * @returns 
     */
    const textIncludes = (findText, items) => {
        if (items && findText) {
            findText = findText.toLowerCase();
            for (let i = 0; i < items.length; i++) {
                if (items[i] && items[i].toLowerCase().includes(findText)) {
                    return true;
                }
            }
            return false; //bulunamamış ise ekleme
        }
        return true; //içerik yoksa default olarak göster
    }
    useEffect(() => {
        if (chauffeurs) {
            let _markers = [];
            chauffeurs.filter(f => f.isShow == true).map((item, index) => {
                _markers.push(
                    {
                        position: item.location,
                        iconUrl: wgfMapDatas.iconUrls.default,
                        iconSize: wgfMapDatas.markerIconSize,
                        callback: null,
                        popup: {
                            title: '',
                            body: (
                                <>
                                    <WgRating value={item.rating} />
                                    <WgText variant="h5" text={item.firstName + ' ' + item.lastName} />
                                    <WgText text={`Tel:${item.phone}`} />
                                </>
                            )
                        }
                    }
                )
            })
            setWgGenMarkerChauffeurs(_markers.length > 0 ? _markers : null);
        }
    }, [chauffeurs])

    return (
        (appData && appData.services && filterService) ? (
            <>
                <WgServiceToggleButtonCarousel setSelectedState={setSelectedServices} isMultiSelect={true} />
                <WgPageBody>
                    {
                        cities && (<WgSelect items={cities} value={selectedCity} propstate={[selectedCity, setSelectedCity]} />)
                    }
                    {
                        (chauffeurs && chauffeurs.length > 0) ? (
                            <>
                                {
                                    /*5'ten fazla olursa arama kutusu da gösterilsin. */
                                    (chauffeurs.length > 5) && (
                                        <WgInput fullWidth id="outlined-basic" variant="standard"
                                            placeholder="Şoför adı,telefon,eposta..."
                                            propstate={[searchText, setSearchText]} />
                                    )
                                }
                                {
                                    chauffeurs.filter(f => f.isShow == true).map((item, index) => {
                                        return (
                                            <WgBox style={{ paddingBottom: '15px', paddingTop: '15px', borderBottom: '1px solid #ccc' }}>
                                                <WgUserNameImageCard userData={item} />
                                            </WgBox>
                                        )
                                    })
                                }
                            </>
                        ) : (
                            selectedServices && selectedServices.length > 0 ? (
                                <WgDataNotFound />
                            ) : (
                                <WgText align="center" text="Hizmet türünü ve şehri seçerek sistemimizde kayıtlı şoförlere kolaylıkla ulaşabilirsiniz." />
                            )
                        )
                    }
                </WgPageBody>

            </>
        ) : (
            <WgReadingInfo />
        )

    );
}
export default WgForWmvSearchService;