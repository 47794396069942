import { useContext } from 'react';
import WgText from '../../components_only_this_platform/elements/WgText';
import { WgAppContext } from '../../App';
import WgModal from '../../components_only_this_platform/elements/WgModal';
import WgSpaceVertical from '../../components_only_this_platform/elements/WgSpaceVertical';
import WgJustifyContent from '../ui/WgJustifyContent';
import WgButton from '../../components_only_this_platform/elements/WgButton';

export const dialogInitial = {
   isShow: false,
   type: 'info',
   title: null,
   content: null,
   yesEvent: null,
   noEvent: null
}

const WgDialog = () => {
   const { wgGenDialog, setWgGenDialog } = useContext(WgAppContext);
   const yesEvent = () => {
      if (wgGenDialog.yesEvent) {
         wgGenDialog.yesEvent();
      }
      setWgGenDialog(dialogInitial);
   }
   const noEvent = () => {
      if (wgGenDialog.noEvent) {
         wgGenDialog.noEvent();
      }
      setWgGenDialog(dialogInitial);
   }
   return (
      wgGenDialog && wgGenDialog.isShow && (
         <WgModal maxwidth={'400px'} open={wgGenDialog.isShow} onClose={noEvent}>
            <WgText variant="h2" text={wgGenDialog.title} />
            <WgText variant="info" text={wgGenDialog.message} />
            <WgSpaceVertical />
            <WgJustifyContent>
               <WgButton onClick={noEvent} title="Hayır" />
               <WgButton onClick={yesEvent} title="Evet" />
            </WgJustifyContent>
         </WgModal>

      )
   )
}
export default WgDialog;
/*

 <Dialog
            open={wgGenDialog.isShow}
            TransitionComponent={Transition}
            keepMounted
            onClose={noEvent}
            aria-describedby="alert-dialog-slide-description"
         >
            <DialogTitle>{wgGenDialog.title}</DialogTitle>
            <DialogContent>
               <WgText variant="body" text={wgGenDialog.message} />
               {
                  (wgGenDialog.subMessage) && (<WgText variant="body2" style={{ marginTop: '15px' }} text={wgGenDialog.subMessage}/>)
               }
            </DialogContent>
            <DialogActions>
               <Button onClick={noEvent}>Hayır</Button>
               <Button onClick={yesEvent}>Evet</Button>
            </DialogActions>
         </Dialog>
*/


