import { useEffect } from "react";
import { useState } from "react";
import { wgfAxiosPost } from "../functions/WgFunctions";
import WgReadingInfo from "../../components_only_this_platform/elements/WgReadingInfo";
import WgCarousel from "../../components_only_this_platform/elements/WgCarousel";
import WgRating from "../elements/WgRating";
import WgJustifyContent from "../ui/WgJustifyContent";
import WgText from "../../components_only_this_platform/elements/WgText";
import WgImage from "../../components_only_this_platform/elements/WgImage";
import WgBox from "../../components_only_this_platform/elements/WgBox";
import WgIconAt from "../../components_only_this_platform/icons/WgIconAt";
import WgIconGlobe from "../../components_only_this_platform/icons/WgIconGlobe";
import { useContext } from "react";
import { WgAppContext } from "../../App";
import WgIconPhone from "../../components_only_this_platform/icons/WgIconPhone";
import WgAvatar from "../elements/WgAvatar";

import WgSpaceVertical from "../../components_only_this_platform/elements/WgSpaceVertical";
import WgComments from "./WgComments";



const WgChauffeurShow = ({ id }) => {
    const { appData, setWgGenShowImage } = useContext(WgAppContext);
    const [chauffeur, setChauffeur] = useState(null);
    useEffect(() => {
        if (id) {
            wgfAxiosPost('chauffeur/get-for-page', { id: id }, null, setChauffeur, null);
        }
    }, [])
    let images = [chauffeur.certificateOfAuthorization, chauffeur.galleryImage1, chauffeur.galleryImage2, chauffeur.galleryImage3, chauffeur.galleryImage4, chauffeur.galleryImage5, chauffeur.galleryImage6];
    let carouselImages = [];
    images.forEach(item => {
        if (item) {
            <WgBox style={{ width: '137px' }}>
                <WgImage src={item} style={{ width: '133px', borderRadius: '10px' }} onClick={() => setWgGenShowImage(item)} />
            </WgBox>
        }
    })
    return (
        chauffeur ? (
            <>
                <WgJustifyContent justifyContent="flex-start">
                    <WgBox style={{ padding: '10px' }}>
                        <WgAvatar
                            src={(chauffeur.profileImage)}
                            style={{ width: 70, height: 70, marginRight: 7, boxShadow: 'rgb(0, 0, 0) 14px 12px 30px -15px', borderRadius: '50%', border: '3px solid #fff' }}
                        />
                    </WgBox>
                    <WgBox>
                        <WgRating value={chauffeur.rating} />
                        <WgText variant="h6" text={(chauffeur.firstName + " " + chauffeur.lastName)} />
                        <WgJustifyContent justifyContent="flex-start">
                            {
                                (appData && appData.services) && appData.services.map(item => {
                                    if (chauffeur.services.includes(item.id)) {
                                        return (
                                            <WgBox>
                                                <WgImage src={item.logo} style={{ width: '28px', margin: '0px 3px' }} />
                                            </WgBox>
                                        )
                                    }
                                })
                            }
                        </WgJustifyContent>
                    </WgBox>
                </WgJustifyContent>
                <WgBox style={{ paddingBottom: '20px', paddingTop: '20px' }}>
                    {
                        (chauffeur.phone) && (
                            <WgJustifyContent mb="7px" justifyContent="flex-start">
                                <WgBox style={{ paddingRight: '10px' }}>
                                    <WgIconPhone size="1x" color="#5e5e5e" />
                                </WgBox>
                                <WgText variant="info" text={chauffeur.phone} />
                            </WgJustifyContent>
                        )
                    }
                    {
                        (chauffeur.email) && (
                            <WgJustifyContent mb="7px" justifyContent="flex-start">
                                <WgBox style={{ paddingRight: '10px' }}>
                                    <WgIconAt size="1x" color="#5e5e5e" />
                                </WgBox>
                                <WgText variant="info" text={chauffeur.email} />
                            </WgJustifyContent>
                        )
                    }
                    {
                        (chauffeur.web) && (
                            <WgJustifyContent mb="7px" justifyContent="flex-start">
                                <WgBox style={{ paddingRight: '10px' }}>
                                    <WgIconGlobe size="1x" color="#5e5e5e" />
                                </WgBox>
                                <WgText variant="info" text={chauffeur.web} />
                            </WgJustifyContent>
                        )
                    }
                    <WgText variant="h5" text="Adres" />
                    {chauffeur.address}<br />{chauffeur.district + '/' + chauffeur.country + '/' + chauffeur.city}
                    {
                        (chauffeur.about) && (
                            <>
                                <WgSpaceVertical />
                                <WgText variant="h5" text="Hakkında" />
                                <WgText text={chauffeur.about} />
                            </>
                        )
                    }
                    <WgSpaceVertical />
                    <WgText variant="h5" text="Resimler" />
                    <WgCarousel items={carouselImages} />
                    {
                        (chauffeur.comments && chauffeur.comments.length > 0) && (
                            <>
                                <WgSpaceVertical />
                                <WgText variant="h5" text="Yorumlar" />
                                <WgComments comments={chauffeur.comments} roleischauffeur={true} />
                            </>
                        )
                    }

                </WgBox>


            </>
        ) : (<WgReadingInfo />)
    )
}
export default WgChauffeurShow;