import { useState } from "react";
import WgIconCommentDots from "../../components_only_this_platform/icons/WgIconCommentDots";
import WgIconPhone from "../../components_only_this_platform/icons/WgIconPhone";
import WgIconUserGear from "../../components_only_this_platform/icons/WgIconUserGear";
import WgIconUser from "../../components_only_this_platform/icons/WgIconUser";
import WgJustifyContent from "./WgJustifyContent";
import { wgsButton, wgsButtonIcon } from "./WgStyles";
import { useContext } from "react";
import { WgAppContext, WgfSnackbarFunc } from "../../App";
import WgModal from "../../components_only_this_platform/elements/WgModal";
import WgChauffeurShow from "../dynamics/WgChauffeurShow";
import { useEffect } from "react";
import WgText from "../../components_only_this_platform/elements/WgText";
import { wgfAxiosPost } from "../functions/WgFunctions";
import WgIconPowerOff from "../../components_only_this_platform/icons/WgIconPowerOff";
import WgTextarea from "../../components_only_this_platform/elements/WgTextarea";
import { wgRouteAddresses } from "../WgStaticValues";
import WgIconStarHalf from "../../components_only_this_platform/icons/WgIconStarHalf";
import WgComments from "../dynamics/WgComments";
import WgBox from "../../components_only_this_platform/elements/WgBox";

import WgButton from "../../components_only_this_platform/elements/WgButton";
const _maxMinLength = {
    max: 300,
    min: 15
};
/**
 * 
 * @param {*} param0 
 * wgGenSelectedWillGo: Eğer şoför ilan detayından müşteriye mesaj yazacak ise ilanın ID değerini de eklemesi için
 * @returns 
 */
const WgUserCardActionButtons = ({ userData, roleIsChauffeur, justifyContent, hideCommentsOrProfile, hideMessage, hidePhone, showMyData, showLogOut }) => {
    const { wgAppObjects, wgGenSelectedWillGo, setWgGenDialog } = useContext(WgAppContext);
    const [showModal, setShowModal] = useState(false);
    const [comments, setComments] = useState(null);
    const [message, setMessage] = useState(null);
    const [contactModalShow, setContactModalShow] = useState(false);
    const sentMessage = () => {
        if (message) {
            let _params = {
                message: message,
                contactUserId: userData.id,
                willGoId: wgGenSelectedWillGo ? wgGenSelectedWillGo.id : ''
            }
            wgfAxiosPost('message/create-message', _params, null, null, null, () => {
                WgfSnackbarFunc("Mesajınız iletilmiştir.Cevabını mesajlaşma sayfasından takip edebilirsiniz.", "info");
                closeEvent();
            }, null, null);
        }
        else {
            WgfSnackbarFunc("Lütfen mesajınızı yaznız.", "info");
        }
    }
    const closeEvent = () => {
        setMessage(null);
        setContactModalShow(false);
    }
    const messageLengthControl = () => {
        let _msg = "";
        if (message) {
            if (message.length > _maxMinLength.max) {
                _msg = "Karakter sınırını aştınız!!!"
            }
            else if (message.length >= _maxMinLength.min) {
                _msg = 'Kalan katakter:' + (_maxMinLength.max - message.length);
            }
            else {
                _msg = (_maxMinLength.min - message.length) + ' karakter daha yazmalısınız.';
            }
        }
        return (<WgText variant="body2" align="right" text={_msg}/>)
    }
    useEffect(() => {
        if (showModal == true) {
            /*
            Müşteri ise hakkında yazılan yorumlar listelenecek.
            Şoför ise şoför paneli componenti yüklenecek.
             */
            if (userData.roleIsChauffeur == false && roleIsChauffeur != true && userData.id) {
                wgfAxiosPost('customer/get-comments', { value: userData.id }, null, setComments, null)
            }
        }
    }, [showModal])

    const logOutEvent = () => {
        setWgGenDialog({
            isShow: true,
            icon: <WgIconPowerOff />,
            title: "Oturum kapatılacak.",
            message: "Oturumunuz kapatılacak. Devam edilsin mi?",
            yesEvent: () => {
                wgAppObjects.functions.logOut();
            }
        })
    }
    let _buttonStyle = { ...wgsButtonIcon, width: '27px', height: '27px', minWidth: '27px', minHeight: '27px', fontSize: '18px' };
    return (
        <>
            <WgJustifyContent width="fit-content" justifyContent={justifyContent ?? "flex-end"}>
                {
                    (userData.id) && (
                        <>
                            {
                                (hideCommentsOrProfile == null || hideCommentsOrProfile == false) && (
                                    <WgButton style={_buttonStyle} onClick={() => setShowModal(true)} icon={(userData.roleIsChauffeur == true || roleIsChauffeur == true) ? (<WgIconUser />) : (<WgIconStarHalf />)}/>
                                )
                            }
                            {
                                (hideMessage == null || hideMessage == false) && (<WgButton onClick={() => setContactModalShow(true)} style={_buttonStyle} icon={<WgIconCommentDots />}/>)
                            }
                        </>
                    )
                }
                {
                    (userData.phone && (hidePhone == null || hidePhone == false)) && (<a href={"tel:" + userData.phone} style={_buttonStyle}><WgIconPhone /></a>)
                }
                {
                    (showMyData == true) && (<WgButton onClick={() => wgAppObjects.functions.handleDrawerListItem(null, wgRouteAddresses.account.myData)} style={_buttonStyle} icon={<WgIconUserGear />}/>)
                }
                {
                    (showLogOut == true) && (<WgButton onClick={() => wgAppObjects.functions.handleDrawerListItem(logOutEvent, null)} style={_buttonStyle} icon={<WgIconPowerOff />}/>)
                }

            </WgJustifyContent>
            {
                (showModal == true) && (
                    <WgModal maxwidth="430px" open={showModal} onClose={() => setShowModal(false)}>
                        {
                            (userData.roleIsChauffeur == true || roleIsChauffeur == true) ? (
                                <WgChauffeurShow id={userData.id} />
                            ) : (
                                (comments && comments.length > 0) ? (
                                    <WgComments comments={comments} roleischauffeur={false} />
                                ) : (
                                    <WgBox>
                                        <WgJustifyContent justifyContent="center">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="50px" height="50px" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
                                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#333" stroke="none">
                                                    <path d="M425 4483 c-143 -13 -304 -122 -368 -249 -60 -119 -57 -32 -57 -1509 0 -1214 2 -1357 16 -1406 54 -182 185 -307 361 -344 38 -8 112 -15 166 -15 l97 0 0 -420 c0 -465 -1 -455 65 -505 19 -15 52 -29 73 -32 86 -12 85 -12 587 488 l471 469 1099 0 c1208 0 1179 -1 1295 61 75 40 151 115 190 188 57 105 60 131 60 564 0 432 4 407 -58 407 -50 1 -148 44 -410 183 -143 75 -265 137 -271 137 -6 0 -129 -62 -273 -138 -145 -77 -290 -149 -323 -162 -70 -26 -201 -31 -275 -11 -208 58 -351 241 -352 450 0 48 19 189 52 380 l53 303 -240 233 c-193 188 -245 245 -272 295 -43 80 -61 148 -61 230 0 133 47 236 151 335 37 35 65 66 62 69 -7 6 -1770 5 -1838 -1z" />
                                                </g>
                                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#ffd800" stroke="none">
                                                    <path d="M3700 5113 c-80 -29 -72 -16 -261 -397 -99 -198 -181 -361 -183 -363 -1 -2 -173 -28 -381 -58 -208 -31 -389 -58 -402 -61 -71 -18 -123 -117 -103 -196 9 -38 45 -77 299 -328 l289 -285 -64 -370 c-35 -203 -64 -387 -64 -408 0 -57 33 -113 82 -138 80 -41 104 -32 486 169 l344 182 352 -185 c319 -168 357 -185 402 -185 54 0 103 24 129 63 39 60 38 72 -30 472 -36 209 -64 387 -63 395 2 8 125 134 273 279 149 145 280 280 292 300 47 75 17 183 -61 221 -29 14 -153 36 -419 74 -209 30 -382 57 -385 60 -4 4 -88 167 -187 364 -179 355 -180 357 -225 380 -42 21 -87 27 -120 15z" />
                                                </g>
                                            </svg>
                                        </WgJustifyContent>
                                        <WgText variant="info" align="center" text="Henüz yorum yazılmamıştır." />
                                    </WgBox>
                                )
                            )
                        }
                    </WgModal>
                )
            }
            {
                contactModalShow && (
                    <WgModal maxwidth="400px" open={contactModalShow} onClose={closeEvent}>
                        <WgTextarea rows={3} placeholder={((userData.roleIsChauffeur == false && roleIsChauffeur != true) == false ? 'Fiyat teklifi almak veya sorunuzu şoföre iletmek için buraya yazabilirsiniz.' : 'Müşteriye mesajınızı buradan iletebilirsiniz.')} propstate={[message, setMessage]}></WgTextarea>
                        {messageLengthControl()}
                        {
                            (message && message.length >= _maxMinLength.min && message.length <= _maxMinLength.max) && (
                                <WgJustifyContent justifyContent="center" mb="10px">
                                    <WgButton style={wgsButton.normal} onClick={sentMessage} title="Gönder"/>
                                </WgJustifyContent>
                            )
                        }
                        <WgText variant="info" align="center" size="sm" text="Sistem üzerinden mesajlaşarak iletişim bilgilerinizi gizli tutabilirsiniz." />
                    </WgModal>
                )
            }
        </>
    )
}
export default WgUserCardActionButtons;