
import { useDispatch, useSelector } from "react-redux";
import { wgfAxiosGet } from "../functions/WgFunctions";
import { useEffect, useState } from "react";
import { setUserInfo } from "../../stores/rdxUserInfo";
const WgSetReduxUserInfoes = () => {
    const [userData, setUserData] = useState(null);
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.rdxStateUserInfo);
    useEffect(()=>{
        wgfAxiosGet('account/customer-current-data', null, setUserData, null);
    },[])
    useEffect(()=>{
        if(userData){
            dispatch(setUserInfo(userData));
            let _userInfo=userInfo; /*Yeni bilgileri bir defalıgına cagir, cunku ilk cagirmada boş veri gelmekte, bu da ilk cagirma olacagi icin o sorun aşilmiş olur. */
        }
    },[userData])
}
export default WgSetReduxUserInfoes;