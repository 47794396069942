import { useEffect, useState } from "react";
import { wgfAxiosGet, wgfAxiosPost, wgfDateToText } from "../../functions/WgFunctions";
import WgImage from "../../../components_only_this_platform/elements/WgImage";
import WgBox from "../../../components_only_this_platform/elements/WgBox";
import WgJustifyContent from "../WgJustifyContent";
import WgUserNameImageCard from "../WgUserNameImageCard";


import { useContext } from "react";
import { WgAppContext } from "../../../App";
import { wgsButton } from "../WgStyles";
import WgButtonToggle from "../../../components_only_this_platform/elements/WgButtonToggle";
import WgText from "../../../components_only_this_platform/elements/WgText";
const WgOffers = ({ id }) => {
    const { setWgGenShowImage, wgAppObjects, wgGenSelectedWillGo, setWgGenSelectedWillGo } = useContext(WgAppContext);
    const [offers, setOffers] = useState(null);
    const [detailsShow, setDetailsShow] = useState(false);
    useEffect(() => {
        getOffers();
    }, [])
    const getOffers = () => {
        if (id) {
            wgfAxiosGet('offer/get-all/' + id, null, setOffers, null);
        }
        else {
            wgAppObjects.functions.getMyWillGos();
        }
    }
    const changeOfferStatus = (id, isSelected) => {
        let _url = (isSelected == true) ? 'offer/delete-selected-offer/' : 'offer/set-selected-offer/';
        wgfAxiosPost(_url, { id: id }, null, null, null, refreshDatas, null);
    }
    const refreshDatas = () => {
        //Teklif durumu degistigi icin İlan Status degerleri gibi degişen bilgiler için ilanı guncelle
        if (wgGenSelectedWillGo) {
            wgfAxiosPost('willgo/get-mywillgo-details', { id: wgGenSelectedWillGo.id }, null, setWgGenSelectedWillGo, null);
        }
        wgAppObjects.functions.getMyWillGos();
        getOffers();
    }
    return (
        <>
            {
                offers && (
                    (offers.length > 0) ? (
                        offers.map((item, index) => {
                            return (
                                <WgBox key={index} style={{ marginBottom: '15px', padding: '7px', backgroundColor: (index % 2 == 0 ? 'transparent' : '#e1e1e1') }} >
                                    <WgUserNameImageCard roleIsChauffeur={true} userData={item.chauffeur} hideCommentsOrProfile={false} hideEmail={false} hidePhone={false} hideLocation={false} showMyData={false} showLogOut={false} />
                                    {
                                        detailsShow && (
                                            <WgBox style={{ marginTop: '20px' }}>
                                                <WgJustifyContent justifyContent="space-between">
                                                    {item.description && (
                                                        <WgText variant="h6" text="Teklif Açıklaması" />
                                                    )}
                                                    <WgText variant="info" size="sm" align="right" text={wgfDateToText(item.date)} />
                                                </WgJustifyContent>
                                                {item.description && (
                                                    <WgText variant="info" text={item.description} />
                                                )}
                                                <WgJustifyContent justifyContent="flex-start">
                                                    <WgBox>
                                                        <WgImage src={item.vehicleImage} onClick={() => setWgGenShowImage(item.vehicleImage)} style={{ width: '50px', cursor: 'pointer', marginRight: '10px' }} />
                                                    </WgBox>
                                                    <WgBox>

                                                        <WgText variant="h6" text="Araç" />
                                                        <WgText variant="info" text={`${item.vehicleModelYear} model ${item.vehicleModelName}`} />
                                                    </WgBox>
                                                </WgJustifyContent>

                                            </WgBox>
                                        )
                                    }
                                    <WgJustifyContent alignItems="center">
                                        <WgBox style={{ paddingRight: '10px' }}>
                                            <WgText variant="h6" text={`Teklif: ₺${item.price}`} />
                                        </WgBox>
                                        {/*
                                    İlan tamamlanmis ise  teklif islemleri butonlarini iptal et.
                                    Eger aktif/teklif onaylanmis ise butonları ekle
                                    */}
                                        {
                                            (wgGenSelectedWillGo && (wgGenSelectedWillGo.status == 2 || wgGenSelectedWillGo.status == 1)) ? (
                                                <>
                                                    <WgButtonToggle istabmenu={true} size="small" style={wgsButton.normal} onClick={() => setDetailsShow(!detailsShow)} selected={detailsShow}>Detay</WgButtonToggle>
                                                    <WgButtonToggle istabmenu={true} style={wgsButton.normal} size="small" onClick={() => changeOfferStatus(item.id, item.isSelectedOffer)} selected={item.isSelectedOffer}>{item.isSelectedOffer ? 'İptal Et' : 'Onayla'}</WgButtonToggle>
                                                </>
                                            ) : (
                                                <WgText variant="info" text="Hizmeti Sağlayan" />
                                            )
                                        }

                                    </WgJustifyContent>
                                </WgBox>
                            )
                        })
                    ) : (
                        <WgBox style={{ paddingTop: '33px', paddingBottom: '33px' }}>
                            <WgText variant="info" align="center" text="Henüz teklif sunulmadı." />
                        </WgBox>
                    )
                )
            }
        </>
    )
}
export default WgOffers;