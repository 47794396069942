import { useContext, useState } from "react";
import { useEffect } from "react";

import { wgfMapDatas, wgfMapGoToPosition } from "../../../components_only_this_platform/functions/WgMapScripts";
import WgLocationToAddress, { wgfAxiosGet, wgfAxiosPost, wgfLocationValuesToString, wgfObjectClone } from "../../functions/WgFunctions";

import WgText from "../../../components_only_this_platform/elements/WgText";
import WgBox from "../../../components_only_this_platform/elements/WgBox";

import WgImage from "../../../components_only_this_platform/elements/WgImage";
import WgJustifyContent from "../WgJustifyContent";

import WgIconTrash from "../../../components_only_this_platform/icons/WgIconTrash";
import WgModal from "../../../components_only_this_platform/elements/WgModal";
import WgSelect from "../../../components_only_this_platform/elements/WgSelect";
import { WgAppContext, WgfSnackbarFunc } from "../../../App";
import { estimatedTimeToText, statusEndTimes } from "../../WgStaticValues";


import { wgsButton, wgsButtonIcon } from "../WgStyles";
import WgButton from "../../../components_only_this_platform/elements/WgButton";

const newStatusInitial = {
    lat: '',
    lng: '',
    time: statusEndTimes[3].value,
    typeId: 0,
    city: '',
    country: '',
    district: '',
    region: '',
    address: ''
}

var _newMarker = null; //tek nokta işaretlenmesi gerek o yüzden işareti obje olarak modül dışında tutmalısın

const WgForWmvRoadStatus = () => {
    const { wgGenMapSelectedLocation, wgGenRoadStatusTypes, wgGenMarkerMyRoadStatus, setWgGenMarkerMyRoadStatus, wgGenMarkerRoadStatus, setWgGenMarkerRoadStatus, setWgGenRoadStatusTypes, setWgGenMarkerRoadStatusSelectedLocation, setWgGenDialog, setWgGenMapSelectedLocation } = useContext(WgAppContext);
    const { wgAppObjects } = useContext(WgAppContext);
    const [newStatus, setNewStatus] = useState(null);
    const [positionAddress, setPositionAddress] = useState(null);
    const [statusModalShow, setStatusModalShow] = useState(false);
    const [statusTypesList, setStatusTypesList] = useState(null);
    const [myRoadStatusses, setMyRoadStatusses] = useState(null);
    useEffect(() => {
        if (!wgGenRoadStatusTypes) {
            wgfAxiosGet('road-status/get-all-status-types', null, setWgGenRoadStatusTypes, null);
        }
        return () => {
            setWgGenMarkerMyRoadStatus(null);
        }
    }, [])

    useEffect(() => {
        if (wgGenRoadStatusTypes) {
            getStatusses();
            newStatusInitial.typeId = wgGenRoadStatusTypes[0].id;//default value
            setStatusTypesList(wgGenRoadStatusTypes.map(item => {
                return { text: item.name, value: item.id }
            }))
        }
    }, [wgGenRoadStatusTypes])
    useEffect(() => {
        if (positionAddress) {
            setStatusModalShow(true);
        }
    }, [positionAddress])
    useEffect(() => {
        if (newStatus && wgGenMapSelectedLocation) {
            setWgGenMarkerRoadStatusSelectedLocation(wgGenMapSelectedLocation);
            WgLocationToAddress(wgGenMapSelectedLocation.lat, wgGenMapSelectedLocation.lng, setPositionAddress, null);
        }
    }, [wgGenMapSelectedLocation, newStatus])

    const deleteRoadStatus = (id) => {
        if (id) {
            setWgGenDialog({
                isShow: true,
                title: "Kayıt silinecek",
                message: "Kayıt silinecek. Devam edilsin mi?",
                yesEvent: () => {
                    wgfAxiosPost('road-status/delete-status', { Id: id }, () => {
                        setMyRoadStatusses(myRoadStatusses.filter(f => f.id != id));
                    }, null, null)
                },
                noEvent: null
            })
        }
    }
    useEffect(() => {
        createMarkerMyRoadStatuses();
    }, [myRoadStatusses]);

    const createMarkerMyRoadStatuses = () => {
        let _markers = [];
        if (myRoadStatusses && wgAppObjects.mainMap.map && wgGenRoadStatusTypes) {
            myRoadStatusses.map(item => {
                let _statusType = wgGenRoadStatusTypes.find(f => f.id == item.typeId);
                if (_statusType) {
                    _markers.push(
                        {
                            position: item.location,
                            iconUrl: _statusType.icon,
                            iconSize: wgfMapDatas.markerIconSize,
                            callback: null,
                            popup: null
                        }
                    )
                }
            });
        }
        setWgGenMarkerMyRoadStatus(_markers);
    }
    useEffect(() => {
        if (statusModalShow == false) {
            setWgGenMapSelectedLocation(null);
            setWgGenMarkerRoadStatusSelectedLocation(null);
            setNewStatus(null);
        }
    }, [statusModalShow])
    const getStatusses = () => {
        wgfAxiosGet('road-status/get-all-my-statusses', null, setMyRoadStatusses, null);
    }
    const createNew = () => {
        setWgGenMapSelectedLocation(null);
        setWgGenMarkerRoadStatusSelectedLocation(null);
        WgfSnackbarFunc('Konumu işaretleyiniz.', 'info');
        setNewStatus(newStatusInitial);
    }

    const createStatus = () => {
        if (wgGenMapSelectedLocation && positionAddress) {
            let _request = wgfObjectClone(newStatus);
            _request.location = wgfLocationValuesToString(wgGenMapSelectedLocation);
            _request.city = positionAddress.city;
            _request.country = positionAddress.country;
            _request.district = positionAddress.district;
            _request.region = positionAddress.region;
            _request.address = positionAddress.address;
            wgfAxiosPost('road-status/set-status', _request, () => {
                getStatusses();
                setStatusModalShow(false);

            }, null, null);
        }
    }
    return (
        <>
            <WgJustifyContent justifyContent="center" mb="13px">
                <WgButton style={{ ...wgsButton.normal, marginBottom: '10px' }} onClick={createNew} size="small" title="Yol Durumu Ekle" />
            </WgJustifyContent>
            {
                (myRoadStatusses && myRoadStatusses.length>0) ? myRoadStatusses.map((item, index) => {
                    return (
                        <WgBox key={index} style={{ marginBottom: '7px', backgroundSize: 'auto 100%', backgroundRepeat: 'no-repeat', backgroundColor: '#ccc', borderRadius: '10px' }}>
                            <WgBox style={{ padding: '10px', borderRadius: '10px' }}>
                                <WgJustifyContent justifyContent="space-between" mb="7px">
                                    <WgBox>
                                        <WgBox style={{ display: 'flex' }} onClick={() => wgfMapGoToPosition(wgAppObjects.mainMap.map, item.location)}>
                                            <WgBox style={{ paddingRight: '10px' }}>
                                                <WgImage src={wgGenRoadStatusTypes.find(f => f.id == item.typeId).icon} width="36px" />
                                            </WgBox>
                                            <WgBox>
                                                <WgText text={wgGenRoadStatusTypes.find(f => f.id == item.typeId).name}/>
                                                <WgText variant="body2" text={`Süresi: ${estimatedTimeToText(item.estimatedTime)}`}/>
                                            </WgBox>
                                        </WgBox>
                                    </WgBox>
                                    <WgButton style={wgsButtonIcon} onClick={() => deleteRoadStatus(item.id)} icon={<WgIconTrash />}/>
                                </WgJustifyContent>
                                <WgBox><WgText variant="body2" text={`${item.district} ${item.country} ${item.city}`}/></WgBox>
                            </WgBox>
                        </WgBox>
                    );
                }) : (
                    <WgText variant="info" align="center" text="Yolun durumu paylaşarak diğer şoföreleri bilgilendirebilirsiniz." />
                )
            }
            <WgModal maxwidth={'300px'} open={statusModalShow} onClose={() => setStatusModalShow(false)}>
                <WgBox style={{ marginBottom: '3px' }}>
                    {
                        statusTypesList && (<WgSelect label="Yol Durumu" items={statusTypesList} defaultValue={statusTypesList[0].value} propstate={[newStatus, setNewStatus, 'typeId']}/>)
                    }
                </WgBox>
                <WgBox style={{ marginBottom: '6px' }}>
                    <WgSelect label="Tahmini bitiş süresi" items={statusEndTimes} defaultValue={statusEndTimes[3].value} propstate={[newStatus, setNewStatus, 'time']} />
                </WgBox>
                {
                    positionAddress && (
                        <>
                            <WgText variant="h6" text="Seçilen Konum:" />
                            <WgText variant="info" text={`${positionAddress.district ?? ''} ${positionAddress.country ?? ''} ${positionAddress.city ?? ''}`} />
                        </>
                    )
                }
                <WgJustifyContent mt="10px">
                    <WgButton style={wgsButton.normal} onClick={createStatus} title="Kaydet"/>
                </WgJustifyContent>
            </WgModal>
        </>
    )
}
export default WgForWmvRoadStatus;