import { useEffect, useState } from "react";
import WgFaqs from "../../components/dynamics/WgFaqs";
import WgImage from "../../components_only_this_platform/elements/WgImage";
import WgJustifyContent from "../../components/ui/WgJustifyContent";
import WgPageBody from "../../components/ui/WgPageBody";
import { wgfAxiosGet } from "../../components/functions/WgFunctions";
import WgText from "../../components_only_this_platform/elements/WgText";

const AboutView = () => {
  const [aboutUs, setAboutUs] = useState(null);
  useEffect(() => {
    wgfAxiosGet('app-data/get-aboutus', null, setAboutUs, null);
  }, [])
  return (
    <WgPageBody>
      <WgJustifyContent mt="35px" mb="15px" justifyContent="center">
        <WgImage src="https://files.gidecekvar.com/images/system/faqs.png" style={{ maxWidth: '133px' }} />
      </WgJustifyContent>
      {(aboutUs) && (
        <>
          <WgText text="HAKKIMIZDA"/>
          {aboutUs}
        </>
      )
      }
      <WgFaqs />
    </WgPageBody>
  )
}
export default AboutView;