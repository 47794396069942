import { useEffect } from "react";
import { useState } from "react";
import { wgfAxiosGet, wgfAxiosPost, wgfChangeState } from "../functions/WgFunctions";
import WgReadingInfo from "../../components_only_this_platform/elements/WgReadingInfo";
import WgImageCrop from "../../components_only_this_platform/imageCrop/WgImageCrop";
import { WgSelectItems } from "../WgStaticValues";
import WgJustifyContent from "./WgJustifyContent";
import WgImage from "../../components_only_this_platform/elements/WgImage";
import WgBox from "../../components_only_this_platform/elements/WgBox";
import WgModal from "../../components_only_this_platform/elements/WgModal";
import WgInput from "../../components_only_this_platform/elements/WgInput";
import WgSelect from "../../components_only_this_platform/elements/WgSelect";
import WgButtonRemove from "../elements/WgButtonRemove";
import WgIconEdit from "../../components_only_this_platform/icons/WgIconEdit";

import { wgsButton } from "./WgStyles";
import WgAvatar from "../elements/WgAvatar";

import WgSpaceVertical from "../../components_only_this_platform/elements/WgSpaceVertical";

import WgButton from "../../components_only_this_platform/elements/WgButton";
import WgText from "../../components_only_this_platform/elements/WgText";
const WgChauffeurVehicles = (props) => {
    const [vehicles, setVehicles] = useState(null);
    const [vehicle, setVehicle] = useState(null);
    const [vehicleModalShow, setVehicleModalShow] = useState(false);
    const [brands, setBrands] = useState([]);
    const [vehicleTypes, setVehicleTypes] = useState([]);
    const [fuelTypes, setFuelTypes] = useState([]);
    const [croppedImage, setCroppedImage] = useState(null);
    useEffect(() => {
        wgfAxiosGet('vehicle-brand/getall-forselect', null, setBrands, []);
        wgfAxiosGet('vehicle-type/getall-forselect', null, setVehicleTypes, []);
        wgfAxiosGet('fuel-type/getall-forselect', null, setFuelTypes, []);
        refreshVehicles();
    }, []);
    useEffect(() => {
        if (croppedImage) {
            wgfChangeState(croppedImage, 'image', vehicle, setVehicle);
        }
    }, [croppedImage]);
    const refreshVehicles = () => {
        wgfAxiosPost('chauffeur-vehicle/getall', null, null, setVehicles, null);
    }
    const saveVehicle = () => {
        //araç konumunu müşteri değiştiremeyecek, ayrnı null oluncada Axios 400 hatasından dolayı default değer ekledim.
        wgfAxiosPost('chauffeur-vehicle/set', vehicle, null, null, null, () => {
            refreshVehicles();
            setVehicleModalShow(false);
        }, null, false);
    }
    const selectedVehicle = (id) => {
        setVehicle(null);
        wgfAxiosPost('chauffeur-vehicle/get', { Id: id }, null, setVehicle, null, () => {
            setVehicleModalShow(true);
        });
    }
    const removeVehicle = () => {
        refreshVehicles();
        setVehicleModalShow(false);
        setVehicle(null);
    }
    return (
        <>
            <WgText variant="h5" align="center" text="Hizmet verirken kullanacağınız araçları ekleyiniz." />
            <WgButton style={wgsButton.normal} onClick={() => { selectedVehicle("0") }} title="Araç Ekle"/>
            <WgSpaceVertical />
            {
                vehicles ? vehicles.map((item, index) => {
                    return (
                        <WgBox key={index}>
                            <WgJustifyContent justifyContent="space-between" mb="10px">
                                <WgBox>
                                    <WgJustifyContent justifyContent="flex-start">
                                        <WgBox style={{ paddingRight: '10px' }}>
                                            <WgAvatar src={item.image} style={{ width: '50px', height: '50px' }} />
                                        </WgBox>
                                        <WgBox>
                                            <WgText variant="h6" text={item.name} />
                                            <WgText variant="info" text={item.licensePlate} />
                                        </WgBox>
                                    </WgJustifyContent>
                                </WgBox>
                                <WgBox>
                                    <WgButton onClick={() => { selectedVehicle(item.id) }} icon={<WgIconEdit />}/>
                                </WgBox>
                            </WgJustifyContent>
                        </WgBox>
                    )
                }) : (<WgReadingInfo />)
            }
            {
                vehicle && (
                    <WgModal size="lg" maxwidth={'440px'} open={vehicleModalShow} onClose={() => setVehicleModalShow(false)}>

                        <WgJustifyContent justifyContent="flex-start" alignItems="flex-start" mb="15px">
                            <WgBox style={{ width: '43%' }}>
                                <WgJustifyContent justifyContent="flex-start">
                                    <WgText variant="h6" text="Araç Resmi" />
                                    <WgImageCrop aspect={4 / 4} setImageSate={setCroppedImage} />
                                </WgJustifyContent>

                                <WgImage src={vehicle.image} style={{ width: '100%', borderRadius: '10px' }} />
                            </WgBox>
                            <WgBox style={{ width: '57%' }}>
                                <WgBox style={{ padding: '10px' }}>
                                    <WgSelect type="number" label="Araç Durumu" value={vehicle.status} propstate={[vehicle, setVehicle, 'status']} items={WgSelectItems.ActivePassive}/>
                                    <WgInput placeholder="Araç Adı" propstate={[vehicle, setVehicle, 'name']} />
                                    <WgInput placeholder="Plaka" propstate={[vehicle, setVehicle, 'licensePlate']} />
                                </WgBox>
                            </WgBox>
                        </WgJustifyContent>
                        <WgJustifyContent justifyContent="flex-start" alignItems="flex-start">
                            <WgBox style={{ width: '50%' }}>
                                <WgBox style={{ padding: '7px' }}>
                                    <WgSelect type="number" label="Araç Tipi" value={vehicle.vehicleTypeId} propstate={[vehicle, setVehicle, 'vehicleTypeId']} items={vehicleTypes}/>
                                    <WgSelect type="number" label="Yakıt Tipi" value={vehicle.fuelTypeId} propstate={[vehicle, setVehicle, 'fuelTypeId']} items={fuelTypes}/>
                                    <WgInput step=".1" type="number" min={0.1} placeholder="Yakıt Tüketimi" propstate={[vehicle, setVehicle, 'fuelConsumption']} />
                                </WgBox>
                            </WgBox>
                            <WgBox style={{ width: '50%' }}>
                                <WgBox style={{ padding: '7px' }}>
                                    <WgSelect type="number" label="Araç markası" value={vehicle.brandId} propstate={[vehicle, setVehicle, 'brandId']} items={brands}/>
                                    <WgInput placeholder="Model Adı" propstate={[vehicle, setVehicle, 'modelName']} />
                                    <WgInput type="number" placeholder="Model Yılı" propstate={[vehicle, setVehicle, 'modelYear']} />
                                </WgBox>
                            </WgBox>
                        </WgJustifyContent>
                        <WgJustifyContent>
                            <WgButton onClick={saveVehicle} title="Kaydet" />
                            <WgButtonRemove title="Araç Silinecek." message="Aracınız silinecek. Devam edilsin mi?" url="chauffeur-vehicle/delete" id={vehicle.id} callback={removeVehicle} />
                        </WgJustifyContent>
                    </WgModal>
                )
            }
        </>
    )
}
export default WgChauffeurVehicles;