import WgBox from "../../components_only_this_platform/elements/WgBox";

/**
 * Sayfaların ekrana sıfır olmasını onlemek için.
 * Mesajlaşma sayfasının ekrana sıfır olması gerekiyor,onun gibi başka sayfalar da varsa onlarda kullanma.
 * Ekrana sıfır olmayacak sayfalarda kullan!!!
 * @param {*} props 
 * @returns 
 */
const WgPageBody = (props) => {
    return (
        <WgBox style={{maxWidth:'440px', width: '94%',paddingBottom:'50px', margin: 'auto',...props.style }}>
            {props.children}
        </WgBox>
    )
}
export default WgPageBody;