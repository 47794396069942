import { MenuItem, OutlinedInput, Select } from "@mui/material";

import WgBox from "./WgBox";
import WgIconXMark from "../icons/WgIconXMark";
import { WgControlsStyle } from "../../components/WgStaticValues";
import WgButton from "./WgButton";
const WgSelectMultiItem = ({ items, setItemsState, label }) => {
    const onChangeEvent = (e) => {
        if (e) {
            changeSelect(e.target.value, true);
        }
    }
    const changeSelect = (value, isSelected) => {
        if (value) {
            setItemsState(items.map(item => {
                if (value == item.value) {
                    item.selected = isSelected;
                }
                return item;
            }))
        }
    }
    return (
        <WgBox style={{ marginTop: '15px', marginBottom: '15px' }}>
            {
                (items && items.filter(f => f.selected == true)) && items.filter(f => f.selected == true).map((item, index) => {
                    return (<WgButton key={index} onClick={() => changeSelect(item.value, false)} size="small" style={{ marginRight: '5px', marginBottom: '5px' }} title={item.text} icon={<WgIconXMark color="#f00" size="sm" />} />)
                })
            }
            <Select
                size="small"
                variant="outlined"
                style={WgControlsStyle}
                defaultValue={0}
                input={<OutlinedInput />}
                value={0}
                onChange={onChangeEvent}
            >
                <MenuItem value={0}>{label}</MenuItem>
                {
                    (items && items.filter(f => (!f.selected))) && items.filter(f => (!f.selected)).map((item, index) => {
                        return <MenuItem key={index} value={item.value}>{item.text}</MenuItem>
                    })
                }
            </Select>


        </WgBox>
    )
}
export default WgSelectMultiItem;