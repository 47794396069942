import { useState } from "react";
import WgModal from "../../components_only_this_platform/elements/WgModal";
import WgJustifyContent from "../ui/WgJustifyContent";
import WgIconLock from "../../components_only_this_platform/icons/WgIconLock";
import WgInput from "../../components_only_this_platform/elements/WgInput";
import WgButton from "../../components_only_this_platform/elements/WgButton";
import WgText from "../../components_only_this_platform/elements/WgText";

const WgButtonEditWithPassword = (props) => {
    const [statusModalShow, setStatusModalShow] = useState(false);
    const saveEvent=()=>{
        props.buttonEvent();
        setStatusModalShow(false);
    }
    return (
        <>
            <WgModal maxwidth={'300px'} open={statusModalShow} onClose={() => setStatusModalShow(false)}>
                <WgJustifyContent justifyContent="center" mb="15px">
                    <WgIconLock size="3x" color="rgb(99,99,99)"/>
                </WgJustifyContent>
                <WgText variant="info" align="center" text="Önemli kayıtları değiştirmek için parola onayı gerekmektedir."/>
                <WgInput type="password" propstate={props.propstate} placeholder="Parolanızı yazınız" />
                <WgJustifyContent justifyContent="center">
                    <WgButton onClick={saveEvent} title="Kaydet"/>
                </WgJustifyContent>
            </WgModal>
            <WgButton onClick={() => setStatusModalShow(true)} title="Kaydet"/>
        </>
    )
}
export default WgButtonEditWithPassword;