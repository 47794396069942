export const wgApiDomain = {
    domain: 'https://api.gidecekvar.com'
};
//'https://localhost:7057'//
//https://localhost:7057
//https://192.168.1.20
//https://api.gidecekvar.com

export const WgSelectItems = {
    ActivePassive: [
        { text: 'Aktif', value: 1 },
        { text: 'Pasif', value: 0 }
    ],
    MessageSubjects: [
        { text: 'İstek', value: 'İstek' },
        { text: 'Öneri', value: 'Öneri' },
        { text: 'Şikayet', value: 'Şikayet' },
        { text: 'Diğer', value: 'Diğer' }
    ],
    YesNo: [
        { text: 'Evet', value: 'Evet' },
        { text: 'Hayır', value: 'Hayır' }
    ]
}

export const WgControlsStyle = {
    backgroundColor: '#f8f8f8',
    marginBottom: '10px',
    marginTop: '5px',
    width: '100%'
}

export const WgColors = {
    yellow: 'rgb(255, 216, 0)',
    black: '#333',
    white: '#f1f1f1'
}
export const WgBackgrounds = {
    yellowGradient: 'linear-gradient(90deg, rgb(255, 216, 0) 0%, rgb(239, 255, 0) 50%, rgb(255, 216, 0) 100%);'
}
export const wgRouteAddresses = {
    home: {
        indexRoot: '/',
        index: '/home',
        register: '/register',
        contact: '/contact',
        forgotPassword: '/forgot-password',
        aboutUs: '/about-us'
    },
    account: {
        myData: '/account-mydata',
        system: '/account-system',
        willgoMap: '/account-willgomap',
        registerChauffeur: '/account-register-chauffeur',
        chauffeurDetails: '/account-chauffeur-details',
        roadStatus: '/account-road-status',
        message: '/account-message',
        notifications: '/account-notifications'
    }
}
/*
Birinde olup digerinde olmayanlari kullanma,kesişim kumesinde olanlari kullan.
*/
export const wgStaticInputTypes = {
    js: {
        'text': 'text',
        'number': 'number',
        'tel': 'tel',
        'search': 'search',
        'email': 'email',
        'url': 'url'
    },
    native: {
        'text': 'text',
        'number': 'numeric',
        'tel': 'tel',
        'search': 'search',
        'email': 'email',
        'url': 'url'
    }
}
export const statusEndTimes = [
    { text: '1 Saat', value: 1 },
    { text: '3 Saat', value: 3 },
    { text: '7 Saat', value: 7 },
    { text: '1 Gün', value: 24 },
    { text: '3 Gün', value: 72 },
    { text: '5 Gün', value: 120 },
    { text: '7 Gün', value: 168 },
    { text: '14 Gün', value: 336 },
    { text: '1 Ay', value: 720 }
]
export const estimatedTimeToText = (value) => {
    let _find = statusEndTimes.find(f => f.value == value);
    if (_find) {
        return _find.text;
    }
    return '';
}
export const willGosStatus = [
    { text: 'Silindi', value: -1, selected: false },
    { text: 'İptal Edildi', value: 0, selected: false },
    { text: 'Aktif', value: 1, selected: false },
    { text: 'Teklif Onaylandı', value: 2, selected: false },
    { text: 'Değerlendiriliyor', value: 3, selected: false },
    { text: 'Tamamlandı', value: 4, selected: false },

];
export const getWillGoStatusText = (status) => {
    let _f = willGosStatus.find(f => f.value == status);
    return _f ? _f.text : '';
}