import { combineReducers, configureStore } from "@reduxjs/toolkit";
import rdxUserInfo from "./rdxUserInfo";
import rdxToastMessages from './rdxToastMessages';
import storage from "redux-persist/lib/storage";
import {
    persistStore, persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from 'redux-persist'
const rootReducer = combineReducers({
    rdxStateUserInfo: rdxUserInfo,
    rdxStateToastMessages:rdxToastMessages
})
/**
 *  redux-persist verileri localStorage nesnesi ile önbelleğe yazdırır
 * 
 * key: persist key adı
 * whiteList: buraya ön bellekte tutulacak reducer'ların adını yazarız. (ön bellek sıfırlanmadığı sürece hafızada tutulur)
 * blackList : bunlarda önbellekte hiç tuutlmayacak olanlar
 */
 const persistConfig={
    key:'my-root-persist',
    storage:storage,
    version: 1,
    whiteList:['rdxStateUserInfo','rdxStateToastMessages'],
    blackList:[]
}
const persistedReducer=persistReducer(persistConfig, rootReducer);
const store = configureStore({
    reducer:persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
})
export const persistor=persistStore(store)
export default store;