import WgImage from "../../components_only_this_platform/elements/WgImage"
import WgPageBody from "../../components/ui/WgPageBody"
import { wgfAuthenticationCode, wgfAxiosPost, wgfIsNotNull } from "../../components/functions/WgFunctions"
import { WgAppContext, WgfSnackbarFunc } from "../../App"
import WgInput from "../../components_only_this_platform/elements/WgInput"
import WgBox from "../../components_only_this_platform/elements/WgBox"
import { useContext, useEffect, useState } from "react"
import WgJustifyContent from "../../components/ui/WgJustifyContent"
import { wgRouteAddresses } from "../../components/WgStaticValues"
import WgCheckbox from "../../components_only_this_platform/elements/WgCheckbox"
import WgKvkkOrTermsOfUse from "../../components/dynamics/WgKvkkOrTermsOfUse"

import { wgsButton } from "../../components/ui/WgStyles"
import WgButton from "../../components_only_this_platform/elements/WgButton"
import WgText from "../../components_only_this_platform/elements/WgText"
const userInitial = {
    profileImage: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    authenticationCode: null,
    authenticationCodeId: ''
}

const RegisterView = () => {
    const { wgAppObjects } = useContext(WgAppContext);
    const [userData, setUserData] = useState(userInitial);
    const [passwordReEnter, setPasswordReEnter] = useState(null);
    const [showKvkk, setShowKvkk] = useState(false);
    const [showTermsOfUse, setShowTermsOfUse] = useState(false);
    const [checkKvkk, setCheckKvkk] = useState(false);
    const [checkTermsOfUse, setCheckTermsOfUse] = useState(false);
    const [authCode, setAuthCode] = useState(null);
    const [authCodeId, setAuthCodeId] = useState(null);
    const [phone, setPhone] = useState(''); //tel degistiginde dogurulama kodunun yeniden gonderilmesi istenilecek
    const [email, setEmail] = useState(''); //mail degistiginde dogurulama kodunun yeniden gonderilmesi istenilecek
    const register = () => {
        if (checkKvkk === true) {
            if (checkTermsOfUse === true) {
                if (wgfIsNotNull([userData.firstName, userData.lastName, phone, email, userData.password, passwordReEnter], true)) {
                    if (userData.password === passwordReEnter) {
                        if (userData.password.length >= 7) {
                            userData.authenticationCode = authCode;
                            userData.authenticationCodeId = authCodeId;
                            userData.phone = phone;
                            userData.email = email;
                            if (wgfIsNotNull([userData.authenticationCodeId]) && userData.authenticationCode != null) {
                                wgfAxiosPost('account/customer-register', userData, null, null, null, () => {
                                    setUserData(userInitial);
                                    setPasswordReEnter(null);
                                    WgfSnackbarFunc("Giriş sayfasına yönlendirieleceksiniz.", 'info');
                                    setTimeout(() => {
                                        wgAppObjects.functions.goToLink(wgRouteAddresses.home.index);
                                    }, 5000)
                                }, null, true);
                            }
                            else {
                                WgfSnackbarFunc('Doğrulama kodu tespit edilemedi.Telefon ve Email bilgilerinizi yeniden yazınız.', 'info')
                            }
                        }
                        else {
                            WgfSnackbarFunc("Parolanız en az 5 karakter olmalı.", 'info');
                        }
                    }
                    else {
                        WgfSnackbarFunc("Parolalar uyuşmamakta.", 'info');
                    }
                }
                else {
                    WgfSnackbarFunc('Lütfen istenilen bilgilerin hepsini yazınız.', 'info');
                }
            }
            else {
                WgfSnackbarFunc('Kullanım Koşullarını onaylamadan kayıt olamazsınız.', 'info');
            }
        } else {
            WgfSnackbarFunc('KVKK metnini onaylamadan kayıt olamazsınız.', 'info');
        }
    }
    useEffect(() => {
        setAuthCode(null);
        setAuthCodeId(null);
    }, [phone, email])
    const setAuthenticationCode = () => {
        wgfAuthenticationCode(phone, email, userData.firstName + ' ' + userData.lastName, setAuthCodeId);
    }
    return (
        <WgPageBody>
            <WgJustifyContent justifyContent="center" mt="33px" mb="13px">
                <WgImage src="https://files.gidecekvar.com/images/system/mobile-map-road.png" style={{ width: '99px' }} />
            </WgJustifyContent>
            <WgBox style={{ paddingTop: '20px', maxWidth: '320px', margin: 'auto' }}>
                <WgText variant="info" align="center" text="Hemen hesap oluşturarak ulaşım ihtiyaçlarınız giderebilir veya hizmet vererek para kazanabilirsiniz." />
                <WgInput placeholder="İsim" validType="letter" propstate={[userData, setUserData, "firstName"]} />
                <WgInput placeholder="Soyisim" validType="letter" propstate={[userData, setUserData, "lastName"]} />
                <WgInput placeholder="Cep Telefonu" validType="phone" propstate={[phone, setPhone]} />
                <WgInput placeholder="Eposta" validType="email" propstate={[email, setEmail]} />
                {
                    (authCodeId == null) && (
                        <WgBox style={{ marginBottom: '9px' }}>
                            <WgText variant="info" size="xs" text="Telefonunuza veya mail adresinize doğrulama kodu gönderilecektir." />
                            <WgJustifyContent mt="0px" mb="15px" justifyContent="center">
                                <WgButton style={wgsButton.normal} onClick={setAuthenticationCode} title="Kod Gönder"/>
                            </WgJustifyContent>
                        </WgBox>
                    )
                }
                {
                    (authCodeId != null) && (
                        <>
                            <WgInput placeholder="Doğrulama Kodu" type="number" propstate={[authCode, setAuthCode]} />
                            <WgInput placeholder="Şifre" type="password" propstate={[userData, setUserData, "password"]} />
                            <WgInput placeholder="Şifre Tekrar" type="password" propstate={[passwordReEnter, setPasswordReEnter]} />
                            <WgText variant="info" size="sm" text="Kayıt olmak için KVKK metnini ve Kullanım Şartlarını okuyunuz ve onaylayınız." />
                            <WgKvkkOrTermsOfUse isKvkk={true} showState={showKvkk} setShowState={setShowKvkk} />
                            <WgCheckbox label="KVKK metnini onaylıyorum." propstate={[checkKvkk, setCheckKvkk]} />
                            <WgKvkkOrTermsOfUse isKvkk={false} showState={showTermsOfUse} setShowState={setShowTermsOfUse} />
                            <WgCheckbox label="Kullanım koşullarını onaylıyorum." propstate={[checkTermsOfUse, setCheckTermsOfUse]} />
                            <WgJustifyContent mt="20px" mb="15px" justifyContent="center">
                                <WgButton style={wgsButton.normal} onClick={() => register()} title="Kaydol"/>
                            </WgJustifyContent>
                        </>
                    )
                }
            </WgBox>


        </WgPageBody>

    )
}
export default RegisterView;