
import { wgRouteAddresses } from "../../components/WgStaticValues";
import { useSelector } from "react-redux";
import WgJustifyContent from "../../components/ui/WgJustifyContent";
import WgImage from "../../components_only_this_platform/elements/WgImage";
import WgBox from "../../components_only_this_platform/elements/WgBox";
import { useContext } from "react";
import { WgAppContext } from "../../App";
import { useEffect } from "react";
import { useState } from "react";
import { wgfAxiosGet, wgfAxiosPost } from "../../components/functions/WgFunctions";
import WgCustomerAddresses from "../../components/dynamics/WgCustomerAddresses";
import WgCustomerInfo from "../../components/dynamics/WgCustomerInfo";
import WgChauffeurBaseInfo from "../../components/dynamics/WgChauffeurBaseInfo";
import WgServiceSelector from "../../components/ui/WgServiceSelector";
import WgChauffeurVehicles from "../../components/ui/WgChauffeurVehicles";
import WgAccountStatusChange from "../../components/ui/WgAccountStatusChange";
import WgPageBody from "../../components/ui/WgPageBody";
import WgCarousel from "../../components_only_this_platform/elements/WgCarousel";
import WgButtonToggle from "../../components_only_this_platform/elements/WgButtonToggle";


import { wgsButton } from "../../components/ui/WgStyles";
import WgButton from "../../components_only_this_platform/elements/WgButton";
import WgText from "../../components_only_this_platform/elements/WgText";


const _tabButtons = {
    myData: 0,
    myAddresses: 1,
    myChauffeurData: 2,
    myChauffeurServices: 3,
    myChauffeurVehicles: 4,
    registerChauffeur: 5,
}
const MyDataView = () => {
    const { wgAppObjects, setWgGenDialog } = useContext(WgAppContext);
    const [isHasAlreadyChauffeurData, setIsHasAlreadyChauffeurData] = useState(false);
    const userInfo = useSelector(state => state.rdxStateUserInfo);
    useEffect(() => {
        /*
         Eğer Şoför yetkisine sahip değilse, yine de kayıt kontrolü yaptır.
         Daha önceden şoför hesabı oluşturup silmiş ise hesabını tekrar aktif edebilecektir.
        */
        if (userInfo.roleIsChauffeur == false) {
            wgfAxiosGet('/chauffeur/has-chauffeur-data', null, setIsHasAlreadyChauffeurData, false);
        }
    }, [])
    const [activeTab, setActiveTab] = useState(_tabButtons.myData);
    const accountEnable = () => {
        setWgGenDialog({
            isShow: true,
            title: "Hesap aktif edilecek.",
            message: 'Dondurulan hesabınız tekrar aktif edilecektir. Devam edilsin mi?',
            subMessage: 'İşlem sonucunda oturumunuz kapatılacak.Yeniden giriş yapmanız gerekecek.',
            yesEvent: () => {
                wgfAxiosPost('/account/account-disable', { value: 1, isTrue: false }, null, null, null, (data) => {
                    setTimeout(() => {
                        wgAppObjects.functions.logOut();
                    }, 2000)
                })
            }
        })
    }
    let carouselNavbarButtons=[];
    carouselNavbarButtons.push(<WgButtonToggle istabmenu={true} selected={activeTab === _tabButtons.myData} onClick={() => setActiveTab(_tabButtons.myData)} size="small">Bilgilerim</WgButtonToggle>);
    carouselNavbarButtons.push(<WgButtonToggle istabmenu={true} selected={activeTab === _tabButtons.myAddresses} onClick={() => setActiveTab(_tabButtons.myAddresses)} size="small">Adreslerim</WgButtonToggle>);
    if(userInfo.roleIsChauffeur == true){
        carouselNavbarButtons.push(<WgButtonToggle istabmenu={true} selected={activeTab === _tabButtons.myChauffeurData} onClick={() => setActiveTab(_tabButtons.myChauffeurData)} size="small">Şoför Bilgilerim</WgButtonToggle>);
        carouselNavbarButtons.push(<WgButtonToggle istabmenu={true} selected={activeTab === _tabButtons.myChauffeurServices} onClick={() => setActiveTab(_tabButtons.myChauffeurServices)} size="small">Hizmetlerim</WgButtonToggle>);
    }
    if(userInfo.roleIsChauffeur == true){
        carouselNavbarButtons.push(<WgButtonToggle istabmenu={true} selected={activeTab === _tabButtons.myChauffeurVehicles} onClick={() => setActiveTab(_tabButtons.myChauffeurVehicles)} size="small">Araçlarım</WgButtonToggle>);
    }
    else{
        carouselNavbarButtons.push(<WgButtonToggle istabmenu={true} selected={activeTab === _tabButtons.registerChauffeur} onClick={() => setActiveTab(_tabButtons.registerChauffeur)} size="small">Şoför Hesabı Oluştur</WgButtonToggle>);
    }

    return (
        <>
            <WgCarousel spaceBetween={1} items={carouselNavbarButtons}/>
            <WgPageBody>

                <WgBox style={{ paddingTop: '20px' }}>
                    <WgBox>
                        {(activeTab === _tabButtons.myData) && (
                            <>
                                <WgCustomerInfo />
                                
                                <WgAccountStatusChange />
                            </>
                        )}
                        {(activeTab === _tabButtons.myAddresses) && (
                            <WgCustomerAddresses />
                        )}
                        {(activeTab === _tabButtons.myChauffeurData) && (
                            <WgChauffeurBaseInfo isCreate={false} />
                        )}
                        {(activeTab === _tabButtons.myChauffeurServices) && (
                            <WgServiceSelector />
                        )}
                        {(activeTab === _tabButtons.myChauffeurVehicles) && (
                            <WgChauffeurVehicles />
                        )}

                        {(activeTab === _tabButtons.registerChauffeur) && (
                            <>
                                <WgJustifyContent justifyContent="center" mt="10px" mb="35px">
                                    <WgImage src="https://files.gidecekvar.com/images/system/mobile-map-road.png" style={{width:'99px'}} />
                                </WgJustifyContent>
                                {
                                    (isHasAlreadyChauffeurData == true) ? (
                                        <>
                                            <WgText align="center" variant="h3" text="Dondurulmuş şoför hesabınız bulunmaktadır!"/>
                                            <WgText align="center" variant="h6" text="Hesabınızı tekrar aktif edebilirsiniz."/>
                                            <WgJustifyContent justifyContent="center" mt="15px" mb="40px">
                                                <WgButton style={wgsButton.normal} onClick={accountEnable} title="Hesabı Aktif Et"/>
                                            </WgJustifyContent>
                                        </>
                                    ) : (
                                        <>
                                            <WgText align="center" variant="h3" text="Henüz şoför hesabı oluşturmamışsınız!"/>
                                            <WgText variant="info" align="center" text="Hemen hesap oluşturarak para kazanamya başlayın."/>
                                            <WgJustifyContent justifyContent="center" mt="15px" mb="40px">
                                                <WgButton style={wgsButton.normal} onClick={() => wgAppObjects.functions.goToLink(wgRouteAddresses.account.registerChauffeur)} title="Şoför Hesabı Oluştur"/>
                                            </WgJustifyContent>
                                        </>
                                    )
                                }
                            </>
                        )}
                    </WgBox>
                </WgBox>
            </WgPageBody>
        </>
    )
}
export default MyDataView;