import { useEffect } from "react";
import { useState } from "react";
import { wgfAxiosPost, wgfAxiosGet } from "../functions/WgFunctions";
import WgButtonRemove from "../elements/WgButtonRemove";
import WgJustifyContent from "../ui/WgJustifyContent";
import WgBox from "../../components_only_this_platform/elements/WgBox";
import WgAddress from "./WgAddress";


import { wgsButton } from "../ui/WgStyles";
import WgPageBody from "../ui/WgPageBody";
import WgButton from "../../components_only_this_platform/elements/WgButton";
import WgIconEdit from "../../components_only_this_platform/icons/WgIconEdit";
import WgText from "../../components_only_this_platform/elements/WgText";

const WgCustomerAddresses = () => {
    const [addresses, setAddresses] = useState([]);
    const [address, setAddress] = useState(null);
    const [addressModalShow, setAddressModalShow] = useState(false);
    useEffect(() => {
        addressListRefresh();
    }, []);
    const addressEditEvent = (id) => {
        wgfAxiosPost('address/get', { Id: id }, null, setAddress, null, () => {
            setAddressModalShow(true);
        });
    }
    const addressListRefresh = () => {
        wgfAxiosGet('address/get-addresses', null, setAddresses, []);
        setAddress(null);
        setAddressModalShow(false);
    }
    return (
        <>
            <WgPageBody>
            <WgText variant="info" align="center" text="İlan eklerken kullanabileceğiniz hazır adres kayıtları oluşturabilirsiniz." />
                <WgJustifyContent justifyContent="center" mb="33px">
                    <WgButton onClick={() => addressEditEvent("null")} style={wgsButton.normal} title="Adres Ekle" />
                </WgJustifyContent>
                

                <WgBox style={{ maxWidth: '540px' }}>
                    {
                        addresses && addresses.map((item, index) => {
                            return (
                                <WgBox style={{ paddingBottom: '7px', marginBottom: '10px', borderBottom: '1px solid #ccc' }}>
                                    <WgJustifyContent justifyContent="space-between">
                                        <WgBox>
                                            <WgText variant="h6" text={item.name} />
                                            <WgText variant="info" text={item.address} />
                                        </WgBox>
                                        <WgBox>
                                            <WgJustifyContent>
                                                <WgButton onClick={() => addressEditEvent(item.id)} icon={<WgIconEdit />} />
                                                <WgButtonRemove title="Adres silinecek." message="Adresiniz silinecek. Devam edilsin mi?" id={item.id} url="address/delete-address" callback={addressListRefresh} />
                                            </WgJustifyContent>
                                        </WgBox>
                                    </WgJustifyContent>
                                </WgBox>
                            )
                        })
                    }
                </WgBox>
                {
                    (address && addressModalShow) && (
                        <WgAddress modalShow={addressModalShow} modalShowState={setAddressModalShow} callback={addressListRefresh} isAddMyAddresses={true} address={address} addressState={setAddress} />
                    )
                }
            </WgPageBody>

        </>
    )
}
export default WgCustomerAddresses;