import WgChauffeurBaseInfo from "../../components/dynamics/WgChauffeurBaseInfo";
import WgImage from "../../components_only_this_platform/elements/WgImage";
import WgText from "../../components_only_this_platform/elements/WgText";

import WgJustifyContent from "../../components/ui/WgJustifyContent";
import WgPageBody from "../../components/ui/WgPageBody";
import WgSpaceVertical from "../../components_only_this_platform/elements/WgSpaceVertical";

const CreateChauffeurView = () => {

    return (
        <WgPageBody>
            <WgSpaceVertical />
            <WgSpaceVertical />
            <WgJustifyContent justifyContent="center">
                <WgImage src="https://files.gidecekvar.com/images/system/mobile-map-road.png" style={{ width: '133px' }} />
            </WgJustifyContent>
            <WgSpaceVertical />
            <WgText align="center" text="Şoför hesabı oluştur, ilanlara teklif sun."/>
            <WgSpaceVertical />
            <WgChauffeurBaseInfo isCreate={true} />
        </WgPageBody>
    )
}
export default CreateChauffeurView;