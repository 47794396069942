
import { useState } from "react";
import { wgfAxiosPost, wgfIsNotNull } from "../../components/functions/WgFunctions";
import WgJustifyContent from "../../components/ui/WgJustifyContent";
import WgImage from "../../components_only_this_platform/elements/WgImage";
import WgTextarea from "../../components_only_this_platform/elements/WgTextarea";
import WgInput from "../../components_only_this_platform/elements/WgInput";
import WgPageBody from "../../components/ui/WgPageBody";
import WgBox from "../../components_only_this_platform/elements/WgBox";

import { wgsButton } from "../../components/ui/WgStyles";
import WgButton from "../../components_only_this_platform/elements/WgButton";
import WgText from "../../components_only_this_platform/elements/WgText";
const contactInitial = {
    nameSurname: '',
    emailPhone: '',
    message: ''
}
const ContactView = () => {
    const [contact, setContact] = useState(contactInitial);
    const submit = () => {
        if (wgfIsNotNull([contact.nameSurname, contact.emailPhone, contact.message], true)) {
            wgfAxiosPost('contact/create', contact, () => {
                setContact(contactInitial);
            }, null, null);
        }
    }
    return (
        <WgPageBody>
            <WgBox style={{ paddingTop: '20px', maxWidth: '320px', margin: 'auto' }}>
                <WgJustifyContent mb="13px" justifyContent="center">
                    <WgImage style={{ width: '99px' }} src="https://files.gidecekvar.com/images/system/question.png" />
                </WgJustifyContent>
                <WgText variant="info" align="center" text="Soru ve öneriler için bizimle iletişime geçebilirsiniz."/>
                <WgInput placeholder={"İsim Soyisim"} validType="letter" propstate={[contact, setContact, "nameSurname"]} />
                <WgInput placeholder={"Telefon veya Eposta"} validType="phone,email" propstate={[contact, setContact, "emailPhone"]} />
                <WgTextarea rows={2} placeholder="Mesajınızı buraya yazınız." propstate={[contact, setContact, "message"]} />
                <WgJustifyContent mt="15px" mb="30px" justifyContent="center">
                    <WgButton style={wgsButton.normal} onClick={() => submit()} title="Gönder"/>
                </WgJustifyContent>
            </WgBox>
        </WgPageBody>
    )
}
export default ContactView;