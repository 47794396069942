import WgImage from "../../components_only_this_platform/elements/WgImage";

const WgAvatar = (props) => {
    let _style = { width: '40px', height: '40px',borderRadius:'100%' }
    if (props.style) {
        _style = { ..._style, ...props.style };
    }
    return (
        <WgImage src={props.src ?? 'default-avatar-image.jpg'} style={_style} />
    )
}
export default WgAvatar;
