import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { wgfCreateRandomID, wgfGetPropStateValue, wgfPropsStateChange } from "../../components/functions/WgFunctions";
import { WgControlsStyle } from "../../components/WgStaticValues";
/*
type = state'e yazdırılacak değer tipini belirtmek için kullanılacak.(text/number)
*/
const WgSelect = ({type,style,propstate,items,wgOnChange,defaultValue,label,defaultItem}) => {
  let _id = wgfCreateRandomID();
  let _style={...WgControlsStyle,width: '100%',...style};
  const onChangeEvent = (e) => {
    if (wgOnChange) {
      wgOnChange()
    }
    if (propstate) {
      let _props={propstate:propstate};
      wgfPropsStateChange(e, _props);
    }
  }
  const getValue=()=>{
    let _props={propstate:propstate};
    return wgfGetPropStateValue(_props);
  }
  return (
    <FormControl style={{ width: '100%' }}>
      {
        (label) && (<InputLabel id={_id}>{label}</InputLabel>)
      }
      <Select
        variant="outlined"
        labelId={_id}
        value={getValue()}
        defaultValue={defaultValue}
        onChange={onChangeEvent}
        style={_style}
        size="small"
      >
        {
          (defaultItem) && (
            <MenuItem value={defaultItem.value}>{defaultItem.text}</MenuItem>
          )
        }
        {
          items && items.map((item, index) => {
            return <MenuItem key={index} value={item.value}>{item.text}</MenuItem>
          })
        }
      </Select>
    </FormControl>
  )
}
export default WgSelect;
