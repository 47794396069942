
import { wgfDateTimeFormat, wgfDistanceToText, wgfJoinWillGoAddress, wgfTimeToText } from "../../functions/WgFunctions.jsx";
import WgReadingInfo from '../../../components_only_this_platform/elements/WgReadingInfo.jsx';
import WgBox from "../../../components_only_this_platform/elements/WgBox.jsx";

import WgJustifyContent from "../WgJustifyContent.jsx";
import WgText from "../../../components_only_this_platform/elements/WgText.jsx";
const WgInfo = ({ willGo }) => {
    return (
        willGo ? (
            <>

                <WgJustifyContent justifyContent="space-between">
                    <WgText variant="info" text="Nereden" />
                    <WgBox style={{ width: 'fit-content', display: 'flex', alignItems: 'center',minWidth:'133px', backgroundColor: 'rgb(99,99,99)', paddingLeft: '5px', paddingRight: '5px', borderRadius: '50px' }}>
                        <WgText align="center" style={{ fontSize: '13px', marginLeft: '5px', marginRight: '5px',color:'#fff' }} text={(wgfDistanceToText(willGo.distance) + '   ' + wgfTimeToText(willGo.time))}/>
                    </WgBox>
                    <WgText variant="info" align="right" text="Nereye" />
                </WgJustifyContent>
                <WgJustifyContent alignItems="flex-start" mb="30px">
                    <WgBox style={{ width: '50%' }}>
                        <WgBox style={{ paddingLeft: '7px' }}>
                            <WgText variant="subtitle2" text={wgfJoinWillGoAddress(willGo, true)}/>
                            <WgText variant="subtitle2" text={willGo.fromCity}/>
                            {
                                (willGo.fromAddress) && (
                                    <WgText variant="subtitle2" text={willGo.fromAddress}/>
                                )
                            }
                        </WgBox>
                    </WgBox>
                    <WgBox style={{ width: '50%', borderLeft: '3px solid rgb(99,99,99)' }}>
                        <WgBox style={{ paddingLeft: '7px' }}>
                            <WgText  variant="subtitle2" align="right" text={wgfJoinWillGoAddress(willGo, false)}/>
                            <WgText  variant="subtitle2" align="right" text={willGo.toCity}/>
                            {
                                (willGo.toAddress) && (
                                    <WgText  variant="subtitle2" align="right" text={willGo.toAddress}/>
                                )
                            }
                        </WgBox>
                    </WgBox>
                </WgJustifyContent>
                {
                    willGo.serviceAnswers && (
                        JSON.parse(willGo.serviceAnswers).map((item, index) => {
                            return (

                                <WgBox key={index}>
                                    <WgJustifyContent justifyContent="flex-start">
                                        <WgText variant="info" style={{width:'auto'}} text={`${item.q} :`} />
                                        <WgText variant="subtitle2" style={{ fontSize: '15px',width:'auto' }} text={item.a}/>
                                    </WgJustifyContent>
                                </WgBox>
                            )
                        })
                    )
                }
                {
                    willGo.description && (
                        <>
                            <WgText variant="info" text="Açıklama" />
                            <WgBox style={{ paddingLeft: '7px' }}>
                                <WgText variant="subtitle2" style={{ fontSize: '15px' }} text={willGo.description}/>
                            </WgBox>
                        </>
                    )
                }
                <WgBox style={{marginBottom:'13px', marginTop:'13px'}}>
                    <WgText variant="info" size="sm" text={wgfDateTimeFormat(willGo.date, 'dd MMM yyyy HH:mm')} />
                    <WgText variant="info" size="sm" text={("Gürüntülenme Sayısı:" + willGo.detailViewedCount)} />
                </WgBox>
            </>
        ) : (<WgReadingInfo />)
    )
}
export default WgInfo;