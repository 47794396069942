import { useContext, useEffect } from "react";
import { useState } from "react";
import { wgfAxiosGet, wgfAxiosPost, wgfChangeState, wgfIsNotNull, wgfIsNotNullWithCustomMessage, wgfObjectClone } from "../functions/WgFunctions";
import WgReadingInfo from '../../components_only_this_platform/elements/WgReadingInfo';


import WgImageCrop from "../../components_only_this_platform/imageCrop/WgImageCrop";
import WgJustifyContent from "../ui/WgJustifyContent";
import WgText from "../../components_only_this_platform/elements/WgText";
import WgButtonEditWithPassword from "../elements/WgButtonEditWithPassword";
import WgImage from "../../components_only_this_platform/elements/WgImage";
import WgBox from "../../components_only_this_platform/elements/WgBox";
import WgTextarea from "../../components_only_this_platform/elements/WgTextarea";
import WgInput from "../../components_only_this_platform/elements/WgInput";
import WgAddress from "./WgAddress";
import { WgAppContext, WgfSnackbarFunc } from "../../App";
import { wgsButtonIcon, wgsGalleryBoxStyles } from "../ui/WgStyles";

import WgIconXMark from "../../components_only_this_platform/icons/WgIconXMark";
import WgSpaceVertical from "../../components_only_this_platform/elements/WgSpaceVertical";


import WgButton from "../../components_only_this_platform/elements/WgButton";
import WgIconEdit from "../../components_only_this_platform/icons/WgIconEdit";

const addressInitial = {
    cityId: 0,
    cityName: '',
    countryId: 0,
    countryName: '',
    districtId: 0,
    districtName: ''
}

const WgChauffeurBaseInfo = ({ isCreate }) => {
    const { wgAppObjects } = useContext(WgAppContext);
    const [chauffeurData, setChauffeurData] = useState(null);
    const [certificateOfAuthorization, setCertificateOfAuthorization] = useState(null);
    const [galleryImage1, setGalleryImage1] = useState(null);
    const [galleryImage2, setGalleryImage2] = useState(null);
    const [galleryImage3, setGalleryImage3] = useState(null);
    const [galleryImage4, setGalleryImage4] = useState(null);
    const [galleryImage5, setGalleryImage5] = useState(null);
    const [galleryImage6, setGalleryImage6] = useState(null);
    const [selectAddress, setSelectAddress] = useState(false);
    const [newAddress, setNewAddress] = useState(addressInitial);
    const reset = () => {
        wgfAxiosGet('chauffeur/get', null, setChauffeurData, null, null, null, false);
    }
    useEffect(() => {
        reset();
    }, []);

    useEffect(() => {
        wgfChangeState(certificateOfAuthorization, 'certificateOfAuthorization', chauffeurData, setChauffeurData);
    }, [certificateOfAuthorization]);
    useEffect(() => {
        if (newAddress && chauffeurData) {
            let _temp = wgfObjectClone(chauffeurData);
            _temp['address'] = newAddress.address;
            _temp['city'] = newAddress.cityName;
            _temp['country'] = newAddress.countryName;
            _temp['district'] = newAddress.districtName;
            _temp['districtId'] = newAddress.districtId;
            _temp['galleryImage1'] = galleryImage1;
            _temp['galleryImage2'] = galleryImage2;
            _temp['galleryImage3'] = galleryImage3;
            _temp['galleryImage4'] = galleryImage4;
            _temp['galleryImage5'] = galleryImage5;
            _temp['galleryImage6'] = galleryImage6;

            //_temp['location'] = wgfLocationValuesToString(newAddress.location); //hata olursa bundan dolayidir
            _temp['location'] = newAddress.location;
            setChauffeurData(_temp);
        }
    }, [newAddress])

    const update = () => {
        if (wgfIsNotNullWithCustomMessage([chauffeurData.password], 'Bilgi güvenliğiniz için parolayı da yazınız.', 'info')) {
            if (wgfIsNotNull([chauffeurData.phone, chauffeurData.email, chauffeurData.address], true)) {
                let _temp = wgfObjectClone(chauffeurData);
                _temp['galleryImage1'] = galleryImage1 ?? _temp['galleryImage1'];
                _temp['galleryImage2'] = galleryImage2 ?? _temp['galleryImage2'];
                _temp['galleryImage3'] = galleryImage3 ?? _temp['galleryImage3'];
                _temp['galleryImage4'] = galleryImage4 ?? _temp['galleryImage4'];
                _temp['galleryImage5'] = galleryImage5 ?? _temp['galleryImage5'];
                _temp['galleryImage6'] = galleryImage6 ?? _temp['galleryImage6'];
                /*
                if (_temp.isCompanyAccount == false) {
                    _temp['companyLogo'] = null;
                    _temp['companyName'] = null;
                }
                */
                wgfAxiosPost('chauffeur/update', _temp, reset, null, null);
                wgfChangeState('', 'password', chauffeurData, setChauffeurData);
            }
        }
    }
    const register = () => {
        if (wgfIsNotNull([chauffeurData.phone, chauffeurData.email, chauffeurData.address, chauffeurData.webSite, chauffeurData.aboutUs], true)) {

            let _temp = wgfObjectClone(chauffeurData);
            _temp['galleryImage1'] = galleryImage1;
            _temp['galleryImage2'] = galleryImage2;
            _temp['galleryImage3'] = galleryImage3;
            _temp['galleryImage4'] = galleryImage4;
            _temp['galleryImage5'] = galleryImage5;
            _temp['galleryImage6'] = galleryImage6;
            /*
              if (_temp.isCompanyAccount == false) {
                  _temp['companyLogo'] = null;
                  _temp['companyName'] = null;
              }
            */
            wgfAxiosPost('chauffeur/create', _temp, null, null, null, () => {
                setTimeout(() => {
                    wgAppObjects.functions.logOut();
                }, 5000);
                WgfSnackbarFunc('Oturumunuz kapatılacak.Tekrar giriş yapmanız geremekte.', 'info');
            });
        }
        else {
            WgfSnackbarFunc('İstenilen bilgileri doldurunuz.', 'info');
        }
    }
    const deleteImage = (imageIndex) => {
        let _temp = wgfObjectClone(chauffeurData);

        switch (imageIndex) {
            case 1:
                setGalleryImage1(null);
                _temp['galleryImage1'] = galleryImage1;
                break;
            case 2:
                setGalleryImage2(null);
                _temp['galleryImage2'] = galleryImage2;
                break;
            case 3:
                setGalleryImage3(null);
                _temp['galleryImage3'] = galleryImage3;
                break;
            case 4:
                setGalleryImage4(null);
                _temp['galleryImage4'] = galleryImage4;
                break;
            case 5:
                setGalleryImage5(null);
                _temp['galleryImage5'] = galleryImage5;
                break;
            case 6:
                setGalleryImage6(null);
                _temp['galleryImage6'] = galleryImage6;
                break;
        }
        setChauffeurData(_temp);
    }
    return (
        chauffeurData ? <>
            <WgBox style={{ 'maxWidth': 420 }}>
                <WgText variant="info" size="sm" text="* işareti olan tüm alanlar zorunludur." />
                <WgInput placeholder="İş Telefonu *" propstate={[chauffeurData, setChauffeurData, 'phone']} />
                <WgInput placeholder="İş Eposta *" validType="email" propstate={[chauffeurData, setChauffeurData, 'email']} />
                <WgInput placeholder="Web Site Adresiniz" propstate={[chauffeurData, setChauffeurData, 'webSite']} />


            </WgBox>
            <WgSpaceVertical />
            <WgJustifyContent justifyContent="flex-start">
                <WgText variant="h5" text="Yetki Belgesinin Resmi" />
                <WgImageCrop aspect={4 / 4} setImageSate={setCertificateOfAuthorization} />
            </WgJustifyContent>

            <WgImage src={chauffeurData.certificateOfAuthorization} style={{ width: '133px', borderRadius: '10px' }} />
            <WgSpaceVertical />
            <WgJustifyContent justifyContent="flex-start">
                <WgText variant="h5" text="İş Adresiniz" />
                <WgButton onClick={() => setSelectAddress(true)} icon={<WgIconEdit />} />
            </WgJustifyContent>

            <WgText text={chauffeurData.address}/>
            {
                chauffeurData.city && (
                    <WgText text={`${chauffeurData.city} / ${chauffeurData.country} / ${chauffeurData.district}`}/>
                )
            }
            <WgAddress
                modalShow={selectAddress}
                modalShowState={setSelectAddress}
                address={newAddress}
                addressState={setNewAddress}
                isAddMyAddresses={false}
            />
            <WgSpaceVertical />
            <WgText variant="h5" text="Hakkınızda açıklama yazabilirsiniz."/>
            <WgTextarea rows={7} value={chauffeurData.aboutUs} propstate={[chauffeurData, setChauffeurData, 'aboutUs']} placeholder="Müşterilere hakkınızda bilgiler vermek için buraya yazınız." />
            <WgSpaceVertical />
            <WgText variant="h5" text="İşiniz ile ilgili resimler yükleyebilirsiniz."/>
            <WgBox style={wgsGalleryBoxStyles.container}>
                <WgBox style={wgsGalleryBoxStyles.imageBox}>
                    {
                        (galleryImage1 || chauffeurData.galleryImage1) && (
                            <WgBox style={wgsGalleryBoxStyles.deleteButtonArea}>
                                <WgButton onClick={() => deleteImage(1)} style={{...wgsButtonIcon,...wgsGalleryBoxStyles.deleteButton}} icon={<WgIconXMark />}/>
                            </WgBox>
                        )
                    }
                    <WgBox style={wgsGalleryBoxStyles.imageButtonArea}>
                        <WgBox style={wgsGalleryBoxStyles.imageButtonBox}>
                            <WgImageCrop aspect={4 / 4} setImageSate={setGalleryImage1} />
                        </WgBox>
                    </WgBox>
                    <WgImage src={galleryImage1 ?? chauffeurData.galleryImage1} style={wgsGalleryBoxStyles.image} />
                </WgBox>
                <WgBox style={wgsGalleryBoxStyles.imageBox}>
                    {
                        (galleryImage2 || chauffeurData.galleryImage2) && (
                            <WgBox style={wgsGalleryBoxStyles.deleteButtonArea}>
                                <WgButton onClick={() => deleteImage(2)} style={{...wgsButtonIcon,...wgsGalleryBoxStyles.deleteButton}} icon={<WgIconXMark />}/>
                            </WgBox>
                        )
                    }
                    <WgBox style={wgsGalleryBoxStyles.imageButtonArea}>
                        <WgBox style={wgsGalleryBoxStyles.imageButtonBox}>
                            <WgImageCrop aspect={4 / 4} setImageSate={setGalleryImage2} />
                        </WgBox>
                    </WgBox>
                    <WgImage src={galleryImage2 ?? chauffeurData.galleryImage2} style={wgsGalleryBoxStyles.image} />
                </WgBox>
                <WgBox style={wgsGalleryBoxStyles.imageBox}>
                    {
                        (galleryImage3 || chauffeurData.galleryImage3) && (
                            <WgBox style={wgsGalleryBoxStyles.deleteButtonArea}>
                                <WgButton onClick={() => deleteImage(3)} style={{...wgsButtonIcon,...wgsGalleryBoxStyles.deleteButton}} icon={<WgIconXMark />}/>
                            </WgBox>
                        )
                    }
                    <WgBox style={wgsGalleryBoxStyles.imageButtonArea}>
                        <WgBox style={wgsGalleryBoxStyles.imageButtonBox}>
                            <WgImageCrop aspect={4 / 4} setImageSate={setGalleryImage3} />
                        </WgBox>
                    </WgBox>
                    <WgImage src={galleryImage3 ?? chauffeurData.galleryImage3} style={wgsGalleryBoxStyles.image} />
                </WgBox>
                <WgBox style={wgsGalleryBoxStyles.imageBox}>
                    {
                        (galleryImage4 || chauffeurData.galleryImage4) && (
                            <WgBox style={wgsGalleryBoxStyles.deleteButtonArea}>
                                <WgButton onClick={() => deleteImage(4)} style={{...wgsButtonIcon,...wgsGalleryBoxStyles.deleteButton}} icon={<WgIconXMark />}/>
                            </WgBox>
                        )
                    }
                    <WgBox style={wgsGalleryBoxStyles.imageButtonArea}>
                        <WgBox style={wgsGalleryBoxStyles.imageButtonBox}>
                            <WgImageCrop aspect={4 / 4} setImageSate={setGalleryImage4} />
                        </WgBox>
                    </WgBox>
                    <WgImage src={galleryImage4 ?? chauffeurData.galleryImage4} style={wgsGalleryBoxStyles.image} />
                </WgBox>
                <WgBox style={wgsGalleryBoxStyles.imageBox}>
                    {
                        (galleryImage5 || chauffeurData.galleryImage5) && (
                            <WgBox style={wgsGalleryBoxStyles.deleteButtonArea}>
                                <WgButton onClick={() => deleteImage(5)} style={{...wgsButtonIcon,...wgsGalleryBoxStyles.deleteButton}} icon={<WgIconXMark />}/>
                            </WgBox>
                        )
                    }
                    <WgBox style={wgsGalleryBoxStyles.imageButtonArea}>
                        <WgBox style={wgsGalleryBoxStyles.imageButtonBox}>
                            <WgImageCrop aspect={4 / 4} setImageSate={setGalleryImage5} />
                        </WgBox>
                    </WgBox>
                    <WgImage src={galleryImage5 ?? chauffeurData.galleryImage5} style={wgsGalleryBoxStyles.image} />
                </WgBox>
                <WgBox style={wgsGalleryBoxStyles.imageBox}>
                    {
                        (galleryImage6 || chauffeurData.galleryImage6) && (
                            <WgBox style={wgsGalleryBoxStyles.deleteButtonArea}>
                                <WgButton onClick={() => deleteImage(6)} style={{...wgsButtonIcon,...wgsGalleryBoxStyles.deleteButton}} icon={<WgIconXMark />}/>
                            </WgBox>
                        )
                    }
                    <WgBox style={wgsGalleryBoxStyles.imageButtonArea}>
                        <WgBox style={wgsGalleryBoxStyles.imageButtonBox}>
                            <WgImageCrop aspect={4 / 4} setImageSate={setGalleryImage6} />
                        </WgBox>
                    </WgBox>
                    <WgImage src={galleryImage6 ?? chauffeurData.galleryImage6} style={wgsGalleryBoxStyles.image} />
                </WgBox>
            </WgBox>
            <WgJustifyContent mt="35px" mb="25px">
                {
                    isCreate == true ? (
                        <WgButton onClick={register} title="Kaydet"/>
                    ) : (
                        <WgButtonEditWithPassword buttonEvent={update} propstate={[chauffeurData, setChauffeurData, 'password']} />
                    )
                }
            </WgJustifyContent>
        </> : <WgReadingInfo />
    )
}
export default WgChauffeurBaseInfo;