import { useContext, useState } from 'react';
import WgInfo from "./WgInfo";
import WgEditOffer from "./WgEditOffer";
import WgJustifyContent from '../WgJustifyContent';
import WgUserNameImageCard from '../WgUserNameImageCard';
import WgBox from '../../../components_only_this_platform/elements/WgBox';
import { WgAppContext } from '../../../App';
import WgWillGoComplete from './WgWillGoComplete';
import WgText from '../../../components_only_this_platform/elements/WgText';
import { wgsButton, wgsButtonIcon } from '../WgStyles';
import WgIconArrowLeft from '../../../components_only_this_platform/icons/WgIconArrowLeft';
import WgButton from '../../../components_only_this_platform/elements/WgButton';

const WgWillGoDetailsForChauffeur = () => {
    const { wgGenSelectedWillGo, wgAppObjects } = useContext(WgAppContext);
    const [detailsShow, setDetailsShow] = useState(true);
    const [willGoCompleteModalShow, setWillGoCompleteModalShow] = useState(false);
    let myOfferControl = (wgGenSelectedWillGo.myOffer != null && wgGenSelectedWillGo.myOffer.price > 0);
    return (
        <WgBox style={{ padding: '10px' }}>
            <WgJustifyContent mb="13px" mt="7px" justifyContent="flex-start">
                <WgBox style={{ paddingRight: '7px' }}>
                    <WgButton onClick={wgAppObjects.functions.goBack} style={wgsButtonIcon} icon={<WgIconArrowLeft />}/>
                </WgBox>
                {
                    (wgGenSelectedWillGo.status != 4) && (
                        (wgGenSelectedWillGo.status == 3) ? (
                            <>
                                <WgButton style={wgsButton.normal} onClick={() => setWillGoCompleteModalShow(true)} title="Değerlendir"/>
                                {
                                    (willGoCompleteModalShow == true) && (
                                        <WgWillGoComplete showState={willGoCompleteModalShow} setShowState={setWillGoCompleteModalShow} isForChauffeur={true} />
                                    )
                                }
                            </>
                        ) : (
                            /*Teklif onaylanmis ise degistirilemez*/
                            (wgGenSelectedWillGo.status == 2) ? (
                                <WgText text={`Teklifim:₺${wgGenSelectedWillGo.myOffer.price}`}/>
                            ) : (
                                <WgButton style={wgsButton.normal} onClick={() => setDetailsShow(!detailsShow)} title={detailsShow == true ? (myOfferControl == true ? `Teklifim:₺${wgGenSelectedWillGo.myOffer.price}` : 'Teklif Sun') : 'Ilan Detayını Göster'}/>
                            )
                        )
                    )
                }
            </WgJustifyContent>
            {
                detailsShow == true ? (
                    <>
                        <WgInfo willGo={wgGenSelectedWillGo} />
                        <WgBox style={{ paddingLeft: '10px' }}>
                            <WgUserNameImageCard roleIsChauffeur={false} userData={wgGenSelectedWillGo.customer} hideCommentsOrProfile={false} hideMessage={false} hideLocation={true} showMyData={false} showLogOut={false} />
                        </WgBox>
                    </>
                ) : (
                    <WgEditOffer willGo={wgGenSelectedWillGo} isUpdate={myOfferControl} />
                )
            }


        </WgBox>
    )
}
export default WgWillGoDetailsForChauffeur;