import { Icon } from 'leaflet'
import { WgfSnackbarFunc } from "../../App";
export const wgfMapDatas = {
    zoom: {
        min: 6,
        max: 16,
        default: 9
    },
    center: [40.7529776, 29.1723124],
    markerIconSize: [30, 30],
    altLineOptions: {
        styles: [
            { color: 'black', opacity: 0.15, weight: 9 },
            { color: 'white', opacity: 0.8, weight: 6 },
            { color: 'blue', opacity: 0.5, weight: 2 }
        ]
    },
    maps: {
        google: 'http://mt0.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
        base: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        openWeather: "https://tile.openweathermap.org/map/precipitation_new/{z}/{x}/{y}.png?appid=dd98512373ced7ba62443c5c2909563e"
    },
    iconUrls: {
        default: "https://files.gidecekvar.com/images/map/default.png",
        start: 'https://files.gidecekvar.com/images/map/route-start.png',
        end: 'https://files.gidecekvar.com/images/map/route-end.png',
        via: 'https://files.gidecekvar.com/images/map/default.png'
    }
}
export const wgfMapCreateMarkerIcon = (imageUrl) => {
    return new Icon({ iconUrl: imageUrl, iconSize: [34, 34], iconAnchor: [17, 34] })
}

export const wgfMapGoToPosition = (map, position, zoomValue) => {
    try {
        if (map && position) {
            map.flyTo(position, (zoomValue ? zoomValue : map.getZoom()));
        }
    } catch (e) {
        console.log(e);
        WgfSnackbarFunc("ERR, Konuma yönlendirilemedi. Console'a bak", 'error');
    }
}



