import WgImage from "../../components_only_this_platform/elements/WgImage";
import WgBox from "../../components_only_this_platform/elements/WgBox";

import WgIconXMark from "../../components_only_this_platform/icons/WgIconXMark";
import WgButton from "../../components_only_this_platform/elements/WgButton";

const WgImageShowModal = ({ imageState, setImageState }) => {
    const handleClose = () => {
        setImageState(null);
    }
    return (
        <>
            <WgBox onClick={handleClose} style={{ position: 'fixed', cursor: 'pointer', backgroundColor: 'rgba(0,0,0,0.87)', zIndex: 9994, top: '0px', left: '0px', width: '100vw', height: '100vh' }}></WgBox>
            <WgButton style={{ position: 'fixed', top: '20px', right: '10px', zIndex: 9999, color: '#fff' }} onClick={handleClose} icon={<WgIconXMark />}/>
            <WgBox onClick={handleClose} style={{ position: 'fixed', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 9996, top: '0px', left: '0px', width: '100vw', height: '100vh' }}>
                <WgImage style={{ maxWidth: '95vw', maxHeight: '95vh' }} src={imageState} />
            </WgBox>
        </>
    )
}
export default WgImageShowModal;