import { useState } from "react";
import { wgfAxiosPost, wgfChangeState, wgfDateTimeFormat, wgfIsNotNull, wgfIsNotNullWithCustomMessage } from "../functions/WgFunctions";
import { useEffect } from "react";
import WgReadingInfo from "../../components_only_this_platform/elements/WgReadingInfo";
import WgSetReduxUserInfoes from "./WgSetReduxUserInfoes";
import WgImageCrop from "../../components_only_this_platform/imageCrop/WgImageCrop";
import WgUserNameImageCard from "../ui/WgUserNameImageCard";
import WgText from "../../components_only_this_platform/elements/WgText";
import WgButtonEditWithPassword from "../elements/WgButtonEditWithPassword";
import WgJustifyContent from "../ui/WgJustifyContent";
import WgBox from "../../components_only_this_platform/elements/WgBox";
import WgInput from "../../components_only_this_platform/elements/WgInput";
import WgSwitch from "../../components_only_this_platform/elements/WgSwitch";

import WgPasswordChange from "../ui/WgPasswordChange";
import WgSpaceVertical from "../../components_only_this_platform/elements/WgSpaceVertical";

import { wgsButton } from "../ui/WgStyles";

import WgButton from "../../components_only_this_platform/elements/WgButton";

const WgCustomerInfo = () => {
    const [userData, setUserData] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [userReduxUpdate, setUserReduxUpdate] = useState(false);
    const [passwordChangeModal, setPasswordChangeModal] = useState(false);
    useEffect(() => {
        if (croppedImage) {
            wgfChangeState(croppedImage, 'profileImage', userData, setUserData);
        }
    }, [croppedImage]);

    useEffect(() => {
        reset();
    }, [])
    const reset = () => {
        wgfAxiosPost('account/get', null, null, setUserData, null);
    }

    const update = () => {
        if (wgfIsNotNullWithCustomMessage([userData.password], 'Bilgi güvenliğiniz için parolayı da yazınız.', 'info')) {
            if (wgfIsNotNull([userData.phone], true)) {
                let _params = {
                    profileImage: userData.profileImage,
                    email: userData.email,
                    phone: userData.phone,
                    isWillGoShowPhone: userData.isWillGoShowPhone,
                    isWillGoShowEmail: userData.isWillGoShowEmail,
                    isWillGoShowFirstName: userData.isWillGoShowFirstName,
                    isWillGoShowLastName: userData.isWillGoShowLastName,
                    password: userData.password,
                    isCompanyAccount: userData.isCompanyAccount,
                    companyName: userData.companyName
                }
                if (_params.isCompanyAccount == false) {
                    _params['companyName'] = null;
                }
                wgfAxiosPost('account/update', _params, reset, null, null);
                wgfChangeState('', 'password', userData, setUserData);
                setTimeout(() => {
                    setUserReduxUpdate(true);
                }, 1000);
            }
        }
    }
    return (
        <>
            {userData ? <>

                {
                    userData && (
                        <>
                            <WgBox style={{ display: 'flex', alignItems: 'center' }}>
                                <WgBox style={{ position: 'relative', minHeight: '50px', marginBottom: '20px', marginRight: '25px' }}>
                                    <WgBox style={{ position: 'absolute', inset: 0 }}>
                                        <WgImageCrop aspect={4 / 4} setImageSate={setCroppedImage} />
                                    </WgBox>
                                </WgBox>
                                <WgUserNameImageCard userData={userData} hideCommentsOrProfile={true} hideEmail={true} hidePhone={true} hideLocation={true} showMyData={false} showLogOut={false} />
                            </WgBox>
                            <WgSpaceVertical />
                            <WgSwitch label="Şirket hesabı olarak kullanılacak" propstate={[userData, setUserData, 'isCompanyAccount']} />
                            {
                                (userData.isCompanyAccount == true) && (
                                    <>
                                        <WgInput placeholder="Şirket Adı" propstate={[userData, setUserData, 'companyName']} />
                                        <WgText variant="info" size="sm" text="Müşteriye artık şirket adı gösterilecektir." />
                                        <WgSpaceVertical />
                                    </>
                                )
                            }
                            <WgInput placeholder="Telefon numarası" validType="phone" propstate={[userData, setUserData, 'phone']} />
                            <WgInput placeholder="Eposta adresiniz" validType="email" propstate={[userData, setUserData, 'email']} />
                            <WgSpaceVertical />
                            <WgSwitch label="İsmi göster" propstate={[userData, setUserData, 'isWillGoShowFirstName']} />
                            <WgSwitch label="Soyismi göster" propstate={[userData, setUserData, 'isWillGoShowLastName']} />
                            <WgSwitch label="Telefonu göster" propstate={[userData, setUserData, 'isWillGoShowPhone']} />
                            <WgSwitch label="Email adresini göster" propstate={[userData, setUserData, 'isWillGoShowEmail']} />
                            <WgText variant="info" size="sm" text="İlanlarınızda gizlemek istediğiniz bilgileri ayarlayabilirsiniz.Sadece teklifini onayladığınız şoföre gösterilecektir." />
                        </>
                    )
                }
                {
                    userData && (
                        <WgJustifyContent mt="35px">
                            <WgButtonEditWithPassword buttonEvent={update} propstate={[userData, setUserData, 'password']} />
                        </WgJustifyContent>
                    )
                }
                <WgSpaceVertical />
                <hr />
                <WgSpaceVertical />
                <WgButton onClick={() => setPasswordChangeModal(true)} style={wgsButton.normal} title="Parolayı Değiştir"/>
                <WgText variant="info" size="sm" text={(`Parola son güncellenme: ${wgfDateTimeFormat(userData.lastPasswordChange, 'dd.MM.yyyy HH.mm')}`)} />
                <WgSpaceVertical />
                {
                    (passwordChangeModal == true) && (
                        <WgPasswordChange showModal={passwordChangeModal} setShowModal={setPasswordChangeModal} />
                    )
                }
            </> : <WgReadingInfo />}
            {
                userReduxUpdate && <WgSetReduxUserInfoes paramUserInfo={null} />
            }


        </>
    )
}
export default WgCustomerInfo;