import { useContext } from "react";
import WgImage from "../../components_only_this_platform/elements/WgImage";
import WgBox from "../../components_only_this_platform/elements/WgBox";
import WgCarousel from "../../components_only_this_platform/elements/WgCarousel";
import { WgAppContext } from "../../App";
import { useState } from "react";
import { wgfObjectClone } from "../functions/WgFunctions";
import WgButtonToggle from "../../components_only_this_platform/elements/WgButtonToggle";
import { useEffect } from "react";
import WgJustifyContent from "./WgJustifyContent";
import WgText from "../../components_only_this_platform/elements/WgText";

const WgServiceToggleButtonCarousel = ({ selectedState, setSelectedState, isMultiSelect }) => {
    const { appData } = useContext(WgAppContext);
    const [services, setServices] = useState(null);
    useEffect(() => {
        if (appData.services) {
            let _services = wgfObjectClone(appData.services);
            /*API'den alinin kayitlarda selected field'i olmayacak.Boşuna servere yük oluşturmasın. İhtiyaca göre bu şekilde eklenecek.*/
            _services = _services.map(item => {
                item['selected'] = false;
                return item;
            })
            setServices(_services);
        }
    }, [appData])
    /*
       const selectService = (id) => {
           let _services = wgfObjectClone(services);
           _services = _services.map(item => {
               if (item.id == id) {
                   item.selected = !item.selected;
               }
               return item;
           })
           if (setSelectedState) {
               //Secilenleri geriye dondur
               let _f = _services.filter(f => f.selected == true);
               if (_f && _f.length > 0) {
                   setSelectedState(_f);
               }
               else {
                   setSelectedState(null);
               }
           }
           setServices(_services)
   
       }
    */
    const handleSelectService = (id) => {
        let _services = wgfObjectClone(services);
        if (isMultiSelect == true) {
            _services = _services.map(item => {
                if (item.id == id) {
                    item.selected = !item.selected;
                }
                return item;
            })
            if (setSelectedState) {
                /*Secilenleri geriye dondur */
                setSelectedState(_services.filter(f => f.selected == true));
            }
        }
        else {
            _services = _services.map(item => {
                if (item.id == id) {
                    item.selected = true;
                }
                else {
                    item.selected = false;
                }
                return item;
            })
            if (setSelectedState) {
                /*Secilenleri geriye dondur */
                setSelectedState(_services.find(f => f.selected == true));
            }
        }
        setServices(_services)
    }
    let carouselServices = [];
    if (services) {
        services.forEach(item => {
            carouselServices.push(
                <WgButtonToggle size="small" onClick={() => handleSelectService(item.id)} selected={(selectedState ? selectedState.id == item.id : item.selected)}>
                    <WgBox style={{ minWidth: '60px' }}>
                        <WgJustifyContent justifyContent="center">
                            <WgImage style={{ width: '41px' }} src={item.logo} />
                        </WgJustifyContent>
                        <WgText align="center" style={{ fontSize: '12px', marginTop: '10px' }} text={item.name} />
                    </WgBox>
                </WgButtonToggle>
            )
        })
    }
    return (
        <WgCarousel spaceBetween={0} items={carouselServices} />
    )
}
export default WgServiceToggleButtonCarousel;