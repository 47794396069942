
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination";
const WgCarousel = ({spaceBetween,items}) => {
    return (
        <Swiper
            spaceBetween={spaceBetween ?? 3}
            slidesPerView={"auto"}
            centeredSlides={false}
            grabCursor={true}
            style={{ paddingTop: '7px', paddingBottom: '10px' }}
        >
            <SwiperSlide style={{ width: '13px' }}></SwiperSlide>
            {
                items && items.map((item, index) => {
                    return <SwiperSlide key={index} style={{ width: 'fit-content' }}>{item}</SwiperSlide>
                })
            }
            <SwiperSlide style={{ width: '99px' }}></SwiperSlide>
        </Swiper>
    )
}
export default WgCarousel;

/*

disableButtonsControls: < > bu şekilde nex/prev butonlarını gizler

 autoPlay
        autoPlayControls
        autoPlayStrategy="none"
        autoPlayInterval={1000}
        animationDuration={1000}
        animationType="fadeout"
        infinite
        touchTracking={false}
        disableDotsControls
        disableButtonsControls
        animationType="fadeout"
        animationDuration={700}
        disableButtonsControls
        infinite = loop
        mouseTracking
        items={items}
        autoWidth
        controlsStrategy="alternate"

*/

/*
import { Children, cloneElement } from 'react';
import WgBox from './WgBox';
export const carouselDragStart = (e) => e.preventDefault(); //childs'lere bunu ekle
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

  const children = props.children;
    let childrenArray = [];

    if (children) {
        if (Array.isArray(children)) {
            childrenArray = Children.toArray(children);
        } else {
            childrenArray.push(children);
        }
    }
    childrenArray = childrenArray.map(item => {
        return cloneElement(item, { role: "presentation", onDragStart: carouselDragStart });
    })

const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
};

  props.responsive ? (
                    <AliceCarousel
                        animationType="fadeout"
                        animationDuration={700}
                        disableButtonsControls
                        mouseTracking
                        items={childrenArray}
                        responsive={props.responsive}
                        controlsStrategy="alternate"
                    />
                ) : (
                    <AliceCarousel
                        animationType="fadeout"
                        animationDuration={700}
                        disableButtonsControls
                        disableDotsControls

                        mouseTracking
                        items={childrenArray}
                        autoWidth
                        controlsStrategy="alternate"
                    />
                )
*/