import { Button, IconButton } from "@mui/material";
import WgBox from "./WgBox";
import WgText from "./WgText";
import { wgsButton, wgsButtonIcon } from "../../components/ui/WgStyles";

const WgButton = ({ title, icon, count, onClick, style, size, key }) => {
    let _style = {};
    if (icon && (!title)) {
        _style = wgsButtonIcon;
    }
    else {
        _style = wgsButton.normal;
    }
    if (style) {
        _style = { ..._style, ...style };
    }
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    }
    const createCounterArea = (count) => {
        return (
            <WgBox style={{ position: 'absolute', top: '-13px', right: '5px', borderRadius: '20px', padding: 0, pointerEvents: 'none' }}>
                <WgText style={{ fontSize: '12px', fontWeight: 'bold', pointerEvents: 'none' }} text={count} />
            </WgBox>
        )
    }
    const _element = () => {
        if (title && icon && count) {
            return (
                <Button key={key} size={size ?? 'medium'} style={{ ..._style, marginRight: '10px', position: 'relative' }} onClick={handleClick}>
                    {icon}
                    {title}
                    {createCounterArea(count)}
                </Button>
            )
        }
        else if (title && icon) {
            return (
                <Button key={key} size={size ?? 'medium'} style={_style} onClick={handleClick}>
                    {icon}
                    {title}
                </Button>
            )
        }
        else if (title && count) {
            return (
                <Button key={key} size={size ?? 'medium'} style={{ ..._style, marginRight: '10px', position: 'relative' }} onClick={handleClick}>
                    {title}
                    {createCounterArea(count)}
                </Button>
            )
        }
        else if (icon && count) {
            return (
                <Button key={key} size={size ?? 'medium'} style={{ ..._style, marginRight: '10px', position: 'relative' }} onClick={handleClick}>
                    {icon}
                    {createCounterArea(count)}
                </Button>
            )
        }
        else if (icon) {
            return (
                <IconButton key={key} size={size ?? 'medium'} style={_style} onClick={handleClick}>
                    {icon}
                </IconButton>
            )
        }
        else if (title) {
            return (
                <Button key={key} size={size ?? 'medium'} style={_style} onClick={handleClick}>
                    {title}
                </Button>
            )
        }
    }
    return (_element())
}
export default WgButton;