import { LinearProgress } from "@mui/material";

import WgText from "./WgText";

const WgReadingInfo = () => {
    return (
        <>
            <WgText variant="info" text="Bilgiler Alınıyor..."/>
            <LinearProgress />
        </>
    )
}
export default WgReadingInfo;