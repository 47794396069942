/*
variants:
body1 : default text
body2 : small text
align:"center"
subtitle2: açıklama başlıkları (mini başlık)
*/
const _headerVariants = {
    'h1': '19px',
    'h2': '18px',
    'h3': '17px',
    'h4': '16px',
    'h5': '15px',
    'h6': '14px',
    'body1':'13px',
    'body2':'12px',
    'subtitle2':'14px',
};
const _fontSizes = {
    xs: '13px',
    sm: '14px',
    md: '15px',
};
const WgText = ({ key, style, variant, align, size, text }) => {
    let _style = {};
    if (style) {
        _style = { ..._style, ...style };
    }
    if (align) {
        if (align == 'center' || align == 'right') {
            _style['display']='block';
            _style['width']='100%';
            _style['textAlign']=align;
        }
        
    }
    if (variant) {
        _style = { display: 'block', width: '100%', fontWeight: '500', ..._style };
         if (variant == 'info') {
            _style['fontSize']=_fontSizes[size ?? 'md'];
            _style['color']='rgb(99,99,99)';
        }
        else {
            if (_headerVariants[variant]) {
                _style['fontSize'] = _headerVariants[variant];
            }
        }
    }
    return (
        <span key={key} style={_style}>
            {text}
        </span>
    )
}
export default WgText;