import WgJustifyContent from "../WgJustifyContent";
import WgOffers from "./WgOffers";
import WgInfo from "./WgInfo";
import { useContext, useState } from "react";

import WgBox from "../../../components_only_this_platform/elements/WgBox";
import WgButtonRemove from "../../elements/WgButtonRemove";
import { WgAppContext } from "../../../App";
import WgWillGoComplete from "./WgWillGoComplete";
import { wgsButton, wgsButtonIcon } from "../WgStyles";
import WgIconArrowLeft from "../../../components_only_this_platform/icons/WgIconArrowLeft";

import WgButton from "../../../components_only_this_platform/elements/WgButton";


const WgWillGoDetailsForCustomer = ({ closeEvent }) => {
    const { wgAppObjects, wgGenSelectedWillGo } = useContext(WgAppContext);
    const [detailsShow, setDetailsShow] = useState(true);
    const [willGoCompleteModalShow, setWillGoCompleteModalShow] = useState(false);
    const deleteWillGo = () => {
        wgAppObjects.functions.resetSelectedWillGo();
        wgAppObjects.functions.getMyWillGos(); //ilanların listesini gunceller.
        if (closeEvent) {
            closeEvent();
        }
    }

    return (
        <>
            <WgJustifyContent mb="13px" mt="7px" justifyContent="space-between">
                <WgBox>
                    <WgJustifyContent justifyContent="flex-start">
                        <WgBox style={{ paddingRight: '7px' }}>
                            <WgButton icon={<WgIconArrowLeft />} onClick={wgAppObjects.functions.goBack} style={wgsButtonIcon} />
                        </WgBox>
                        {
                            (wgGenSelectedWillGo && wgGenSelectedWillGo.status == 2 && wgGenSelectedWillGo.offerPrice && wgGenSelectedWillGo.offerPrice > 0) && (
                                <WgButton style={wgsButton.normal} onClick={() => setWillGoCompleteModalShow(true)} title="Sonlandır"/>
                            )
                        }
                        {
                            (detailsShow == true && wgGenSelectedWillGo && wgGenSelectedWillGo.offerCount > 0) ? (
                                <WgButton count={wgGenSelectedWillGo.offerCount} title={detailsShow == true ? 'Teklifler' : 'İlan Detay'} size="small" style={wgsButton.normal} onClick={() => setDetailsShow(!detailsShow)} />
                            ) : (
                                <WgButton title={detailsShow == true ? 'Teklifler' : 'İlan Detay'} size="small" style={wgsButton.normal} onClick={() => setDetailsShow(!detailsShow)} />
                            )
                        }
                    </WgJustifyContent>
                </WgBox>
                <WgButtonRemove url="willgo/delete" title="İlan Silinecek!" message="İlanınız silinecek. Devam edilsin mi?" id={wgGenSelectedWillGo.id} callback={deleteWillGo} />
            </WgJustifyContent>
            <WgBox style={{ padding: '7px' }}>

                {
                    detailsShow == true ? (
                        <WgInfo willGo={wgGenSelectedWillGo} />
                    ) : (
                        <WgOffers id={wgGenSelectedWillGo.id} />
                    )
                }
            </WgBox>

            {
                (willGoCompleteModalShow == true) && (
                    <WgWillGoComplete showState={willGoCompleteModalShow} setShowState={setWillGoCompleteModalShow} isForChauffeur={false} />
                )
            }
        </>
    )
}
export default WgWillGoDetailsForCustomer;