import { useContext } from "react";
import { WgAppContext } from "../../App";

import WgJustifyContent from "../ui/WgJustifyContent";
import WgBox from "../../components_only_this_platform/elements/WgBox";
import WgRating from "../elements/WgRating";
import WgText from "../../components_only_this_platform/elements/WgText";

import WgImage from "../../components_only_this_platform/elements/WgImage";
import { wgfDateToText } from "../functions/WgFunctions";
import WgIconArrowRightLong from "../../components_only_this_platform/icons/WgIconArrowRightLong";

const WgComments = ({ comments, roleischauffeur }) => {
    const { appData } = useContext(WgAppContext);
    const willGoCommentFromToCity = (comment) => {
        let _fromCity = appData.cities.find(f => f.value == comment.fromCityId);
        let _toCity = appData.cities.find(f => f.value == comment.toCityId);
        if (_fromCity && _toCity) {
            if (_fromCity.value == _toCity.value) {
                return (<WgText variant="info" text={(`${_fromCity.text} içinde.`)} />)
            }
            else {
                return (
                    <WgJustifyContent justifyContent="flex-start">
                        <WgText variant="info" text={_fromCity.text} />
                        <WgBox style={{ marginRight: '5px', marginLeft: '5px' }}>
                            <WgIconArrowRightLong />
                        </WgBox>
                        <WgText variant="info" text={_toCity.text} />
                    </WgJustifyContent>
                )
            }
        }
        return <WgText text=""/>;
    }
    return (
        (comments && comments.length > 0) && comments.map((item, index) => {
            return (
                <WgBox key={index} style={{ width: '100%', pointerEvents: 'none', width: '100%', borderBottom: '1px solid #ccc', marginTop: '10px', paddingBottom: '10px' }}>
                    <WgJustifyContent justifyContent="space-between">
                        <WgRating value={item.rating} />
                        <WgText variant="info" size="xs" text={wgfDateToText(item.date)} />
                    </WgJustifyContent>
                    <WgText text={item.comment}/>
                    <WgJustifyContent justifyContent="flex-start">
                        <WgText variant="h6" size="md" text={(item.firstName + ' ****')} />
                        {
                            /*HizmetVerdi/HizmetAldı burada ters mantık var.
                             Şoför gösteriliyorsa yorumu yazan müşteri(hizmet alır)
                             Müşteri gösteriliyorsa yorumu yazan şoför(hizmet verir)
                            */
                        }
                        <WgText variant="info" size="xs" text={(item.roleIsChauffeur == true ? '(Hizmet aldı.)':'(Hizmet verdi.)')} />
                    </WgJustifyContent>
                    <WgJustifyContent justifyContent="flex-start" mt="10px">
                        {
                            (appData && appData.services) && appData.services.map((sItem, sIndex) => {
                                if (sItem.id == item.serviceId) {
                                    return (
                                        <WgBox>
                                            <WgImage src={sItem.logo} style={{ width: '33px', marginRight: '10px' }} />
                                        </WgBox>
                                    )
                                }
                            })
                        }
                        <WgText variant="info" size="sm" text={willGoCommentFromToCity(item)} />
                    </WgJustifyContent>
                </WgBox>
            )
        })
    )
}
export default WgComments;