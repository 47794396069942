import { useContext, useEffect } from "react";
import { useState } from "react";
import { wgfAxiosPost, wgfObjectClone } from "../functions/WgFunctions";
import WgJustifyContent from "./WgJustifyContent";
import WgButtonEditWithPassword from "../elements/WgButtonEditWithPassword";
import WgImage from "../../components_only_this_platform/elements/WgImage";

import { WgAppContext } from "../../App";
import { wgRouteAddresses } from "../WgStaticValues";


import WgList from "../../components_only_this_platform/elements/WgList";
import WgButton from "../../components_only_this_platform/elements/WgButton";
import WgText from "../../components_only_this_platform/elements/WgText";
import WgSwitch from "../../components_only_this_platform/elements/WgSwitch";

const WgServiceSelector = () => {
    const { wgAppObjects } = useContext(WgAppContext);
    const [chauffeurServices, setChauffeurServices] = useState();
    const [password, setPassword] = useState('');
    useEffect(() => {
        wgfAxiosPost('chauffeur-service/getall', null, null, setChauffeurServices, []);
    }, []);

    const save = () => {
        if (chauffeurServices) {
            let _params = {
                password: password,
                activeServices: wgfObjectClone(chauffeurServices.filter(x => x.isChecked == true).map(item => item.id))
            }
            wgfAxiosPost('chauffeur-service/set', _params, reset, null, null);
        }
    }
    const reset = () => {
        setPassword('');
    }
    const changeChecked = (id, isChecked) => {
        if (chauffeurServices) {
            setChauffeurServices(chauffeurServices.map(item => {
                if (item.id == id) {
                    item.isChecked = isChecked;
                }
                return item;
            }));
        }
    }
    const getServiceItems = () => {
        let _items = [];
        chauffeurServices && chauffeurServices.map((item, index) => {
            _items.push({
                icon: <WgImage src={item.logo} style={{width:'40px', marginRight:'7px'}} />,
                callback: null,
                roleIsChauffeur: null,
                text: item.name,
                children: (
                    (item.name.includes('Taksi')) ? (
                        <WgButton onClick={() => wgAppObjects.functions.goToLink(wgRouteAddresses.home.contact)} title="İletişime geçiniz" />
                    ) : (
                        <WgSwitch itemsthisid={item.id} itemsstate={chauffeurServices} itemssetstate={setChauffeurServices} />
                    )
                )
            })
        })
        return _items;
    }
    return (
        <>
            <WgText align="center" variant="h5" text="Vermiş olduğunuz hizmet türleriniz seçiniz." />
            <WgList items={getServiceItems()} />
            <WgJustifyContent mt="15px" mb="25px">
                <WgButtonEditWithPassword buttonEvent={save} propstate={[password, setPassword]} />
            </WgJustifyContent>
            <WgText variant="info" text="Taksi hizmeti için bizimle iletişime geçiniz.Sizden gerekli evraklar istenilecek." />
        </>
    )
}
export default WgServiceSelector;