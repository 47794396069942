import WgBox from "../../components_only_this_platform/elements/WgBox";
/*
flex-end = sağ tarafa yaslar
start = sola yaslar
space-between = iki tarafa yaslar

 alignItems:flex-start/flex-end/
*/
const WgJustifyContent = (props) => {
    return (
        <WgBox style={{ display: 'flex', width: props.width??'100%', alignItems:props.alignItems??'center', justifyContent: props.justifyContent??'flex-end', marginTop: props.mt ?? 0, marginBottom: props.mb ?? 0 }}>{props.children}</WgBox>
    )
}
export default WgJustifyContent;
