import React, { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import { getOrientation } from 'get-orientation/browser'
import { getCroppedImg, getRotatedImage } from './canvasUtils'
import { useRef } from 'react'
import WgJustifyContent from '../../components/ui/WgJustifyContent'
import WgBox from '../elements/WgBox'
import WgIconCamera from '../icons/WgIconCamera'
import WgIconCropSimple from '../icons/WgIconCropSimple'
import WgIconRotateRight from '../icons/WgIconRotateRight'
import WgIconRotateLeft from '../icons/WgIconRotateLeft'
import WgIconZoomPlus from '../icons/WgIconZoomPlus'
import WgIconZoomMinus from '../icons/WgIconZoomMinus'
import { WgfSnackbarFunc } from '../../App'
import { wgsButtonIcon } from '../../components/ui/WgStyles'
import WgButton from '../elements/WgButton'
import WgModal from '../elements/WgModal'
const ORIENTATION_TO_ANGLE = {
  '3': 180,
  '6': 90,
  '8': -90,
}
const _buttonStyles = {
  fontSize: '22px',
  minWidth: '30px',
  minHeight: '30px',
  maxWidth: '30px',
  maxHeight: '30px'
}
const _buttonCropStyles = {
  fontSize: '24px',
  minWidth: '36px',
  minHeight: '36px',
  maxWidth: '36px',
  maxHeight: '36px'
}
const WgImageEasyCrop = (props) => {
  const [open, setOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const fileInputRef = useRef(null);
  const cropperRef = useRef(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  //kırpılanı gostermek icin
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);
      blobUrlToBase64(croppedImage).then((result) => {
        if (props.setImageSate) {
          props.setImageSate(result);
        }
        handleClose();
      }).catch((error) => {
        WgfSnackbarFunc("Resmi kırpılamadı. Tekrar denenyiniz.", "danger");
      });

    } catch (e) {
      console.error(e)
    }
  }, [imageSrc, croppedAreaPixels, rotation])





  /**
   * Kırpılan resim blob: url olarak dondurulmekteydi, bu fonksiyon ile base64'e çevirilmekte
   * @param {*} blobUrl 
   * @returns 
   */
  const blobUrlToBase64 = (blobUrl) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        }
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = function () {
        reject('Failed to convert Blob URL to base64');
      }
      xhr.open('GET', blobUrl);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      let imageDataUrl = await readFile(file)
      try {
        // apply rotation if needed
        const orientation = await getOrientation(file)
        const rotation = ORIENTATION_TO_ANGLE[orientation]
        if (rotation) {
          imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
        }
      } catch (e) {
        WgfSnackbarFunc('Resim döndürülemedi.', 'danger');
      }
      setImageSrc(imageDataUrl);
      handleOpen();
    }
  }

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }
  const zoomIncrement = (val = 0.1) => {
    val += zoom;
    if (val > 3) {
      val = 3
    }
    else if (val < 1) {
      val = 1;
    }
    setZoom(val);
  }
  const rotationIncrement = (val = 1) => {
    val += rotation;
    if (val > 360) {
      val = -360
    }
    else if (val < -360) {
      val = 0;
    }
    setRotation(val);
  }
  const handleButtonClick = () => {
    fileInputRef.current.click();

  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setImageSrc(null);
    if (props.onHide) {
      props.onHide();
    }

  };



  return (
    <>
      <WgButton onClick={handleButtonClick} style={{ ...wgsButtonIcon }} icon={<WgIconCamera />} />
      <WgBox style={{ display: 'none' }}>
        <input type="file" onChange={onFileChange}
          ref={fileInputRef}
          accept="image/*"
        />
      </WgBox>
      <WgModal
        open={open}
        onClose={handleClose}
      >
        <WgBox style={{ position: 'relative', width: '90wv', height: '90vh' }}>
          <WgBox style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            maxWidth: '720px',
            backgroundColor: '#fff',
            border: 'none',
            padding: '0px',
            margin: '0px'
          }}>
            <WgBox style={{ width: '100%', height: '400px', backgroundColor: '#000' }}>
              <Cropper
                image={imageSrc}
                crop={crop}
                ref={cropperRef}
                rotation={rotation}
                zoom={zoom}
                aspect={(props.aspect ? props.aspect : 4 / 3)}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </WgBox>
            <WgJustifyContent justifyContent="center">
              <WgButton style={{ ...wgsButtonIcon, ..._buttonStyles}} onClick={() => zoomIncrement(-0.1)} icon={<WgIconZoomMinus />} />
              <WgButton style={{ ...wgsButtonIcon, ..._buttonStyles}} onClick={() => zoomIncrement(0.1)} icon={<WgIconZoomPlus />} />
              <WgButton style={{ ...wgsButtonIcon, ..._buttonCropStyles}} onClick={showCroppedImage} icon={<WgIconCropSimple />} />
              <WgButton style={{ ...wgsButtonIcon, ..._buttonStyles}} onClick={() => rotationIncrement(-1)} icon={<WgIconRotateLeft />} />
              <WgButton style={{ ...wgsButtonIcon, ..._buttonStyles}} onClick={() => rotationIncrement(1)} icon={<WgIconRotateRight />} />
            </WgJustifyContent>
          </WgBox>
        </WgBox>
      </WgModal>
    </>
  )
}
export default WgImageEasyCrop;
