import { Button } from "@mui/material";
import { wgsButton } from "../../components/ui/WgStyles";

const WgButtonToggle = (props) => {
    const getStyle = () => {
        if (props.selected == true) {
            if (props.istabmenu == true) {
                return wgsButton.active;
            }
            return wgsButton.active
        }
        else {
            if (props.istabmenu == true) {
                return wgsButton.normal;
            }
            return wgsButton.normalDarkGradient
        }
    }
    return (
        <Button {...props} style={getStyle()}  >{props.children} </Button>
    )
}
export default WgButtonToggle;
