import { Modal, Stack } from "@mui/material";
import WgJustifyContent from "../../components/ui/WgJustifyContent";
import WgBox from "./WgBox";
import WgIconXMark from "../icons/WgIconXMark";

import { wgfGetStyleValue } from "../../components/functions/WgFunctions";
import WgButton from "./WgButton";
import WgText from "./WgText";
/**
 * NOT: Eğer componnet içerisinde birden fazla bu component çağırılıyorsa maxWidth parametresine deiğerlerininikini yazıyordu.
 * Örneğin İlanlar harita sayfasında filtreleme modalını başka bir modalın genişliğini yazıyordu bende hepsinin 640px yaptım.
 * 
 */

const WgModal = (props) => {
  let _headerHide = props.hiddenHeader ?? false; //modal header alanını gizler
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: (props.maxwidth ? props.maxwidth : '720px'),
    backgroundColor: '#fff',
    border: 'none',
    borderRadius: '15px',
    padding: wgfGetStyleValue(props.style, 'padding', '33px 24px'),
  };
  return (
    <Modal
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      {...props}
    >
      <WgBox style={style}>
        <WgJustifyContent>
          <WgButton onClick={props.onClose} style={{ marginTop: '-20px', marginRight: '-15px' }} icon={<WgIconXMark />} />
        </WgJustifyContent>
        <Stack style={{ maxHeight: '77vh', overflowY: 'auto' }}>
          {
            _headerHide == false && (<WgText variant="h2" text={props.heading} />)
          }
          {
            props.children
          }
        </Stack>

      </WgBox>
    </Modal>
  )
}
export default WgModal;