import { useSelector } from "react-redux";
import WgSelect from "../../components_only_this_platform/elements/WgSelect";
import { useState } from "react";
import { useContext } from "react";
import { wgfAxiosPost } from "../functions/WgFunctions";
import { WgAppContext } from "../../App";


import { wgsButton } from "./WgStyles";
import WgButton from "../../components_only_this_platform/elements/WgButton";
import WgText from "../../components_only_this_platform/elements/WgText";
/*
Rol kontrolü yap
1-Şoför ise Şoför ve Müşteri seçeneği sun
2-Müşter ise Müşteri seçeneği sun
*/
const WgAccountStatusChange = () => {
  const [accountStatus, setAccountStatus] = useState(2);
  
  const { wgAppObjects,setWgGenDialog } = useContext(WgAppContext);

  const userInfo = useSelector(state => state.rdxStateUserInfo);
  
  const statusItems = [
    { text: 'Şoför hesabını', value: 1 },
    { text: 'Tüm hesapları', value: 2 }
  ];
  const changeState = () => {
    setWgGenDialog({
      isShow: true,
      title: "Hesabınız Dondurulacak.",
      message: (accountStatus == 1 ? 'Şoför hesabınız dondurulacak. Devam edilsin mi?' : 'Hesabınız dondurulacak. Devam edilsin mi?'),
      subMessage: (accountStatus == 1 ? 'İşlem sonucunda oturumunuz kapatılacak. Müşteri hesabınızı açmanız için tekrar giriş yapmanız gerekecektir.' : 'İşlem sonucunda oturumunuz kapatılacak.1 Yıl içerisinde tekrar giriş yaparak hesabınızı aktif edebilirisiniz.'),
      yesEvent: () => {
        /*Hesap dondurma işleminden sonra. Oturum kapatılacak, tekrar giriş yapması istenilecektir.*/
        wgfAxiosPost('/account/account-disable', { value: accountStatus, isTrue: true }, null, null, null, (data) => {
          setTimeout(() => {
            wgAppObjects.functions.logOut();
          }, 2000)
        })
      }
    })
  }
  return (
    <>
    {
          (userInfo.roleIsChauffeur == true) && (
            <>
              <WgText variant="h6" text="Dondurulacak hesabı seçiniz."/>
              <WgSelect value={accountStatus} propstate={[accountStatus, setAccountStatus]} style={{ width: 'fit-content' }} items={statusItems}/>
            </>
          )
        }
      <WgButton onClick={changeState} style={{...wgsButton.normal, width: '200px', marginBottom: '10px' }} title="Hesabı Dondur"/>
      <WgText  variant="info" size="sm" text="Dondurulan hesabı 1 yıl içerisinde tekrar aktif edebilirsiniz. 1 yıllık süre dolunca otomatik silinecektir."/>
    </>
  )
}
export default WgAccountStatusChange;