import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
const WgList = (props) => {
    const itemClick=(callback)=>{
        if(callback){
            callback();
        }
    }
    return (
        <List
            sx={{ width: '100%' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            {
                props.items&&props.items.map((item, index) => {
                    return (
                        <ListItemButton key={index} onClick={() => itemClick(item.callback)}>
                            <ListItemIcon style={{ minWidth: '33px' }}>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.text} />
                            {item.children}
                        </ListItemButton>
                    )
                })
            }
        </List>
    )
}
export default WgList;