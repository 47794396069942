

import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import { SnackbarProvider, useSnackbar } from 'notistack';

import { HubConnectionBuilder, LogLevel, HubConnectionState, HttpTransportType } from "@microsoft/signalr";

import L from 'leaflet';
import 'leaflet/dist/leaflet.css'
import "leaflet-routing-machine";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import { MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents, ZoomControl } from "react-leaflet";
import 'leaflet.locatecontrol';
import 'leaflet.locatecontrol/dist/L.Control.Locate.css';

import { default as HomeIndexView } from './views/home/IndexView';
import { default as HomeRegisterView } from './views/home/RegisterView';
import { default as HomeContactView } from './views/home/ContactView';
import { default as HomeForgotPasswordView } from './views/home/ForgotPasswordView';
import { default as HomeAboutView } from './views/home/AboutView';
import { default as AccountMyDataView } from './views/account/MyDataView'
import { default as AccountWillGoMapView } from './views/account/WillGoMapView'
import { default as AccountCreateChauffeurView } from './views/account/CreateChauffeurView'
import { default as AccountMessageView } from './views/account/MessageView'
import { default as AccountNotificationsView } from './views/account/NotificationsView'

import { createContext, useEffect, useState } from 'react';
import { isHasLocationValues, wgfAxiosGet, wgfAxiosPost, wgfGetLoginKey, wgfObjectClone, wgfSetError } from './components/functions/WgFunctions';
import WgJustifyContent from './components/ui/WgJustifyContent';
import WgText from './components_only_this_platform/elements/WgText';
import { wgApiDomain, wgRouteAddresses } from './components/WgStaticValues';
import WgBox from './components_only_this_platform/elements/WgBox';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUserInfo } from './stores/rdxUserInfo';
import WgIconCommentDots from './components_only_this_platform/icons/WgIconCommentDots';
import WgIconBell from './components_only_this_platform/icons/WgIconBell';
import WgIconBars from './components_only_this_platform/icons/WgIconBars';
import WgUserNameImageCard from './components/ui/WgUserNameImageCard';

import { Icon } from 'leaflet';

import WgDialog, { dialogInitial } from './components/elements/WgDialog';
import WgIconPen from './components_only_this_platform/icons/WgIconPen';
import WgIconRegister from './components_only_this_platform/icons/WgIconRegister';
import WgIconLogin from './components_only_this_platform/icons/WgIconLogin';
import WgIconKey from './components_only_this_platform/icons/WgIconKey';
import WgIconInfo from './components_only_this_platform/icons/WgIconInfo';
import WgIconAt from './components_only_this_platform/icons/WgIconAt';
import WgImageShowModal from './components/ui/WgImageShowModal';


import { wgsButtonIcon } from './components/ui/WgStyles';
import WgWillGoDetailsForCustomer from './components/ui/willGoDetails/WgWillGoDetailsForCustomer';
import WgWillGoDetailsForChauffeur from './components/ui/willGoDetails/WgWillGoDetailsForChauffeur';
import WgPageBody from './components/ui/WgPageBody';

import WgIconArrowLeft from './components_only_this_platform/icons/WgIconArrowLeft';
import WgLogoSvgWithText from './components_only_this_platform/ui/WgLogoSvgWithText';
import WgLogoSvg from './components_only_this_platform/ui/WgLogoSvg';
import WgList from './components_only_this_platform/elements/WgList';
import WgDrawer from './components_only_this_platform/elements/WgDrawer';
import WgButton from './components_only_this_platform/elements/WgButton';
import { getWindowHeight, getWindowWidth, removeLocalStorage, wgfGetCurrentPosition } from './components_only_this_platform/functions/WgFunctions';

import { wgfMapCreateMarkerIcon, wgfMapDatas, wgfMapGoToPosition } from './components_only_this_platform/functions/WgMapScripts';
import WgRoadSvg from './components_only_this_platform/ui/WgRoadSvg';

export var WgfSnackbarFunc = null; //snackbar hooks olduğu için her yerde çalıştırılamıyor bu yüzden değişkene fonksiyonu atadım ve her yerden bu değiken aracılığı ile erişilebilinir.

export const WgAppContext = createContext();
const conatinerMaxHeightPercent = 75;
const wgAppObjects = {
  currentPage: wgRouteAddresses.home.indexRoot,/*geçerli sayfanın adresi*/
  goBackArray: [],
  currentStyle: null,
  currentStyleWidth: null,
  windowWidth: getWindowWidth(),
  windowHeight: getWindowHeight(),
  currentStyleHeight: (getWindowHeight() * conatinerMaxHeightPercent / 100),
  currentStyleHeightPercent: conatinerMaxHeightPercent,
  mainMap: {
    map: null,
    center: null,
  },
  route: {
    routingControl: null,
    clearRoute: null,
    drawRoute: null
  },
  signalR: {
    con: null,
    conId: null,
    isConnected: null,
    create: null,
    connect: null,
    disconnect: null,
    reset: null,
    resetCounter: 0,
    connectionStartTimeout: null,
    connectionControlInterval: null
  },
  functions: {
    handleDrawerListItem: null,
    getMessageItems: null,
    getMyWillGos: null,
    getMyNotifications: null,
    goToLink: null,
    logOut: null,
    getWillGoDetails: null,
    updateAccountLayoutData: null,
    getMyOffers: null,
    resetSelectedWillGo: null,
    goBack: null,
  }
}

const _stylesStatics = {
  map: { padding: 0, margin: 0, position: 'fixed', top: 0, zIndex: 1 },
  container: { backgroundColor: '#fff', borderRadius: '15px 15px 15px 0px', overflow: 'hidden', left: 0, bottom: 0, position: 'fixed', zIndex: 5, paddingBottom: '40px', },
  containerBody: { overflow: 'auto', width: '100%', paddingBottom: '50px' }
}

function App() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [appData, setAppData] = useState(null);
  const [intro, setIntro] = useState(true);
  const [appTimeout, setAppTimeout] = useState(false); //eğer 33 saniye içinde veriler yüklenmemiş ise açıklama mesajı çıksın
  /*
    NOT
    >Alt componentler (layout dahil) render edildiğinde static olması gereken veriler tekrar işlenmek zorunda kalmakta.
    Bunun yerine App içinde state oluştur verileri buraya yaz, component render edildiğinde state'i kontrol ettir null ise o zaman işlemi yaptır.
    wgGen... ile başlayanlar App içerisinde generic bilgi tuutan State nesneleridir.
   */
  const [wgGenSignalRReceiveMessageId, setWgGenSignalRReceiveMessageId] = useState(null);
  const [wgGenSignalRReceiveMessage, setWgGenSignalRReceiveMessage] = useState(null);
  const [wgGenSignalRRemoveMessageItemId, setWgGenSignalRRemoveMessageItemId] = useState(null);
  const [wgGenSignalRUserLeavedConId, setWgGenSignalRUserLeavedConId] = useState(null);
  const [wgGenSignalRUserJoinConId, setWgGenSignalRUserJoinConId] = useState(null);
  const [wgGenMarkerWillGos, setWgGenMarkerWillGos] = useState(null);
  const [wgGenRoadStatusTypes, setWgGenRoadStatusTypes] = useState(null);
  const [wgGenMarkerRoadStatus, setWgGenMarkerRoadStatus] = useState(null);
  const [wgGenMarkerMyRoadStatus, setWgGenMarkerMyRoadStatus] = useState(null);
  const [wgGenMarkerRoadStatusSelectedLocation, setWgGenMarkerRoadStatusSelectedLocation] = useState(null);
  const [wgGenMarkerRoadAddress, setWgGenMarkerAddress] = useState(null);
  const [wgGenMarkerWeather, setWgGenMarkerWeather] = useState(null);
  const [wgGenMarkerChauffeurs, setWgGenMarkerChauffeurs] = useState(null);
  const [wgGenDialog, setWgGenDialog] = useState(dialogInitial);
  const [wgGenWillGos, setWgGenWillGos] = useState(null); //kendi ilanları, burada olsun getMyWillGos() metoduna baska compoentlerden de erisim oldugu icin
  const [wgClientLocation, setWgClientLocation] = useState(null);
  const [wgGenMapSelectedLocation, setWgGenMapSelectedLocation] = useState(null);
  const [wgIsRouteStartMyLocation, setWgIsRouteStartMyLocation] = useState(false);
  const [wgGenAccountLayoutData, setWgGenAccountLayoutData] = useState(null);
  const [wgGenNotifications, setWgGenNotifications] = useState(null);//bildirimler
  const [drawerShow, setDrawerShow] = useState(false);
  const [wgGenCurrentStyle, setWgGenCurrentStyle] = useState(null);
  const [wgGenSelectedWillGo, setWgGenSelectedWillGo] = useState(null);//aynı anda birden fazla ilan detayı görüntülenemeyecek ve rotası çizilemeyecek.
  const [wgGenShowImage, setWgGenShowImage] = useState(null);
  const [showPage, setShowPage] = useState(<HomeIndexView />);
  /*
   NOT: Rota çizildiğinde ilgili controlü state ile taşıyınca, tekrar rota silme işleminde silme yapamıyordu, bu yüzden state yerine değişkene yazdırdım.
  */
  const [reduxData, setReduxData] = useState(useSelector(state => state.rdxStateUserInfo));
  const [wgGenLoginInfo, setWgGenLoginInfo] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      if (!appData) {
        setAppTimeout(true);
        wgfSetError("Uygulama başlatılmadı.");
      }
    }, 33000)
    wgfAxiosGet('app-data/get', null, setAppData);
    if (!wgClientLocation) {
      wgfGetCurrentPosition(null, (pos) => {
        if (pos) {
          clientLocation([pos.lat, pos.lng],)
        }
      })
    }
    return () => {
      //Component kapatilirken signalR baglantisini sonlandir
      wgAppObjects.signalR.disconnect();
    }
  }, []);

  /****************************************/
  useEffect(() => {
    if (appData) {
      setIntro(false);
    }
  }, [appData])
  /*Login Control*/
  useEffect(() => {
    /**
      * LoginKey doğrulaması yapar, doğrulanamz ise oturumu kapatır
    */
    if (reduxData) {
      if (reduxData.isLogin && reduxData.firstName) {
        wgAppObjects.goBackArray = [];
        //signalR.create dedikten sonra bir muddet bekledikten sonra login kontrolu yaptir, yoksa signalR hatasi vermekte
        wgfAxiosPost('account/loginkey-verified', { value: reduxData.firstName }, null, null, null, () => {
          //Bilgiler doğrulanmış ise hesaba yönlendir
          wgAppObjects.functions.updateAccountLayoutData();
          setWgGenLoginInfo(reduxData);
          wgAppObjects.goBackArray = [];
          wgAppObjects.functions.goToLink(wgRouteAddresses.account.willgoMap);//hesaba yonlendir
          signalRStartTimeout();
          if (wgAppObjects.signalR.connectionControlInterval) {
            //localhost'ta calisirken art arda iki Api istegi yaptigi icin ilk istekte oluna timer'ı sil
            clearInterval(wgAppObjects.signalR.connectionControlInterval);
          }
          wgAppObjects.signalR.connectionControlInterval = setInterval(() => {
            wgAppObjects.signalR.connect();
          }, 60000)
        }, () => {
          wgAppObjects.functions.logOut();
        }, true);
      }
      else {
        wgAppObjects.functions.logOut();
      }
    }
  }, [reduxData])

  /************************************************************************************/
  /************************************************************************************/
  /************************************************************************************/
  /*
  BorderRadius için harita width/height değerlerini -10 daha az yaz.
  */
  useEffect(() => {
    if (wgGenCurrentStyle == null) {
      windowSize();
      window.addEventListener('resize', windowSize); //Only ReactJS
    }
  }, [wgGenCurrentStyle])

  const windowSize = (changeHeight = false) => {
    let _wWidth = getWindowWidth(); //Mobil için değiştir
    let _wHeight = getWindowHeight(); //Mobil için değiştir
    wgAppObjects.windowWidth = _wWidth;
    /*
      >ilk genislik null oldugunda
      >yukseklik degistiginde
      > genislik sınırlarının dısına ciktiginda
    */
    if (wgAppObjects.currentStyleWidth == null || wgAppObjects.windowHeight != _wHeight || changeHeight == true || (wgAppObjects.windowWidth > 700 && wgAppObjects.currentStyleWidth < 700) || (wgAppObjects.windowWidth < 700 && wgAppObjects.currentStyleWidth > 700)) {
      wgAppObjects.windowHeight = _wHeight;
      wgAppObjects.currentStyleWidth = wgAppObjects.windowWidth;
      wgAppObjects.currentStyleHeight = wgAppObjects.windowHeight;
      let _style = { map: null, container: null, containerBody: null };
      //container'in yuksekligini ayarlarken +75 navbar yuksekligi ver.
      if (wgAppObjects.windowWidth < 700) {
        //mobile
        if (changeHeight == true) {
          if (wgAppObjects.currentStyleHeightPercent == conatinerMaxHeightPercent) {
            wgAppObjects.currentStyleHeightPercent = 50;
          } else {
            wgAppObjects.currentStyleHeightPercent = conatinerMaxHeightPercent;
          }
        }
        wgAppObjects.currentStyleHeight = wgAppObjects.windowHeight * wgAppObjects.currentStyleHeightPercent / 100;
        _style.map = { ..._stylesStatics.map, width: '100vw', height: `${wgAppObjects.windowHeight - wgAppObjects.currentStyleHeight - 20}px`, left: '0px' };
        _style.container = { ..._stylesStatics.container, width: '100%', boxShadow: 'rgb(0, 0, 0) 0px -20px 20px -25px', borderRadius: '15px 15px 0px 0px', maxWidth: '700px', right: 0, margin: 'auto', height: `${wgAppObjects.currentStyleHeight}px` };
        wgAppObjects.currentStyleHeight -= 20; //body için navbar yüksekligini cikar
      }
      else {
        //pc
        _style.map = { ..._stylesStatics.map, width: 'calc(100vw - 460px)', height: `${wgAppObjects.currentStyleHeight}px`, left: '460px' };
        _style.container = { ..._stylesStatics.container, paddingBottom: '0px', width: '470px', boxShadow: 'rgb(0, 0, 0) 20px 19px 20px -25px', maxWidth: '470px', height: `${wgAppObjects.currentStyleHeight}px` };
        wgAppObjects.currentStyleHeight -= 60; //body için navbar yüksekligini cikar
      }
      _style.containerBody = { ..._stylesStatics.containerBody, height: `${wgAppObjects.currentStyleHeight}px` };
      setWgGenCurrentStyle(_style);
    }
  }



  WgfSnackbarFunc = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant,
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top',
      }
    });
  }
  wgAppObjects.functions.goToLink = (path) => {

    wgAppObjects.functions.resetSelectedWillGo();
    if (wgAppObjects.currentPage != path) {
      wgAppObjects.goBackArray.push(path);
    }
    if (path != wgRouteAddresses.account.willgoMap) {
      if (wgAppObjects.windowWidth < 700 && wgAppObjects.currentStyleHeightPercent == 50) {
        windowSize(true); //eger harita haricinde baska sayfa acilirsa ve content yuksekligi min ise buyut
      }
    }
    clearData();
    openView(path);
  }
  const openView = (path) => {
    wgAppObjects.currentPage = path;
    switch (path) {
      case wgRouteAddresses.home.indexRoot:
        setShowPage(<HomeIndexView />); break;
      case wgRouteAddresses.home.index:
        setShowPage(<HomeIndexView />); break;
      case wgRouteAddresses.home.register:
        setShowPage(<HomeRegisterView />); break;
      case wgRouteAddresses.home.contact:
        setShowPage(<HomeContactView />); break;
      case wgRouteAddresses.home.forgotPassword:
        setShowPage(<HomeForgotPasswordView />); break;
      case wgRouteAddresses.home.aboutUs:
        setShowPage(<HomeAboutView />); break;
      case wgRouteAddresses.account.myData:
        setShowPage(<AccountMyDataView />); break;
      case wgRouteAddresses.account.notifications:
        setShowPage(<AccountNotificationsView />); break;
      case wgRouteAddresses.account.willgoMap:
        setShowPage(<AccountWillGoMapView />); break;
      case wgRouteAddresses.account.registerChauffeur:
        setShowPage(<AccountCreateChauffeurView />); break;
      case wgRouteAddresses.account.message:
        setShowPage(<AccountMessageView />); break;
      case wgRouteAddresses.account.message:
        setShowPage(<AccountMessageView />); break;
      default:
        setShowPage(<HomeIndexView />); break;
    }
  }
  wgAppObjects.functions.goBack = () => {
    if (wgGenSelectedWillGo) {
      wgAppObjects.functions.resetSelectedWillGo();
    }
    else {
      let _path = null;
      if (wgAppObjects.goBackArray && wgAppObjects.goBackArray.length > 0) {
        wgAppObjects.goBackArray.splice(-1); //sondakini sil
        _path = wgAppObjects.goBackArray[wgAppObjects.goBackArray.length - 1];
      }
      if (_path == null) {
        if (wgGenLoginInfo && wgGenLoginInfo.isLogin == true) {
          _path = wgRouteAddresses.account.willgoMap;
        }
        else {
          _path = wgRouteAddresses.home.index;
        }
      }
      openView(_path)
    }
  }
  //burada olsun,notifications component'inden de erisildigi icin alt componentlerde hata verir.
  wgAppObjects.functions.getMyWillGos = () => {
    wgfAxiosGet('willgo/my-willgos', null, setWgGenWillGos, null);
  }
  useEffect(() => {
    if (wgGenNotifications && wgGenAccountLayoutData) {
      let _accountLayoutData = wgfObjectClone(wgGenAccountLayoutData);
      _accountLayoutData.notificationCount = wgGenNotifications.length;
      setWgGenAccountLayoutData(_accountLayoutData);
    }
  }, [wgGenNotifications])
  //Notifications component'inde ilan silme islemi yapildiginda ilanlarin yenilenmesi icin fonksiyonu global yaptim.
  wgAppObjects.functions.getMyNotifications = () => {
    wgfAxiosGet('/notification/get-all', null, setWgGenNotifications, null, null, null, null);
  }
  wgAppObjects.functions.updateAccountLayoutData = () => {
    wgfAxiosGet('account-layout-datas/get', null, setWgGenAccountLayoutData, null, null, null, null, false);
  }
  wgAppObjects.functions.getMyOffers = () => {
    wgfAxiosGet('willgo/get-my-offers', null, setWgGenWillGos, null);
  }
  wgAppObjects.functions.resetSelectedWillGo = () => {
    setWgGenSelectedWillGo(null);
    wgAppObjects.route.clearRoute();
  }

  /*****************************************************************************************/
  /*****************************************************************************************/
  /*****************************************************************************************/
  /*SignalR Connection */


  wgAppObjects.signalR.create = () => {
    if (wgAppObjects.signalR.con == null) {
      let _loginKey = wgfGetLoginKey();
      let _url = wgApiDomain.domain + "/wg-real-time";
      console.log('signalR _url', _url);
      wgAppObjects.signalR.con = new HubConnectionBuilder().withUrl(_url, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        withCredentials: true,
        accessTokenFactory: () => _loginKey
      }).configureLogging(LogLevel.Information)
        .withAutomaticReconnect([1000, 3000, 7000, 13000, 19000])
        .build();
      wgAppObjects.signalR.con.onclose(() => {
        console.log("SignalR Bağlantınız kesildi.");
      });
      wgAppObjects.signalR.con.on("ReceiveMessage", (messageId, message) => {
        setWgGenSignalRReceiveMessageId(messageId);
        setWgGenSignalRReceiveMessage(message);
        //mesajlaşma sayfasında değilse toast ile mesajı göster
        if (wgAppObjects.currentPage != wgRouteAddresses.account.message) {
          WgfSnackbarFunc("1 yeni Mesaj: " + message);
          wgAppObjects.functions.updateAccountLayoutData();
        }
      });
      wgAppObjects.signalR.con.on("NewNotification", (miniMessage) => {
        if (miniMessage) {
          WgfSnackbarFunc(miniMessage);
        }
        //Bildirim sayfasi aciksa listeyi guncelle, degilse count'u guncelle
        if (wgAppObjects.currentPage == wgRouteAddresses.account.notifications) {
          wgAppObjects.functions.getMyNotifications();
        }
        else {
          wgAppObjects.functions.updateAccountLayoutData();
        }
      });
      wgAppObjects.signalR.con.on("RemoveMessage", (messageItemId) => {
        setWgGenSignalRRemoveMessageItemId(messageItemId);
      });
      wgAppObjects.signalR.con.on("UserLeaved", (connectionId) => {
        if (connectionId) {
          setWgGenSignalRUserLeavedConId(connectionId);
        }
      });
      wgAppObjects.signalR.con.on("UserJoin", (connectionId) => {
        if (connectionId) {
          setWgGenSignalRUserJoinConId(connectionId);
        }
      });
    }
    else {
      WgfSnackbarFunc('Bağlantı mevcut', 'info');
      WgfSnackbarFunc(wgAppObjects.signalR.conId, 'info');
    }
  }
  /*SignalR Functions*/
  wgAppObjects.signalR.connect = () => {
    if (wgAppObjects.signalR.con) {
      if ((wgAppObjects.signalR.con.state !== HubConnectionState.Connected)) {
        wgAppObjects.signalR.con.start().then(() => {
          if (wgAppObjects.signalR.con.connection && wgAppObjects.signalR.con.connection.connectionId) {
            wgAppObjects.signalR.conId = wgAppObjects.signalR.con.connection.connectionId;
          }
        })
          .catch((error) => {
            WgfSnackbarFunc(error.message, 'error');
            console.error(error);
          });
      }
      else {
        console.log('SignalR bağlantı mevcut.');
      }
    }
  }
  wgAppObjects.signalR.disconnect = () => {
    if (wgAppObjects.signalR.con && (wgAppObjects.signalR.con.state === HubConnectionState.Connected)) {
      wgAppObjects.signalR.con.stop();
    }
    wgAppObjects.signalR.con = null; //bağlantilari null yap
    wgAppObjects.signalR.conId = null;
    wgAppObjects.signalR.resetCounter = 0;
  }
  wgAppObjects.signalR.reset = () => {
    /*Bir hatadan dolayi döngü oluşmaması için sınırla*/
    if (wgAppObjects.signalR.resetCounter < 7) {
      wgAppObjects.signalR.resetCounter++;
      wgAppObjects.signalR.disconnect();
      signalRStartTimeout();//biraz bekledikten sonra yeni baglanti kursun
    }
  }
  const signalRStartTimeout = () => {
    if (wgAppObjects.signalR.connectionStartTimeout) {
      //localhost'ta calisirken art arda iki Api istegi yaptigi icin ilk istekte oluna timer'ı sil
      clearTimeout(wgAppObjects.signalR.connectionStartTimeout);
    }
    wgAppObjects.signalR.connectionStartTimeout = setTimeout(() => {
      wgAppObjects.signalR.connect();
    }, 3000)
  }
  useEffect(() => {
    /*Her Render isleminde baglanti kontrolu yaptir.*/
    if (wgGenLoginInfo && wgAppObjects.signalR.con == null) {
      WgfSnackbarFunc('login Infu State', 'info');
      wgAppObjects.signalR.create();
    }
  })
  /*****************************************************************************************/
  /*****************************************************************************************/
  /*****************************************************************************************/
  //NOT: getWillGoDetails metodu ile wgGenSelectedWillGo state'i WillGoMapView dışında da kullanıldığı için App.jsx 'e yazdım.
  wgAppObjects.functions.getWillGoDetails = (willgo) => {
    wgAppObjects.route.clearRoute();
    //ikinici tiklamada kapatacak
    if (wgGenSelectedWillGo && wgGenSelectedWillGo.id == willgo.id) {
      wgAppObjects.functions.resetSelectedWillGo();
    }
    else {
      if (willgo.isMyWillGo == true) {
        wgfAxiosPost('willgo/get-mywillgo-details', { id: willgo.id }, null, setWgGenSelectedWillGo, null);
      } else {
        wgfAxiosPost('willgo/get-details', { id: willgo.id }, null, setWgGenSelectedWillGo, null);
      }
      wgAppObjects.route.drawRoute([[willgo.fromLocation.lat, willgo.fromLocation.lng], [willgo.toLocation.lat, willgo.toLocation.lng]], willgo.id);
    }
  }
  /*****************************************************************************************/
  /*****************************************************************************************/
  /*****************************************************************************************/
  wgAppObjects.functions.logOut = () => {
    wgAppObjects.goBackArray = [];
    wgAppObjects.signalR.disconnect(); //signalR baglantisini kes
    wgAppObjects.mainMap.map = null;
    wgAppObjects.mainMap.center = null;
    clearData();
    wgAppObjects.currentPage = wgRouteAddresses.home.index;
    setShowPage(<HomeIndexView />);
    if (wgGenLoginInfo && wgGenLoginInfo.loginKey) {
      wgfAxiosGet('account/logout', null, null, null);
    }
    dispatch(deleteUserInfo());
    removeLocalStorage("auth-data");
    removeLocalStorage("auth-key");
    setWgGenLoginInfo(null);
    wgAppObjects.functions.goToLink(wgRouteAddresses.home.index);
  }
  const clearData = () => {
    setWgGenMarkerWillGos(null);
    setWgGenMarkerAddress(null);
    setWgGenMarkerChauffeurs(null);
    wgAppObjects.route.clearRoute();
    setWgGenWillGos(null);
  }


  const MapEventsArea = () => {
    wgAppObjects.mainMap.map = useMap();
    const mMap = useMapEvents({
      click(ev) {
        setWgGenMapSelectedLocation(ev.latlng)
      },
      moveend() {
        //harita merkez koordinatını verir, ayrıca harita sürüklendikçe tetiklenir
        //wgAppObjects.mainMap.center = mMap.getCenter();
        wgAppObjects.mainMap.center = mMap.getCenter();
      },
      zoomend() {
        // setMapZoomValue(mMap.getZoom());
        //console.log('zoomend');
      },
      locationfound(e) {
        clientLocation(e.latlng, e);
      }
    });
  }
  const clientLocation = (location, mapE) => {
    if (location) {
      setWgClientLocation(location); //konumu alır
      if (wgAppObjects.mainMap.map) {
        wgfMapGoToPosition(wgAppObjects.mainMap.map, location);
      }
      else {
        setTimeout(() => {
          clientLocation(location);
        }, 3000)
      }
    }
    if (mapE) {
      //harita nesnesi yüklenirse alanı cizer
      //konum tahmini alanını daire icine alır
      const radius = mapE.accuracy;
      const circle = L.circle(mapE.latlng, radius);
      circle.addTo(wgAppObjects.mainMap.map);
    }
  }


  const markerClieckEvent = (item) => {
    if (item.callback) {
      item.callback(item); //marker'a tıklanınca ilgili fonksiyon tetilenir ve marker bilgilerini döndürür
    }
  }

  const mapReadyEvent = () => {
    /*
     NOT !!!
     Haritayı state'e MapEventsArea metodu içerisinde ekliyordu ancak, aşağıdaki hatayı verdiği için harita yüklendikten sonra burada eklensin.
     const MapEventsArea = () => {
         wgAppObjects.mainMap.map = useMap();
       }
     HATA:'Cannot update a component (`App`) while rendering a different component (`MapEventsArea`). To locate the bad setState() call inside `MapEventsArea`, follow the stack trace as described i'
    */

    /*
     Hataya neden oluyordu.Bir sure beklesin.
    */
    setTimeout(() => {
      if (wgAppObjects.mainMap.map) {
        /*
        var lc = L.control
          .locate({
            position: "topright",
            strings: {
              title: "Tahimini konumuz."
            }
          })
          .addTo(wgAppObjects.mainMap.map);
        */
      }
    }, 3000)
  }

  wgAppObjects.route.clearRoute = () => {
    if (wgAppObjects.mainMap.map && wgAppObjects.route.routingControl) {
      if (wgAppObjects.route.routingControl) {
        wgAppObjects.route.routingControl.getPlan().setWaypoints([]);
        wgAppObjects.route.routingControl.route();
        wgAppObjects.route.routingControl = null;
      }
    }
  }

  /**
   * WillGo ID ile routeData verisini server'den alır.
   * Aşağıdaki gibi Leaflet Routing yerine polygon çizimi de yapılabilir, ancak eğili yerlede düz çizgi çizmekte
   *    leaflet'te lng/lat şeklinde
         const latLngs = wgGenRoute.routes[0].geometry.coordinates.map(item => [item[1], item[0]]);
        const latLngs = wgGenRoute.geometry.map(coord => [coord.lat, coord.lng]);
        L.polyline(latLngs, { color: 'red' }).addTo(map); >>bununla da rota çizilmekte ancak, yolun kıvrımlarına göre çizim yapamamakta
   * @param {*} willgo 
   */
  wgAppObjects.route.drawRoute = (waypoints, dataSource) => {
    let _serviceUrl = wgApiDomain.domain + '/api/willgo/get-willgo-route/api-key/' + dataSource;
    wgAppObjects.route.routingControl = L.Routing.control({
      waypoints: waypoints,
      addWaypoints: false,
      draggableWaypoints: false,
      routeWhileDragging: false,
      reverseWaypoints: false,
      fitSelectedRoutes: 'small',
      showAlternatives: false,
      alternatives: false,
      show: false /*rota kartı*/,
      useHints: false,
      serviceUrl: _serviceUrl,
      /*alternatif route'lar */
      altLineOptions: wgfMapDatas.altLineOptions,
      createMarker: (i, wp, nWps) => {
        let _icon = null;
        if (i === 0) {
          _icon = wgfMapCreateMarkerIcon(wgfMapDatas.iconUrls.start);
        } else if (i === nWps - 1) {
          _icon = wgfMapCreateMarkerIcon(wgfMapDatas.iconUrls.end);
        } else {
          //ara noktalar
          _icon = null; //wgfMapCreateMarkerIcon(wgfMapDatas.iconUrls.via);
        }
        if (_icon) {
          let _icn = L.marker([wp.latLng.lat, wp.latLng.lng], { icon: _icon });
          _icn.on('click', () => { });
          return _icn;
        }
      },
      lineOptions: {
        styles: [{
          color: "#333",
          opacity: 0.77,
          weight: 4
        }]
      }
    }).on('routingerror', () => {
      WgfSnackbarFunc('Üzgünüz, rotanız çizilemedi.', 'error');
    }).on('routingstart', () => {
      //console.log('rota çiziliyor...');
    }).on('routesfound', (e) => {
      if (e.routes.length == 0) {
        WgfSnackbarFunc('Üzgünüz, rotanız tespit edilemedi.', "error");
      }
    }).addTo(wgAppObjects.mainMap.map); //NOT: Burada _thisMap nesnesini kullan. wgGenMap state'ini kullanınca hata vermekte.
    document.querySelectorAll('.leaflet-routing-container').forEach(item => item.remove()); //RoutingMachine'nin kendi güzergah listesi açan butonu gizler.
  }

  const activePageColor = (name, isMainButton) => {
    let _resp = { ...wgsButtonIcon, backgroundColor: '#fff', marginTop: '-7px' };
    if (wgAppObjects.currentPage == wgRouteAddresses.account[name] || wgAppObjects.currentPage == wgRouteAddresses.home[name]) {
      _resp = { ...wgsButtonIcon, backgroundColor: '#ffd800', marginTop: '-7px' }
    }
    _resp.minWidth = '35px';
    _resp.minHeight = '35px';
    if (isMainButton == true) {
      _resp.marginTop = '-14px';
      _resp.minWidth = '44px';
      _resp.minHeight = '44px';
    }
    return _resp;
  }

  /**
   * Önce fonksiyonu çalıştır, sonra sayfa açılacaksa sayfayı aç
   * @param {*} callback 
   * @param {*} toPage 
   */
  wgAppObjects.functions.handleDrawerListItem = (callback, toPage) => {
    setDrawerShow(false);
    if (callback) {
      callback();
    }
    if (toPage) {
      wgAppObjects.functions.goToLink(toPage);
    }
  }

  /*
  Login işleminden bağımsız olacaksa null olsun
  Tüm her iki rolden bağımsız olacaksa null olsun.
   */

  const GetDrawerListItems = () => {
    let _resp = [];
    if (wgGenLoginInfo && wgGenLoginInfo.isLogin == true) {
      if (wgGenLoginInfo.roleIsChauffeur == true) {
      }
      else {
        _resp.push(
          {
            id: 1, icon: <WgIconPen size="1x" />, text: 'Şoför Hesabı Oluştur', callback: () => wgAppObjects.functions.handleDrawerListItem(null, wgRouteAddresses.account.registerChauffeur), roleIsChauffeur: false
          }
        );
      }
    } else {
      _resp.push(
        {
          id: 2, icon: <WgIconLogin size="1x" />, text: 'Giriş Yap', callback: () => wgAppObjects.functions.handleDrawerListItem(null, wgRouteAddresses.home.indexRoot), roleIsChauffeur: null
        }
      );
      _resp.push(
        {
          id: 3, icon: <WgIconRegister size="1x" />, text: 'Kaydol', callback: () => wgAppObjects.functions.handleDrawerListItem(null, wgRouteAddresses.home.register), roleIsChauffeur: null
        }
      );
      _resp.push(
        {
          id: 4, icon: <WgIconKey size="1x" />, text: 'Parolamı Unuttum', callback: () => wgAppObjects.functions.handleDrawerListItem(null, wgRouteAddresses.home.forgotPassword), roleIsChauffeur: null
        }
      );
    }
    _resp.push(
      {
        id: 5, icon: <WgIconInfo size="1x" />, text: 'Hakkımızda', callback: () => wgAppObjects.functions.handleDrawerListItem(null, wgRouteAddresses.home.aboutUs), roleIsChauffeur: null
      }
    );
    _resp.push(
      {
        id: 6, icon: <WgIconAt size="1x" />, text: 'İletişim', callback: () => wgAppObjects.functions.handleDrawerListItem(null, wgRouteAddresses.home.contact), roleIsChauffeur: null
      }
    );
    return _resp;
  }
  return (
    <div className="App">
      {
        (intro === true) ? (
          <WgBox style={{ width: '100vw', height: '100vh', padding: '0px', margin: '0px', backgroundColor: '#ffd800' }}>
            <WgBox style={{ position: 'fixed', zIndex: 2, paddingTop: '20px' }}>
              {
                appTimeout && (
                  <WgBox style={{ width: '80%', borderRadius: '15px', maxWidth: '420px', margin: 'auto', backgroundColor: '#fff', padding: '7px', marginTop: '15px' }}>
                    <WgText align="center" variant="h5" text="İnternet bağlantınızı kontrol ediniz." />
                    <WgText align="center" variant="h5" text="Bilgiler alınamadığı için uygulama başlatılamadı." />
                  </WgBox>
                )
              }
            </WgBox>
            <WgBox style={{ position: 'fixed', top: '27%', left: '33px', zIndex: 5 }}>
              <WgJustifyContent justifyContent="center">
                <WgLogoSvgWithText color="#fff" width={230} />
              </WgJustifyContent>
            </WgBox>
            <WgRoadSvg />
          </WgBox>
        ) : (
          <>

            <WgAppContext.Provider value={{
              appData,
              wgClientLocation,
              wgGenNotifications,
              setWgGenNotifications,
              wgAppObjects,
              wgGenSignalRReceiveMessageId,
              wgGenSignalRReceiveMessage,
              wgGenSignalRRemoveMessageItemId,
              wgIsRouteStartMyLocation,
              setWgIsRouteStartMyLocation,
              wgGenMapSelectedLocation,
              setWgGenMapSelectedLocation,
              setWgGenMarkerWillGos,
              wgGenMarkerRoadStatus,
              setWgGenMarkerRoadStatus,
              setWgGenMarkerRoadStatusSelectedLocation,
              wgGenMarkerMyRoadStatus,
              setWgGenMarkerMyRoadStatus,
              setWgGenMarkerWeather,
              setWgGenMarkerChauffeurs,
              setWgGenMarkerAddress,
              wgGenDialog,
              setWgGenDialog,
              wgGenLoginInfo,
              setWgGenLoginInfo,
              wgGenSelectedWillGo,
              setWgGenSelectedWillGo,
              wgGenWillGos,
              setWgGenWillGos,
              setWgGenShowImage,
              wgGenSignalRUserLeavedConId,
              wgGenSignalRUserJoinConId,
              wgGenRoadStatusTypes,
              setWgGenRoadStatusTypes
            }}>

              <WgBox style={{ position: 'relative', display: 'flex', height: '100vh' }}>

                {
                  (wgGenLoginInfo && wgGenLoginInfo.isLogin == true) ? (
                    <WgBox style={wgGenCurrentStyle.map}>
                      <MapContainer
                        center={wgfMapDatas.center}
                        zoom={wgfMapDatas.zoom.default}
                        maxZoom={wgfMapDatas.zoom.max}
                        minZoom={wgfMapDatas.zoom.min}
                        scrollWheelZoom={true}
                        zoomControl={false} /*NOT: <ZoomControl/> ile sağ üst köşeye eklerken, default olanı hala duruyordu.*/
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                        whenReady={mapReadyEvent}
                        zoomAnimation={true}
                      >
                        <MapEventsArea />
                        <TileLayer
                          url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYWhtZXRtdGwiLCJhIjoiY2xmNGJwb29xMGtrNDNzbzFoOG92NXM3OSJ9.QxLFerU9oq1hdiyKLstz4A"
                          attribution="&copy; <a href='https://www.mapbox.com/'>Mapbox</a>"
                          maxZoom={18}
                          id="mapbox/streets-v11"
                          tileSize={512}
                          zoomOffset={-1}
                          accessToken="pk.eyJ1IjoiYWhtZXRtdGwiLCJhIjoiY2xmNGJwb29xMGtrNDNzbzFoOG92NXM3OSJ9.QxLFerU9oq1hdiyKLstz4A"
                        />
                        <ZoomControl position="bottomright" />
                        {
                          (wgClientLocation && isHasLocationValues(wgClientLocation)) && (
                            <Marker position={wgClientLocation} icon={wgfMapCreateMarkerIcon(wgfMapDatas.iconUrls.via)}>
                            </Marker>
                          )
                        }
                        {
                          (wgGenMarkerRoadStatusSelectedLocation && isHasLocationValues(wgGenMarkerRoadStatusSelectedLocation)) && (
                            <Marker position={wgGenMarkerRoadStatusSelectedLocation} icon={wgfMapCreateMarkerIcon(wgfMapDatas.iconUrls.via)}>
                            </Marker>
                          )
                        }

                        {
                          wgGenMarkerWillGos && wgGenMarkerWillGos.map((item, index) => {
                            return (
                              <Marker key={index}
                                icon={wgfMapCreateMarkerIcon(item.iconUrl)}
                                onClick={() => markerClieckEvent(item)}
                                position={item.position}>
                                {
                                  item.popup && (
                                    <Popup>
                                      {
                                        item.popup.title && (<WgText text={item.popup.title} />)
                                      }
                                      {
                                        item.popup.body
                                      }
                                    </Popup>
                                  )
                                }
                              </Marker>
                            )
                          })
                        }
                        {
                          wgGenMarkerRoadStatus && wgGenMarkerRoadStatus.map((item, index) => {
                            return (
                              <Marker key={index}
                                icon={wgfMapCreateMarkerIcon(item.iconUrl)}
                                onClick={() => markerClieckEvent(item)}
                                position={item.position}>
                                {
                                  item.popup && (
                                    <Popup>
                                      {
                                        item.popup.title && (<WgText text={item.popup.title} />)
                                      }
                                      {
                                        item.popup.body
                                      }
                                    </Popup>
                                  )
                                }
                              </Marker>
                            )
                          })
                        }
                        {
                          wgGenMarkerMyRoadStatus && wgGenMarkerMyRoadStatus.map((item, index) => {
                            return (
                              <Marker key={index}
                                icon={wgfMapCreateMarkerIcon(item.iconUrl)}
                                onClick={() => markerClieckEvent(item)}
                                position={item.position}>
                                {
                                  item.popup && (
                                    <Popup>
                                      {
                                        item.popup.title && (<WgText text={item.popup.title} />)
                                      }
                                      {
                                        item.popup.body
                                      }
                                    </Popup>
                                  )
                                }
                              </Marker>
                            )
                          })
                        }
                        {
                          wgGenMarkerRoadAddress && wgGenMarkerRoadAddress.map((item, index) => {
                            return (
                              <Marker key={index}
                                icon={wgfMapCreateMarkerIcon(item.iconUrl)}
                                onClick={() => markerClieckEvent(item)}
                                position={item.position}>
                                {
                                  item.popup && (
                                    <Popup>
                                      {
                                        item.popup.title && (<WgText text={item.popup.title} />)
                                      }
                                      {
                                        item.popup.body
                                      }
                                    </Popup>
                                  )
                                }
                              </Marker>
                            )
                          })
                        }
                        {
                          wgGenMarkerChauffeurs && wgGenMarkerChauffeurs.map((item, index) => {
                            return (
                              <Marker key={index}
                                icon={wgfMapCreateMarkerIcon(item.iconUrl)}
                                onClick={() => markerClieckEvent(item)}
                                position={item.position}>
                                {
                                  item.popup && (
                                    <Popup>
                                      {
                                        item.popup.title && (<WgText text={item.popup.title} />)
                                      }
                                      {
                                        item.popup.body
                                      }
                                    </Popup>
                                  )
                                }
                              </Marker>
                            )
                          })
                        }
                        {
                          wgGenMarkerWeather && wgGenMarkerWeather.map((item, index) => {
                            return (
                              <Marker key={index}
                                icon={wgfMapCreateMarkerIcon(item.iconUrl)}
                                onClick={() => markerClieckEvent(item)}
                                position={item.position}>
                                {
                                  item.popup && (
                                    <Popup>
                                      {
                                        item.popup.title && (<WgText text={item.popup.title} />)
                                      }
                                      {
                                        item.popup.body
                                      }
                                    </Popup>
                                  )
                                }
                              </Marker>
                            )
                          })
                        }
                      </MapContainer>
                    </WgBox>
                  ) : (
                    <WgBox style={{ ...wgGenCurrentStyle.map, backgroundSize: 'cover', backgroundImage: 'url(https://files.gidecekvar.com/images/banners/banner-1.jpg)' }}>
                    </WgBox>
                  )
                }
                <WgBox style={{ width: wgGenCurrentStyle.container.width, position: 'fixed', bottom: 0, height: '45px', backgroundColor: '#333', zIndex: 999 }}>
                  <WgBox style={{ position: 'relative', display: 'flex', width: '100%', maxWidth: '300px', justifyContent: 'space-around', margin: 'auto' }}>
                    {
                      (wgAppObjects.goBackArray && wgAppObjects.goBackArray.length > 0) ? (
                        <WgButton onClick={wgAppObjects.functions.goBack} style={activePageColor(null)} icon={<WgIconArrowLeft />} />
                      ) : (
                        <WgButton style={{ ...activePageColor(null), opacity: '0.5' }} icon={<WgIconArrowLeft />} />
                      )
                    }

                    {
                      (wgGenLoginInfo && wgGenLoginInfo.isLogin == true) ? (
                        <>
                          {
                            (wgGenAccountLayoutData && wgGenAccountLayoutData.messageCount) ? (
                              <WgButton onClick={() => wgAppObjects.functions.goToLink(wgRouteAddresses.account.message)} count={wgGenAccountLayoutData.messageCount} style={activePageColor('message')} icon={<WgIconCommentDots size="xs" color="#333" />} />
                            ) : (
                              <WgButton onClick={() => wgAppObjects.functions.goToLink(wgRouteAddresses.account.message)} style={activePageColor('message')} icon={<WgIconCommentDots size="xs" color="#333" />} />
                            )
                          }
                          <WgButton onClick={() => wgAppObjects.functions.goToLink(wgRouteAddresses.account.willgoMap)} style={activePageColor('willgoMap', true)} icon={<WgLogoSvg width={24} />} />
                          {
                            (wgGenAccountLayoutData && wgGenAccountLayoutData.notificationCount && wgGenAccountLayoutData.notificationCount > 0) ? (
                              <WgButton count={wgGenAccountLayoutData.notificationCount} onClick={() => wgAppObjects.functions.goToLink(wgRouteAddresses.account.notifications)} style={activePageColor('notifications')} icon={<WgIconBell size="xs" color="#333" />} />
                            ) : (
                              <WgButton onClick={() => wgAppObjects.functions.goToLink(wgRouteAddresses.account.notifications)} style={activePageColor('notifications')} icon={<WgIconBell size="xs" color="#333" />} />
                            )
                          }
                        </>
                      ) : (
                        <>
                          <WgButton onClick={() => wgAppObjects.functions.goToLink(wgRouteAddresses.home.register)} style={activePageColor('register')} icon={<WgIconRegister size="xs" color="#333" />} />
                          <WgButton onClick={() => wgAppObjects.functions.goToLink(wgRouteAddresses.home.index)} style={activePageColor('index', true)} icon={<WgLogoSvg width={24} />} />
                          <WgButton onClick={() => wgAppObjects.functions.goToLink(wgRouteAddresses.home.contact)} style={activePageColor('contact')} icon={<WgIconAt size="xs" color="#333" />} />
                        </>
                      )
                    }
                    <WgButton onClick={() => setDrawerShow(true)} style={activePageColor(null)} icon={<WgIconBars size="xs" color="#333" />} />
                  </WgBox>
                </WgBox>
                <WgBox style={wgGenCurrentStyle.container}>
                  <WgBox style={{ paddingBottom: '5px', paddingTop: '5px', borderBottom: '2px solid #ccc' }} onClick={() => windowSize(true)}>
                    <WgJustifyContent justifyContent="center">
                      <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="67px" height="7px" viewBox="0 0 1500.000000 155.000000" preserveAspectRatio="xMidYMid meet">
                        <g transform="translate(0.000000,155.000000) scale(0.100000,-0.100000)" fill="#ffd800" stroke="none">
                          {
                            (wgAppObjects.currentStyleHeightPercent == 50) ? (
                              <path d="M7130 1484 c-519 -60 -1963 -223 -2195 -249 -291 -31 -1750 -196 -2128 -239 -210 -25 -411 -47 -447 -50 -62 -6 -1161 -129 -1795 -201 -277 -32 -305 -37 -361 -65 -73 -36 -126 -91 -163 -169 -22 -47 -26 -70 -26 -141 0 -99 26 -168 90 -239 43 -47 139 -97 205 -106 30 -4 280 20 715 69 369 42 994 112 1390 156 396 45 995 112 1330 150 336 38 639 72 675 75 36 4 202 22 370 41 168 19 526 59 795 89 270 30 726 82 1015 115 289 33 644 73 790 89 l265 29 540 -64 c297 -36 806 -96 1130 -134 325 -38 948 -112 1385 -165 437 -52 989 -117 1225 -145 237 -28 500 -59 585 -69 85 -11 441 -53 790 -95 349 -41 788 -94 975 -116 211 -26 362 -40 399 -37 124 11 243 99 287 216 26 70 29 197 5 254 -38 92 -120 171 -208 203 -24 8 -250 39 -503 69 -253 30 -552 65 -665 79 -566 68 -1693 202 -2070 246 -234 28 -537 64 -675 80 -545 66 -1785 213 -2230 265 -487 57 -740 88 -860 105 -103 14 -129 12 -635 -46z" />
                            ) : (
                              <path xmlns="http://www.w3.org/2000/svg" d="M14315 1505 c-148 -18 -578 -70 -955 -115 -377 -45 -755 -90 -840 -101 -85 -10 -348 -41 -585 -69 -236 -28 -788 -93 -1225 -145 -437 -53 -1060 -127 -1385 -165 -324 -38 -833 -98 -1130 -134 l-540 -64 -265 29 c-146 16 -501 56 -790 89 -289 33 -745 85 -1015 115 -269 30 -627 70 -795 89 -168 19 -334 37 -370 41 -36 3 -339 37 -675 75 -335 38 -934 105 -1330 150 -396 44 -1021 114 -1390 156 -435 49 -685 73 -715 69 -66 -9 -162 -59 -205 -106 -64 -71 -90 -140 -90 -239 0 -71 4 -94 26 -141 37 -78 90 -133 163 -169 56 -28 84 -33 361 -65 634 -72 1733 -195 1795 -201 36 -3 237 -25 447 -50 378 -44 1838 -208 2128 -239 137 -15 907 -102 2220 -250 455 -52 508 -56 585 -47 47 6 159 20 250 31 91 11 377 45 635 76 445 52 1685 199 2230 265 138 16 441 52 675 80 377 44 1504 178 2070 246 113 14 412 49 665 79 253 30 479 61 503 69 88 32 170 111 208 203 10 23 16 68 16 118 0 148 -64 255 -188 316 -91 45 -158 45 -489 4z" />
                            )
                          }
                        </g>
                      </svg>
                    </WgJustifyContent>
                  </WgBox>
                  <WgBox style={wgGenCurrentStyle.containerBody}>

                    {
                      (wgGenSelectedWillGo) && (
                        <WgBox style={{ ...wgGenCurrentStyle.containerBody, position: 'absolute', backgroundColor: '#fff', zIndex: 999, left: 0, top: '20px' }}>
                          <WgPageBody style={wgGenCurrentStyle}>
                            {
                              (wgGenSelectedWillGo.isMyWillGo == true) ? (
                                <WgWillGoDetailsForCustomer />
                              ) : (
                                <WgWillGoDetailsForChauffeur />
                              )
                            }
                          </WgPageBody>
                        </WgBox>
                      )
                    }
                    {showPage}
                  </WgBox>
                </WgBox>
              </WgBox>
              {
                (wgGenShowImage) && (
                  <WgImageShowModal imageState={wgGenShowImage} setImageState={setWgGenShowImage} />
                )
              }
              <WgDrawer
                anchor={'right'}
                openState={{ drawerShow, setDrawerShow }}
                isAppendCloseBtn={true}
              >
                <WgJustifyContent justifyContent="center" mb="20px">
                  <WgLogoSvgWithText />
                </WgJustifyContent>
                <WgList items={GetDrawerListItems()} />
                {
                  (wgGenLoginInfo && wgGenLoginInfo.isLogin == true) && (
                    <WgBox style={{ padding: '5px', backgroundColor: '#eee' }}>
                      <WgUserNameImageCard userData={wgGenLoginInfo} hideCommentsOrProfile={true} hideEmail={true} hidePhone={true} hideLocation={true} showMyData={true} showLogOut={true} />
                    </WgBox>
                  )
                }
                <WgBox style={{ marginTop: '50px', borderTop: '2px solid #ccc', paddingTop: '20px' }}>
                  <WgText variant="info" align="center" text="www.gidecekvar.com" />
                  <WgText variant="info" align="center" size="sm" text="iletisim@gidecekvar.com" />
                </WgBox>
              </WgDrawer>
              {
              }
              {
                wgGenDialog.isShow && <WgDialog />
              }
            </WgAppContext.Provider>
          </>
        )
      }
    </div>
  );
}
//export default App;  // IntegrationNotistack(snackbar ın) için bu komutu disabled ettim
export default function IntegrationNotistack() {
  return (
    <SnackbarProvider maxSnack={5}>
      <App />
    </SnackbarProvider>
  );
}


