import WgText from "../../components_only_this_platform/elements/WgText";

import WgJustifyContent from "./WgJustifyContent";
import WgLogoSvg from "../../components_only_this_platform/ui/WgLogoSvg";

const WgDataNotFound = ({message}) => {
    return (
        <>
            <WgJustifyContent justifyContent="center" mt="17px">
                <WgLogoSvg />
            </WgJustifyContent>
            <WgText variant="info" align="center" text={message??"Kayıt bulunamadı!"} />
        </>
    )
}
export default WgDataNotFound;