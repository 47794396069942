import { useDispatch } from "react-redux";
import { setUserInfo } from '../../stores/rdxUserInfo';
import { useContext, useState } from 'react';
import { useEffect } from 'react';
import { wgfAxiosPost, wgfIsNotNull } from '../functions/WgFunctions'
import WgSetReduxUserInfoes from '../dynamics/WgSetReduxUserInfoes'
import { WgAppContext, WgfSnackbarFunc } from "../../App";
import WgJustifyContent from "./WgJustifyContent";
import WgLink from '../../components_only_this_platform/elements/WgLink'
import WgImage from "../../components_only_this_platform/elements/WgImage";
import { wgRouteAddresses } from "../WgStaticValues";
import WgBox from "../../components_only_this_platform/elements/WgBox";
import WgInput from "../../components_only_this_platform/elements/WgInput";
import { wgsButton } from "./WgStyles";
import WgSpaceVertical from "../../components_only_this_platform/elements/WgSpaceVertical";
import WgButton from "../../components_only_this_platform/elements/WgButton";
import { setLocalStorage } from "../../components_only_this_platform/functions/WgFunctions";


const WgLoginForm = () => {
    const [phoneOrEmail, setPhoneOrEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const dispatch = useDispatch();
    const { wgAppObjects, setWgGenLoginInfo } = useContext(WgAppContext);
    const [loginUserData, setLoginUserData] = useState(null);

    const login = () => {
        
        if (wgfIsNotNull([phoneOrEmail, password], true)) {
            wgfAxiosPost('account/login', {
                phoneOrEmail: phoneOrEmail,
                password: password
            }, null, setLoginUserData, null, null, () => {
                WgfSnackbarFunc('Bilgileriniz yanlış, tekrar deneyiniz.', 'error');
            }, true);
        }
        else {
            WgfSnackbarFunc('Giriş yapabilmek için bilgilerinizi yazmalısınız.', 'info');
        }
    }
    useEffect(() => {
        if (loginUserData) {
            setLocalStorage("auth-data", loginUserData);
            setLocalStorage("auth-key", loginUserData.loginKey);
            dispatch(setUserInfo(loginUserData));
            wgAppObjects.functions.goToLink(wgRouteAddresses.account.willgoMap);
            setWgGenLoginInfo(loginUserData);
        }
    }, [loginUserData])

    return (
        <WgBox>
            <WgInput propstate={[phoneOrEmail, setPhoneOrEmail]} validType="phone,email" placeholder="Telefon veya Email" />
            <WgInput type="password" propstate={[password, setPassword]} placeholder="Şifreniz" />
            <WgJustifyContent mt="10px" justifyContent="center">
                <WgButton style={wgsButton.normal} onClick={() => { login() }} title="Giriş Yap"/>
            </WgJustifyContent>
            <WgJustifyContent mt="7px" mb="7px" justifyContent="center">
                <WgButton onClick={() => wgAppObjects.functions.goToLink('/register')} size="small" title="Kaydol"/>
                <WgButton onClick={() => wgAppObjects.functions.goToLink('/forgot-password')} size="small" title="Parolamı Unuttum"/>
            </WgJustifyContent>
            <WgSpaceVertical />
            <WgJustifyContent justifyContent="center" mt="7px" mb="7px">
                <WgLink href="#">
                    <WgImage style={{ width: 110 }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/640px-Google_Play_Store_badge_EN.svg.png" />
                </WgLink>
                <WgLink href="#">
                    <WgImage style={{ width: 110 }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/2560px-Download_on_the_App_Store_Badge.svg.png" />
                </WgLink>
            </WgJustifyContent>
            {
                loginUserData && <WgSetReduxUserInfoes paramUserInfo={loginUserData} />
            }
        </WgBox>
    )
}
export default WgLoginForm;