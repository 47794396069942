import Axios from 'axios'
import WgText from '../../components_only_this_platform/elements/WgText';
import WgIconArrowRightLong from '../../components_only_this_platform/icons/WgIconArrowRightLong';
import { wgApiDomain } from '../WgStaticValues';
import { WgfSnackbarFunc } from '../../App';
import WgBox from '../../components_only_this_platform/elements/WgBox';
import { getLocalStorage } from '../../components_only_this_platform/functions/WgFunctions';

const _appApiUrl = wgApiDomain.domain + '/api';
/*
>>>>>>>>>>>>>>>>>>>>>ACIKLAMA<<<<<<<<<<<<<<<<
>wgf... şeklinde fonksiyonları isimlendir.
>Tum projede ortak olarak kullanılabilecek fonksiyonları buraya yaz ve ilgili modele import et.
>Eğer bir fonksiyon en az iki defa kullanılıyorsa buraya yaz.
>Fonksiyonların üzerine amacını yaz ve parametrelerden açıklanması gereken açıklamayı yaz.
*/

/**
 * State listesinden ID değeri verilen kayıtı siler.
 * @param {*} state_ 
 * @param {*} setSatet_ 
 * @param {*} id_ 
 */
/***************************************************************************/
/*State Change */

export const wgfGuid = (isLong = false) => {
    if (isLong == true) {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    else {
        return ([1e7] + -1e3).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
}
export const wgfCreateRandomID = () => {
    return `wg-rnd-${Math.floor(Math.random() * (1000000 - 1 + 1)) + 1}`; //+ (new Date()).getMilliseconds();
}



/**
 * 
 * @param {*} value_ : yeni değer
 * @param {*} prop_  : değeri değişecek proprty adı
 * @param {*} state_ : değeri tutan state
 * @param {*} setState_ : state'e değer atayan fonksiyon
 */
export const wgfChangeState = (value_, prop_, state_, setState_) => {
    try {
        setState_({ ...state_, [prop_]: value_ });
    }
    catch (e) {
        console.error('wgfChangeState', e);
    }
}

/***************************************************************************/

export const wgfDateToText = (date) => {
    const _dt = new Date(date);
    const now = new Date();
    const diffInSeconds = Math.floor((now - _dt) / 1000);
    if (diffInSeconds < 60) {
        return `${diffInSeconds} saniye önce`;
    } else if (diffInSeconds < 3600) {
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        return `${diffInMinutes} dakika önce`;
    } else if (diffInSeconds < 86400) {
        const diffInHours = Math.floor(diffInSeconds / 3600);
        return `${diffInHours} saat önce`;
    } else {
        const diffInDays = Math.floor(diffInSeconds / 86400);
        return `${diffInDays} gün önce`;
    }
}

/**
 * JS'te nesne kopyalandığında kaynak nesnedeki değişiklik yeni kopyayada etki etmekte.
 * Bu bağlantıyı kırmak için Json.stringfy kullanılır.
 * @param {*} obj 
 */
export const wgfObjectClone = (obj) => {
    if (obj) {
        return JSON.parse(JSON.stringify(obj));
    }
    return null;
}

/**
 * Nesnenin type'ı tarih mi
 * @param {*} date 
 * @returns 
 */
export const wgfDateIsValid = (date) => {
    return date instanceof Date && !isNaN(date);
}
export const wgfDateTimeFormat = (date_, format_) => {
    if (date_) {
        if (wgfDateIsValid(date_) == false) {
            date_ = new Date(date_);
        }
        if (wgfDateIsValid(date_)) {
            let _langs = {
                tr: {
                    months: [
                        "Ocak",
                        "Şubat",
                        "Mart",
                        "Nisan",
                        "Mayıs",
                        "Haziran",
                        "Temmuz",
                        "Ağustos",
                        "Eylül",
                        "Ekim",
                        "Kasım",
                        "Aralık"
                    ],
                    months_short: [
                        "Oca",
                        "Şub",
                        "Mar",
                        "Nis",
                        "May",
                        "Haz",
                        "Tem",
                        "Ağu",
                        "Eyl",
                        "Eki",
                        "Kas",
                        "Ara"
                    ],
                    days: [
                        "Pazar",
                        "Pazartesi",
                        "Salı",
                        "Çarşamba",
                        "Perşembe",
                        "Cuma",
                        "Cumartesi"
                    ],
                    days_short: [
                        'Paz',
                        'Pzt',
                        'Sal',
                        'Çar',
                        'Per',
                        'Cum',
                        'Cts'
                    ]
                }
            }
            /*
            getMounth() ile donen tarih verisi 0-11 aralığında olur. Yani 1'den değil 0'dan başlamaktadır.
            */
            let _dtObj = {
                year: date_.getFullYear(),
                mounth: date_.getMonth() + 1,
                date: date_.getDate(),
                hour: date_.getHours(),
                minute: date_.getMinutes(),
                second: date_.getSeconds(),
                day_number: date_.getDay(),
                milisecond: date_.getMilliseconds()
            }
            let _clientLang = window.navigator.language.toString().toLocaleLowerCase().split('-')[0];
            let format_Obj = {
                convertPM_AM: is12H_ => {
                    let _newDt = date_.toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: is12H_
                    }).toString().split(':')[0];
                    return _newDt;
                },
                isPM: () => {
                    let _newDt = date_.toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: false
                    }).toString().split(':')[0];
                    return Number(_newDt) > 12;
                },
                y: () => Number(_dtObj.year.toString().substr(2, 2)),
                yy: () => _dtObj.year.toString().substr(1, 3),
                yyy: () => _dtObj.year,
                yyyy: () => _dtObj.year,
                M: () => _dtObj.mounth,
                MM: () => (_dtObj.mounth < 10 ? '0' + _dtObj.mounth : _dtObj.mounth),
                MMM: () => _langs[_clientLang].months_short[_dtObj.mounth - 1],
                MMMM: () => _langs[_clientLang].months[_dtObj.mounth - 1],
                d: () => _dtObj.date,
                dd: () => (_dtObj.date < 10 ? '0' + _dtObj.date : _dtObj.date),
                ddd: () => _langs[_clientLang].days_short[_dtObj.day_number],
                dddd: () => _langs[_clientLang].days[_dtObj.day_number],
                h: () => Number(format_Obj.convertPM_AM(true)),
                hh: () => {
                    let _newHr = Number(format_Obj.convertPM_AM(true).toString().split(':')[0]);
                    return (_newHr < 10 ? '0' + _newHr : _newHr);
                },
                H: () => Number(format_Obj.convertPM_AM(false).toString().split(':')[0]),
                HH: () => {
                    let _newHr = Number(format_Obj.convertPM_AM(false).toString().split(':')[0]);
                    return (_newHr < 10 ? '0' + _newHr : _newHr)
                },
                m: () => _dtObj.minute,
                mm: () => (_dtObj.minute < 10 ? '0' + _dtObj.minute : _dtObj.minute),
                s: () => _dtObj.second,
                ss: () => (_dtObj.second < 10 ? '0' + _dtObj.second : _dtObj.second),
                f: () => Math.round(_dtObj.milisecond / 100),
                ff: () => Math.round(_dtObj.milisecond / 10),
                fff: () => _dtObj.milisecond,
                t: () => format_Obj.isPM ? 'P' : 'A',
                tt: () => format_Obj.isPM ? 'PM' : 'AM'

            }
            //tarih formatında kullanılabilecek seperatorlerden ayir
            format_.split(/[-_./ :]+/).map(item => {
                let _funct = format_Obj[item];
                if (_funct != undefined) {
                    format_ = format_.replace(item, _funct());
                }
            })
            return format_;
        }
    }

    return null;
}

/**
 * 
 * @param {*} e : change olayanın gerçekleştiği element
 * @param {*} props 
 * @param {*} setValue : eğer hazır veri varsa onu yaz, e parametresini okumaz.
 * @param {*} isTypeNumber : dönüş değeri numara ise convert eder 
 */
export const wgfOnChangeEvent = (props, value, setValue, isTypeNumber = false) => {
    if (props) {
        let _val = value ?? setValue;
        if (isTypeNumber === true) {
            _val = Number(_val);
        }
        if (props.propstate) {
            if (props.propstate[2]) {
                let _state = props.propstate[0];
                let _setState = props.propstate[1];
                wgfChangeState(_val, props.propstate[2], _state, _setState);
            }
            else {
                //propState ile de object olmayan yapı gönderilebilecek (state / setSatete ikilisi de gönderilebilecek)
                let _setState = props.propstate[1];
                _setState(_val);
            }
        }
        else if (props.setState) {
            props.setState(_val)
        }
    }
}
export const wgfGetStyleValue = (style, propName, defaultValue) => {
    if (style && style[propName]) {
        return style[propName];
    }
    return defaultValue;
}
/**
 * [A-Za-z\s] = bu regex Türkçe karakterleri destekelemez.Sadece ingilizce karakter içermesi gerekenlerde kullan.
 * [a-zA-ZçÇğĞıİöÖşŞüÜ\\s]   = Türkçe karakterleri de destekelemesi için bunu kullan.
 */
export const wgfValidation = {
    phone: (number) => (/^5\d{9}$/).test(number),
    email: (email) => (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).test(email),
    letter: (text) => (/^[a-zA-ZçÇğĞıİöÖşŞüÜ\\s ]+$/).test(text),//sadece harf ve boşluk
    password: () => { }
}

/**
 * 
 * @param {*} e 
 * @param {*} propstate = [state,setState,statePropsName(optional)]
 * @param {*} callback = return(e). (optional)
 * 
 */
export const wgfPropsStateChange = (e, props, valueType) => {
    if (e && props && props.propstate) {
        let _val = e.target.value;
        if (_val) {
            if (valueType) {
                if (valueType == 'boolean') {
                    _val = e.target.checked;
                }
            }
            else if (props.type) {
                if (props.type == 'number') {
                    _val = Number(_val);
                }
            }
        }

        if (props.propstate.length > 2) {
            wgfChangeState(_val, props.propstate[2], props.propstate[0], props.propstate[1]);
        }
        else {
            props.propstate[1](_val);
        }
    }
}
/**
 * propstate verisini yazdırır
 * @param {*} props 
 * @returns 
 */
export const wgfGetPropStateValue = (props, defaultValue) => {
    if (props) {
        if (props.value) {
            return props.value; //eğer propstate haricinde value değeri varsa onu yazdır
        }
        else if (props.propstate) {
            if (props.propstate.length > 2) {
                return props.propstate[0][props.propstate[2]];
            }
            else {
                return props.propstate[0];
            }
        }
    }

    return defaultValue ?? null;
}

//Array.isArray(response.data) (boolean) => nesnesin Array olup olmadığını kontrol eden fonksiyon

export const wgfAxiosPost = (url, params, callback, setUseState, defaultValue, succededCallback = null, errorCallback = null, isShowToast = true) => {
    wgAxios(true, url, params, callback, setUseState, defaultValue, succededCallback, errorCallback, isShowToast);
}
export const wgfAxiosDelete = (url, id, callback, setUseState, defaultValue, succededCallback = null, errorCallback = null, isShowToast = true) => {
    wgAxios(true, url, { Id: id }, callback, setUseState, defaultValue, succededCallback, errorCallback, isShowToast);
}
export const wgfAxiosGet = (url, callback, setUseState, defaultValue, succededCallback = null, errorCallback = null, isShowToast = true) => {
    wgAxios(false, url, null, callback, setUseState, defaultValue, succededCallback, errorCallback, isShowToast);
}
export const wgfAxiosGetAndRespParams = (url, callback) => {
    let _lKey = wgfGetLoginKey();
    let _config = null;
    if (_lKey) {
        _config = {
            headers: {
                loginKey: _lKey,
                ApiKey: _lKey
            }
        }
    }
    if (wgfIsNotNull([url])) {
        if (url.toString().startsWith('/') == false) {
            url = '/' + url
        }
    }
    url = _appApiUrl + url;
    if (wgfIsNotNull([url])) {
        Axios.get(url, _config)
            .then(response => {
                if (callback) {
                    callback(response);
                }
            }).catch(e => {
                wgfAxiosError(e, callback, null, null);
            })
            .finally(() => {

            });
    }
    else {
        if (callback) {
            callback(null);
        }
    }
}
/*
Axios post işleminde bir data birde header kısmı ile verileri backend'e post ederiz. Header kısmında apiKey bilgisi falan bulunur.
*/
/**
 * Axios Request işlemleri için ortak fonksiyon
 * @param {*} isPost 
 * @param {*} url 
 * @param {*} params 
 * @param {*} callback : Not callback parametre alamayacak, eğer geriye değer döndürülecekse bunu state ile yap çünkü parametre olduğunda bu sefer parametresizlerde sorun çıkmakta.
 * @param {*} setUseState 
 * @param {*} defaultValue 
 */
const wgAxios = (isPost, url, params, callback, setUseState, defaultValue, succededCallback = null, errorCallback = null, isShowToast = true) => {
    try {
       
        let _lKey = wgfGetLoginKey();
        let _config = null;

        if (_lKey) {
            _config = {
                headers: {
                    loginKey: _lKey,
                    ApiKey: _lKey
                }
            }
        }
        if (wgfIsNotNull([url])) {
            if (url.toString().startsWith('/') == false) {
                url = '/' + url
            }
        }
        url = _appApiUrl + url;
        if (wgfIsNotNull([url])) {
            if (isPost) {
                Axios.post(url, params, _config)
                    .then(response => {
                        wgAxiosResponseAction(response, callback, setUseState, defaultValue, succededCallback, isShowToast);
                    }).catch(e => {
                        wgfAxiosError(e, callback, setUseState, defaultValue, errorCallback);
                    })
                    .finally(() => {

                    });
            }
            else {
                Axios.get(url, _config)
                    .then(response => {
                        wgAxiosResponseAction(response, callback, setUseState, defaultValue, succededCallback, isShowToast);
                    }).catch(e => {

                        wgfAxiosError(e, callback, setUseState, defaultValue, errorCallback);
                    })
                    .finally(() => {

                    });
            }
        }
    } catch (e) {
        console.log('Axios Error', e);
    }
}

export const wgfAuthenticationCode = (phone, email, nameSurname, authCodeSetState) => {
    if (wgfIsNotNull([phone, email, nameSurname])) {
        let _params = {
            nameSurname: nameSurname,
            phone: phone,
            email: email
        }
        wgfAxiosPost('account/send-authentication-code', _params, null, authCodeSetState, null, null, null, true);
    }
    else {
        WgfSnackbarFunc('İstenilen alanları doldurunuz.', 'error');
    }
}
/**
 * Axios'tan donen response'lar için ortak fonksiyon
 * @param {*} response 
 */
const wgAxiosResponseAction = (response, callback, setUseState, defaultValue, succededCallback = null, isShowToast = true) => {

    var _isSetDefault = true;
    if (response) {
        if (response.data) {
            _isSetDefault = false;
            if (response.data.message && isShowToast) {
                WgfSnackbarFunc(response.data.message, response.data.succeded == true ? 'success' : 'warning');
            }
            /*Not aşağıda wgfIsNotNull fonksiyonu kullanma çünkü array içinde array olunca sorun çıkıyor */
            if (response.data.data) {
                if (setUseState) {
                    setUseState(response.data.data);
                }
            }
            if (callback) {
                callback();
            }
            if (response.data.succeded && response.data.succeded == true) {
                if (succededCallback) {
                    succededCallback();
                }
            }
        }
    }
    else {
        // WgfSnackbarFunc("Bir sorun oluştu.İnternet bağlantınızı kontrol ediniz.", 'warning');
    }
    if (_isSetDefault == true) {
        if (defaultValue) {
            if (setUseState) {
                setUseState(defaultValue);
            }
            if (callback) {
                callback();
            }
        }
    }
}
export const wgfAxiosError = (e, callback, setUseState, defaultValue, errorCallback = null) => {
    if (e) {
        if (e.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(e.response.data);
            console.log(e.response.status);
            console.log(e.response.headers);
        } else if (e.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(e.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', e.message);
        }
        console.log(e.config);

        let _err = "Axios Error.";
        if (e.message) {
            _err += "   " + e.message;
        }
        if (e.response) {
            _err += "   " + e.response;
        }
        console.error("Axios Error", _err);
        WgfSnackbarFunc('Bir sorun oluştu !!!' + _err, 'error');
        wgAxiosResponseAction(null, callback, setUseState, defaultValue);
        if (errorCallback) {
            errorCallback();
        }
        wgfSetError(_err);
        /*
        Aşağıdaki Axios hata tiplerini kullanıcıya göre açıklayarak göster
        >Axios Error Network Error == Servere erişilemedi...
        */
    }

}
export const wgfSetError = (error) => {
    try {
        let _params = {
            value: error
        }
        let _lKey = wgfGetLoginKey();
        let _config = null;

        if (_lKey) {
            _config = {
                headers: {
                    loginKey: _lKey,
                    ApiKey: _lKey
                }
            }
            Axios.post(_appApiUrl + '/app-data/set-error', _params, _config)
        }
    } catch (e) {

        console.error('ERROR', e.message);
    }
}
/*********************************************/
/*********************************************/
/*********************************************/

export const wgfGetLoginKey = () => {
    //Eger loginKey yok ise oturumu kapatsın
    if (wgLoginControl() == true) {
        return getLocalStorage('auth-key');
    }
    return null;
}
/**
 * Text metnini keser ve sonuna üç nokta ekler
 * @param {*} text 
 */
export const wgfTextCropAndDots = (text, maxLength) => {
    if (text && text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
}
export const wfgScrollToBottom = (elementRef) => {
    if (elementRef && elementRef.current) {
        elementRef.current.scrollTo({
            top: elementRef.current.scrollHeight - elementRef.current.clientHeight,
            behavior: "smooth"
        });
    }
}
/**
 * Kullanıcının oturumunun açık olup olmadığını kontrol eder.
 * LoginKey olsa bile bunun içerisindeki oturum süresini kontrol etmek için API'ye request gönder,
 *  olabildiğindece küçük paket gönder çünkü sürekli yapılacak bişey.(hatta ileride belki bunun için ayrı servis yazılabilir.)
 * 
 */
export const wgLoginControl = () => {
    let _key = getLocalStorage('auth-key');
    if (wgfIsNotNull([_key])) {
        return true;
    }
    return false;
}
/**
 * 
 * @param {*} params : Array olarak parametreleri gönderebilirsin
 * @returns 
 */
export const wgfIsNotNull = (params, isShowToastMessage = false) => {
    let _resp = true;
    params.map(item => {
        if (!item ||
            item == null ||
            item == undefined ||
            item == "" ||
            item.length == 0) {
            _resp = false;
        }
    })
    if (isShowToastMessage === true & _resp === false) {
        WgfSnackbarFunc('Zorunlu alanları doldurunuz !!!', 'info');
    }
    return _resp;
}
export const wgfIsNotNullWithCustomMessage = (params, message, toastVariant = 'info') => {
    let _resp = wgfIsNotNull(params);
    if (_resp === false) {
        WgfSnackbarFunc(message, toastVariant);
    }
    return _resp;
}
export const wgfImgToBase64 = (imgIdSelector) => {
    let img = document.getElementById(imgIdSelector);
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL;
    //aşağıdaki ile ham halini döndürür, dosya tipi falan yazmayan.
    //return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
}

/**
 * Int tipindeki mesafe değerini km/mt olacak şekilde hesaplar ve text olarak döndürür.
 * @param {*} distance 
 */
export const wgfDistanceToText = (distance) => {
    if (distance) {
        distance = Number(distance);
        if (distance > 1000) {
            return (distance / 1000).toFixed(0) + ' km';
        } else {
            return distance + ' mt';
        }
    }
    return '';
}
/**
 * Int tipindeki zaman değerini saat/dakika olacak şekilde hesaplar ve döndürür
 * @param {*} tt_ 
 * @returns 
 */
export const wgfTimeToText = (tt_) => {
    let hours = Math.floor(tt_ / 3600);
    let minutes = 0;
    if (hours > 0) {
        tt_ %= 3600;
        minutes = Math.floor(tt_ / 60);
        return `${hours} saat ${minutes} dk.`;
    }
    minutes = Math.floor(tt_ / 60);
    return `${minutes} dk.`;
}


/**
 * Gidecek ilanında Şehir/İlçe/Mahalle adlarını yazılacak formatta ayarlar
 * @param {*} willgo 
 * @param {*} isFromAddress 
 * @returns 
 */
export const wgfJoinWillGoAddress = (willgo, isFromAddress = true) => {
    if (isFromAddress) {
        return (`${willgo.fromDistrict}/ ${willgo.fromCountry}`);
    }
    return (`${willgo.toDistrict}/ ${willgo.toCountry}`);
}
/**
 * Başlangıç ve bitiş adreslerini belirli formata göre yazar
 * Şehirler farklı ise FromŞehir>ToŞehir
 * Şehirler aynı İlçeler farklı ise Fromİlçe>ToIlçe
 * İlçe aynı Mah/Köy farklı ise FromDistrict/ToDistrict
 * olacak şekilde ikili olarak gösterir.
 * @param {*} willgo 
 * @param {*} isFromAddress 
 * @returns 
 */
export const wgfFromToAddress = (willgo) => {
    if (willgo.fromCityName != willgo.toCityName) {
        return <WgBox><WgText text={willgo.fromCityName} /> <WgIconArrowRightLong fontSize="sm" /></WgBox>;
    }
    else if (willgo.fromCountry != willgo.toCountry) {
        return <WgBox><WgText text={willgo.fromCountryName} /> <WgIconArrowRightLong fontSize="sm" /> <WgText text={willgo.toCountryName} /> </WgBox>;
    }
    else {
        return <WgBox><WgText text={willgo.fromDistrictName} /> <WgIconArrowRightLong fontSize="sm" /> <WgText text={willgo.toDistrictName} /></WgBox>;
    }
}
/*
Array formatındaki loaction verisindeki değerleri lat/lng olarak döndürür
 */
export const wgfLocationConvertLatLng = (location) => {
    if (location) {
        return {
            lat: location[0] ? Number(location[0]) : 0,
            lng: location[1] ? Number(location[1]) : 0
        }
    }
    return {
        lat: 0,
        lng: 0
    };
}
export const wgfWillGoLocationToArray = (willGo, isFrom = true) => {
    if (willGo) {
        if (isFrom == true) {
            if (isHasLocationValues(willGo.fromLocation)) {
                return [Number(willGo.fromLocation.lat), Number(willGo.fromLocation.lng)];
            }
        }
        else {
            if (isHasLocationValues(willGo.toLocation)) {
                return [Number(willGo.toLocation.lat), Number(willGo.toLocation.lng)];
            }
        }
    }
    return [];
}
export const isHasLocationValues = (locationLatLng) => {
    return (locationLatLng && locationLatLng.lat && locationLatLng.lng)
}
export const wgfLocationValuesToString = (locationLatLng) => {
    return { lat: locationLatLng.lat ? locationLatLng.lat.toString() : null, lng: locationLatLng.lng ? locationLatLng.lng.toString() : null }
}
export const wgfLocationValuesToNumber = (locationLatLng) => {
    return { lat: locationLatLng.lat ? Number(locationLatLng.lat) : null, lng: locationLatLng.lng ? Number(locationLatLng.lng) : null }
}

/*********************************************************/
/*********************************************************/
/*********************************************************/

/**
 * * Location bilgisi ile adres bilgisini bulur. Geçici olarak Nominatim API kullan daha sonra bu servisi servere yükle
 * @param {*} lat 
 * @param {*} lng 
 * @param {*} setState 
 * @param {*} callback = geriye parametre döndürecektir
 */
const WgLocationToAddress = (lat, lng, setState = null, callback = null) => {
    let _url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}&zoom=19&addressdetails=1`;
    let _resp = {
        osm_id: null,
        lat: null,
        lng: null,
        address: null,
        district: null,
        country: null,
        city: null,
        region: null,
        postcode: null,
        boundingbox: null
    }
    fetch(_url).then(resp => resp.json()).then(data => {
        if (wgfIsNotNull([data])) {
            _resp = {
                osm_id: data.osm_id,
                lat: data.lat,
                lng: data.lon,
                address: data.display_name,
                district: data.address.suburb,
                country: data.address.town,
                city: data.address.province,
                region: data.address.region,
                postcode: data.address.postcode,
                boundingbox: data.boundingbox
            }
            if (setState) {
                setState(_resp);
            }
            if (callback) {
                callback(_resp);
            }
        }
    })
}
export default WgLocationToAddress;

/********************************************************************/
/**
 * Adres bilgileriş ile bu fonksiyon tetiklenir ve diger private fonksiyonları teker teker deneyerek adresin konumunu bulup dondurur.
 * @param {*} addressArray
 * @param {*} setUseSate 
 * @param {*} callback 
 */
export const wgGetAddresLocation = (districtId, addressArray, setUseSate, callback = null) => {
    /**
    * NOT: Url'lere "Türkiye" ifadesi eklenemesi gerek, bunu da aşağıda ekliyor sonrakilerde gerek yok.
    */
    //ilk once Veritabanında ara yoksa Api kullan
    wgfAxiosGetAndRespParams('district/get-location/' + districtId, (resp) => {
        let _data = resp.data ? resp.data.data : null;
        if (isHasLocationValues(_data)) {
            if (wgfIsNotNull([_data.lat, _data.lon])) {
                let _loc = {
                    lat: Number(_data.lat),
                    lng: Number(_data.lng)
                };
                if (setUseSate) {
                    setUseSate(_loc);
                }
                if (callback) {
                    callback(_loc);
                }
            }
            else if (Array.isArray(addressArray)) {
                addressArray.unshift("Türkiye");
                getAddressLocationApi0(districtId, addressArray, setUseSate, callback);
            }
        }
        else if (Array.isArray(addressArray)) {
            addressArray.unshift("Türkiye");
            getAddressLocationApi0(districtId, addressArray, setUseSate, callback);
        }
    });

}
const apiSetLocation = (districtId, lat, lng, setUseSate = null, callback = null) => {
    if (wgfIsNotNull([lat, lng])) {
        let _loc = {
            lat: lat,
            lng: lng
        };
        /*
         NOT!!!
         Tekrar api'yi kullanmamak için ve porformans için kendi veritabanına kaydetdilecektir.
         TblDistrict tablosuna ID numarası ile kaydedilmektedir.
         Sorgulama yapılıkende ilk once veritabanında arayacaktır.
        */
        if (districtId) {
            if (districtId > 0) {
                wgfAxiosPost('district/set-location', { id: districtId, lat: lat, lng: lng }, null, null, null);
            }
        }
        if (setUseSate) {
            setUseSate(_loc);
        }
        if (callback) {
            callback(_loc);
        }
    }
}
/***********************************/
/*mapbox*/
const getAddressLocationApi0 = (districtId, addressArray, setUseSate = null, callback = null) => {
    //https://nominatim.openstreetmap.org/search?format=json&city=Sivas&=state=Türkiye+sivas+ulaş+baharözü&country=Türkiye&limit=1
    const _token = "pk.eyJ1IjoiYWhtZXRtdGwiLCJhIjoiY2tzZGM1NzZyMG92MDJvcXFtbTN0b2thNyJ9.IQ8TXysQUWDC_9IALfencw";
    let _url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${addressArray.join('%20')}.json?limit=1&access_token=${_token}`;
    fetch(_url)
        .then((resp_) => resp_.json())
        .then((resp_) => {
            if (resp_ && resp_.features && resp_.features.length > 0) {
                //mapbox'ta location cerileri leflaet'e gore ters gelmekte
                apiSetLocation(districtId, resp_.features[0].center[1].toString(), resp_.features[0].center[0].toString(), setUseSate, callback);
            }
            else {
                getAddressLocationApi1(districtId, addressArray, setUseSate, callback);
            }
        }).catch((e) => {
            getAddressLocationApi1(districtId, addressArray, setUseSate, callback);
            console.error(e);
        });
}
/*nominatim*/
const getAddressLocationApi1 = (districtId, addressArray, setUseSate = null, callback = null) => {
    //https://nominatim.openstreetmap.org/search?format=json&city=Sivas&=state=Türkiye+sivas+ulaş+baharözü&country=Türkiye&limit=1
    let _url = `https://nominatim.openstreetmap.org/search?format=json&country=Türkiye&city=${addressArray[1]}&=state=${addressArray.join('+')}&limit=1`;
    fetch(_url)
        .then((resp_) => resp_.json())
        .then((resp_) => {
            if (wgfIsNotNull([resp_, resp_[0]]) === true) {
                apiSetLocation(districtId, resp_[0].lat, resp_[0].lon, setUseSate, callback);
            }
            else {
                getAddressLocationApi3(districtId, addressArray, setUseSate, callback);
            }
        }).catch((e) => {
            getAddressLocationApi3(districtId, addressArray, setUseSate, callback);
            console.error(e);
        });
}
/*GOOGLE*/
/*
şimdilik es geç fatura istiyor
"You must enable Billing on the Google Cloud Project at https://console.cloud.google.com/project/_/billing/enable Learn more at https://developers.google.com/maps/gmp-get-started"
*/
const getAddressLocationApi2 = (districtId, addressArray, setUseSate = null, callback = null) => {
    let _url = "https://maps.googleapis.com/maps/api/geocode/json?new_forward_geocoder=true&key=AIzaSyBSk7xrmBvWDhGSHebID-D0Gh5fU6XNPO0&address=" + addressArray.join('+');
    fetch(_url)
        .then((resp_) => resp_.json())
        .then((resp_) => {
            if (wgfIsNotNull([resp_.results[0]])) {
                apiSetLocation(districtId, resp_.results[0].geometry.location.lat, resp_.results[0].geometry.location.lng, setUseSate, callback);
            }
            else {
                getAddressLocationApi3(districtId, addressArray, setUseSate, callback);
            }
        }).catch((e) => {
            getAddressLocationApi3(districtId, addressArray, setUseSate, callback);
        });
}
/*MAPQUEST*/
const getAddressLocationApi3 = (districtId, addressArray, setUseSate = null, callback = null) => {
    let _url = "https://api.locationiq.com/v1/autocomplete.php?limit=1&key=pk.0c9fbb21fc7fa9bcbcbd05e96209d479&q=" + addressArray.join(' ');
    fetch(_url)
        .then((resp_) => resp_.json())
        .then((resp_) => {
            if (wgfIsNotNull([resp_[0]])) {
                apiSetLocation(districtId, resp_[0].lat, resp_[0].lon, setUseSate, callback);
            }
            else {
                getAddressLocationApi4(districtId, addressArray, setUseSate, callback);
            }
        }).catch((e) => {
            getAddressLocationApi4(districtId, addressArray, setUseSate, callback);
        });
}
/*LOCATION IQ*/
const getAddressLocationApi4 = (districtId, addressArray, setUseSate = null, callback = null) => {
    let _url = "https://open.mapquestapi.com/geocoding/v1/address?key=h82Fp348A1Rch0wIQtf4i8w70CsLZ8PW&location=" + addressArray.join(',');
    fetch(_url)
        .then((resp_) => resp_.json())
        .then((resp_) => {
            if (wgfIsNotNull([resp_.results[0]])) {
                apiSetLocation(districtId, resp_.results[0].locations[0].latLng.lat, resp_.results[0].locations[0].latLng.lng, setUseSate, callback);
            }
        }).catch((e) => console.error(e));
}

/**********************************************************************/
/*weather*/
export const weatherDateTimeToClock = (date) => {
    return wgfDateTimeFormat((new Date(date * 1000)), 'HH:mm');
}

export const weatherTimeColors = {
    "00:00": '#000001',
    "03:00": '#00000b',
    "06:00": '#00427D',
    "09:00": '#006FD3',
    "12:00": '#0086FE',
    "15:00": '#006FD3',
    "18:00": '#00427D',
    "21:00": '#001528',
}
/**
 * 
 * @param {*} imageName 
 * @param {*} type png/svg/dark
 */
export const weatherGetImageSrc = (imageName, type) => {
    if (type == 'dark') {
        return `https://files.gidecekvar.com/images/openweather/dark/${imageName}.png`
    } else if (type = "svg") {
        return `https://files.gidecekvar.com/images/openweather/${imageName}.svg`;
    }
    return `https://files.gidecekvar.com/images/openweather/${imageName}.png`;
}
/**
 * Hava durumu bilgisi veritabanında string(json) tipte tuutlmakta ve dönen veriyi önve json formatına çevirir.
 * Daha sonra her bir saatin bilgisini tarihi, derecesi,açıklaması,icon'u ile birlikte döndürür
 * @param {*} data 
 * @returns 
 */
export const wgfWeatherToDetails = (data) => {
    var exampleData = {
        "cod": "200",
        "message": 0,
        "cnt": 6,
        "list": [{
            "dt": 1682294400,
            "main": { "temp": 5.53, "feels_like": 4.58, "temp_min": 5.53, "temp_max": 5.88, "pressure": 1012, "sea_level": 1012, "grnd_level": 856, "humidity": 96, "temp_kf": -0.35 },
            "weather": [{ "id": 803, "main": "Clouds", "description": "parçalı bulutlu", "icon": "04n" }],
            "clouds": { "all": 80 },
            "wind": { "speed": 1.48, "deg": 261, "gust": 2.03 },
            "visibility": 10000,
            "pop": 0.77,
            "sys": { "pod": "n" },
            "dt_txt": "2023-04-24 00:00:00"
        }],
        "city": { "id": 300617, "name": "Sivas Province", "coord": { "lat": 39.7916, "lon": 37.0019 }, "country": "TR", "population": 738433, "timezone": 10800, "sunrise": 1682304129, "sunset": 1682353076 }
    }
    if (data) {
        let _data = JSON.parse(data);
        if (_data && _data.cod == "200") {
            let _resp = [];
            _data.list && _data.list.map(item => {
                _resp.push({
                    date: wgfDateTimeFormat((new Date(item.dt * 1000)), 'dddd HH:mm'),
                    temp: item.main.temp.toFixed(0),
                    description: item.weather ? item.weather[0].description : "",
                    icon: item.weather ? item.weather[0].icon : "",
                });
            })
            return {
                weatherCityId: _data.city.id,
                items: _resp
            };
        }
    }
    return null;
}

/**********************************************************************/