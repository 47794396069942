

import React, { useContext, useEffect, useState } from "react";
import WgModal from "../../components_only_this_platform/elements/WgModal";
import { weatherGetImageSrc,wgfWeatherToDetails, wgfAxiosGet, wgfAxiosPost, wgfDateTimeFormat, wgfObjectClone } from "../../components/functions/WgFunctions";

import { WgAppContext } from "../../App";
import WgCarousel from "../../components_only_this_platform/elements/WgCarousel";
import WgJustifyContent from "../../components/ui/WgJustifyContent";
import WgImage from "../../components_only_this_platform/elements/WgImage";
import WgBox from "../../components_only_this_platform/elements/WgBox";
import WgButtonToggle from "../../components_only_this_platform/elements/WgButtonToggle";
import WgSwitch from "../../components_only_this_platform/elements/WgSwitch";
import { wgfMapDatas } from "../../components_only_this_platform/functions/WgMapScripts";
import WgForWmvSearchService from "../../components/ui/willGoMapViewComponents/WgForWmvSearchService";
import WgForWmvMyWillGos from "../../components/ui/willGoMapViewComponents/WgForWmvMyWillGos";
import WgForWmvWillGos from "../../components/ui/willGoMapViewComponents/WgForWmvWillGos";
import WgForWmvRoadStatus from "../../components/ui/willGoMapViewComponents/WgForWmvRoadStatus";
import WgWillGoFormNew from "../../components_only_this_platform/ui/WgWillGoFormNew";
import WgForWmvMyOffers from "../../components/ui/willGoMapViewComponents/WgForWmvMyOffers";
import { estimatedTimeToText, wgRouteAddresses } from "../../components/WgStaticValues";
import WgPageBody from "../../components/ui/WgPageBody";
import { wgsButton } from "../../components/ui/WgStyles";
import { setUserInfo } from "../../stores/rdxUserInfo";
import { useDispatch, useSelector } from "react-redux";
import WgAvatar from "../../components/elements/WgAvatar";


import WgButton from "../../components_only_this_platform/elements/WgButton";
import WgSpaceVertical from "../../components_only_this_platform/elements/WgSpaceVertical";
import WgText from "../../components_only_this_platform/elements/WgText";

var weatherItems = null; //state kullandığımda componnet render edildiğinde null oluyordu ve Id kontrolü yapılamıyordu.
const _tabButtons = {
  createWillGo: 0,
  myWillGos: 1,
  searchService: 2,
  willGos: 3,
  myOffers: 4,
  options: 5,
  createChauffeurAccount: 6
}

const WillGoMapView = () => {
  const dispatch = useDispatch();
  const [startChangeCounter, setStartChangeCounter] = useState(0);
  const [reduxData, setReduxData] = useState(useSelector(state => state.rdxStateUserInfo));
  const { wgAppObjects, setWgGenWillGos, setWgGenSelectedWillGo, wgGenRoadStatusTypes, setWgGenRoadStatusTypes, setWgGenMarkerChauffeurs, wgGenLoginInfo, setWgGenMarkerWillGos, setWgGenMarkerRoadStatus, setWgGenMarkerWeather, wgIsRouteStartMyLocation, setWgIsRouteStartMyLocation, appData } = useContext(WgAppContext);
  const [modalSelectedWeatherShow, setModalSelectedWeatherShow] = React.useState(false); //seçilen hava durumu detayını modal ile gösterir

  //DATAS

  const [roadStatusses, setRoadStatusses] = useState(null);
  const [roadStatusShow, setRoadStatusShow] = useState(false);
  const [weathers, setWeathers] = useState(null);
  const [weather, setWeather] = useState(null); //modal ile gösterilecek olan hava durumu bilgisi
  const [weatherShow, setWeatherShow] = useState(false);

  const [activeTab, setActiveTab] = useState(((wgGenLoginInfo ? wgGenLoginInfo.roleIsChauffeur == true : false) ? _tabButtons.willGos : _tabButtons.myWillGos));

  useEffect(() => {
    if (reduxData) {
      setRoadStatusShow(reduxData.showRoadStatus);
      setWeatherShow(reduxData.showWeather);
    }
  }, [reduxData])
  /********************************************************************/
  /********************************************************************/
  /********************************************************************/
  /*WEATHER*/
  useEffect(() => {
    if (appData && appData.cities && weatherShow == true) {
      //hava durumu yuklu degilse yukle
      if (!weathers) {
        wgfAxiosGet('weather/get-all', null, setWeathers, null);
      }
      else {
        createWeatherMarkers();
      }
    }
    else {
      setWgGenMarkerWeather(null);
    }
  }, [appData, weatherShow])
  useEffect(() => {
    if (weather) {
      weatherItems = wgfWeatherToDetails(weather);
      setModalSelectedWeatherShow(true);
      if (weatherShow == true) {
        createWeatherMarkers();
      }
    }
  }, [weather])
  useEffect(() => {
    if (weathers) {
      if (weatherShow == true) {
        createWeatherMarkers();
      }
    }
  }, [weathers])
  const createWeatherMarkers = () => {
    //setWgGenMarkerWeather
    let _markers = [];
    weathers && weathers.map(item => {
      let _city = appData.cities.find(f => f.value == item.cityId);
      if (_city) {
        let _wthr = JSON.parse(item.weather);
        let _weather = _wthr.list[0];
        if (_weather) {
          _markers.push(
            {
              position: _city.location,
              iconUrl: weatherGetImageSrc(_weather.weather[0].icon, 'dark'),
              iconSize: wgfMapDatas.markerIconSize,
              callback: null,
              popup: {
                title: '',
                body: (
                  <>
                    <WgImage src={weatherGetImageSrc(_weather.weather[0].icon, 'svg')} width={70} />
                    <WgBox variant="h6" component="div">{_weather.main.temp}&deg;C</WgBox>
                    <WgBox>{_weather.weather[0].description}</WgBox>
                    <WgButton style={wgsButton.normal} onClick={() => {
                      if (weatherItems && weatherItems.weatherCityId == _wthr.city.id) {
                        setModalSelectedWeatherShow(true);
                      }
                      else {
                        wgfAxiosGet('weather/get/' + _city.value, null, setWeather, null, null, null, null);
                      }
                    }} title="Tümü" />
                  </>
                )
              }
            }
          )
        }
      }
    })
    setWgGenMarkerWeather(_markers)
  }
  /********************************************************************/
  /********************************************************************/
  /********************************************************************/
  useEffect(() => {
    //yol durum bilgileri yuklu degilse yukle
    if (roadStatusShow == true) {
      if (!roadStatusses) {
        if (!wgGenRoadStatusTypes) {
          wgfAxiosGet('road-status/get-all-status-types', getRoadStatusses, setWgGenRoadStatusTypes, null);
        }
        else {
          getRoadStatusses();
        }
      }
    }
    createRoadStatusMarkers();
  }, [roadStatusShow])
  const getRoadStatusses = () => {
    wgfAxiosGet('road-status/get-all-statusses', null, setRoadStatusses, null);
  }
  useEffect(() => {
    createRoadStatusMarkers();
  }, [roadStatusses])
  const createRoadStatusMarkers = () => {
    let _markers = [];
    if (roadStatusses && wgGenRoadStatusTypes && roadStatusShow == true) {
      roadStatusses.map((item) => {
        let _statusGroup = wgGenRoadStatusTypes.find(f => f.id == item.typeId);
        _markers.push(
          {
            position: item.location,
            iconUrl: _statusGroup.icon,
            iconSize: wgfMapDatas.markerIconSize,
            callback: null,
            popup: {
              title: '',
              body: (
                <>
                  <WgJustifyContent justifyContent="center">
                    <WgImage width={70} src={_statusGroup.icon} />
                  </WgJustifyContent>
                  <WgBox>{_statusGroup.name}</WgBox>
                  <WgBox>Tahmini süresi {estimatedTimeToText(item.estimatedTime)}</WgBox>
                  <WgBox>Kayıt tarihi {wgfDateTimeFormat(item.date, 'dd MMM yyyy HH:mm')}</WgBox>
                </>
              )
            }
          }
        )
      })
    }
    setWgGenMarkerRoadStatus(_markers);
  }
  /********************************************************************/
  /********************************************************************/
  /********************************************************************/
  useEffect(() => {
    setWgGenWillGos(null);
    wgAppObjects.route.clearRoute();
    setWgGenMarkerChauffeurs(null);
    setWgGenSelectedWillGo(null);
    //yol durumu secildiginde, ilanlara ait markerları sil
    setWgGenMarkerWillGos(null);

  }, [activeTab])
  useEffect(() => {
    /*Acilista herbir state icin ilk change olayi tetiklenecek,onlari pas gecmek icin saydir.*/
    if (startChangeCounter > 1) {
      wgfAxiosPost('chauffeur/change-show', {
        showRoadStatus: roadStatusShow,
        showWeather: weatherShow
      }, null, null, null, () => {
        let _temp = wgfObjectClone(reduxData);
        _temp.showRoadStatus = roadStatusShow;
        _temp.showWeather = weatherShow;
        dispatch(setUserInfo(_temp));
      }, null, false);
    }
    let _c = startChangeCounter;
    setStartChangeCounter(++_c);
  }, [roadStatusShow, weatherShow])
  let carouselNavbarButtons=[];
  carouselNavbarButtons.push(<WgButtonToggle istabmenu={true} selected={activeTab === _tabButtons.createWillGo} onClick={() => setActiveTab(_tabButtons.createWillGo)} size="small">İlan Ekle</WgButtonToggle>);
  if(wgGenLoginInfo ? wgGenLoginInfo.roleIsChauffeur == true : false){
    carouselNavbarButtons.push(<WgButtonToggle istabmenu={true} selected={activeTab === _tabButtons.willGos} onClick={() => setActiveTab(_tabButtons.willGos)} size="small">İlanlar</WgButtonToggle>);
    carouselNavbarButtons.push(<WgButtonToggle istabmenu={true} selected={activeTab === _tabButtons.myOffers} onClick={() => setActiveTab(_tabButtons.myOffers)} size="small">Tekliflerim</WgButtonToggle>);
  }
  carouselNavbarButtons.push(<WgButtonToggle istabmenu={true} selected={activeTab === _tabButtons.myWillGos} onClick={() => setActiveTab(_tabButtons.myWillGos)} size="small">İlanlarım</WgButtonToggle>);
  carouselNavbarButtons.push(<WgButtonToggle istabmenu={true} selected={activeTab === _tabButtons.searchService} onClick={() => setActiveTab(_tabButtons.searchService)} size="small">Şoför Ara</WgButtonToggle>);
  
  if(wgGenLoginInfo ? wgGenLoginInfo.roleIsChauffeur == true : false){
    carouselNavbarButtons.push(<WgButtonToggle istabmenu={true} selected={activeTab === _tabButtons.options} onClick={() => setActiveTab(_tabButtons.options)} size="small">Seçenekler</WgButtonToggle>);
  }
  else{
    carouselNavbarButtons.push(<WgButtonToggle istabmenu={true} selected={activeTab === _tabButtons.createChauffeurAccount} onClick={() => setActiveTab(_tabButtons.createChauffeurAccount)} size="small">Şoför Hesabı Oluştur</WgButtonToggle>);
  }
  return (
    <>
      <WgCarousel spaceBetween={1} items={carouselNavbarButtons}/>
      <WgPageBody>
        <WgBox style={{ paddingTop: '20px' }}>
          <WgBox>
            {(activeTab === _tabButtons.createWillGo) && (
              <WgWillGoFormNew />
            )}
            {(activeTab === _tabButtons.myWillGos) && (
              <WgForWmvMyWillGos setActiveTab={setActiveTab} createWillGoTabKey={_tabButtons.createWillGo} />
            )}
            {(activeTab === _tabButtons.searchService) && (
              <WgForWmvSearchService />
            )}
            {(activeTab === _tabButtons.willGos) && (
              <WgForWmvWillGos />
            )}

            {(activeTab === _tabButtons.myOffers) && (
              <WgForWmvMyOffers setActiveTab={setActiveTab} willGosTabKey={_tabButtons.willGos} />
            )}
            {(activeTab === _tabButtons.options) && (
              <>
                {/*<WgSwitch checked={wgIsRouteStartMyLocation} onChange={(e) => setWgIsRouteStartMyLocation(e.target.checked)} label="Rotayı bulunduğum konumdan başlat." /> */}
                <WgSwitch propstate={[roadStatusShow, setRoadStatusShow]} label="Yol Durumunu Göster" />
                <WgSwitch propstate={[weatherShow, setWeatherShow]} label="Hava Durumunu Göster" />
                <hr />
                <WgForWmvRoadStatus />
              </>
            )}
            {(activeTab === _tabButtons.createChauffeurAccount) && (
              <>

                <WgSpaceVertical />
                <WgText variant="h6" align="center" text="Sizde şoför hesabı oluşturarak hizmet verebilirsiniz." />
                <WgJustifyContent justifyContent="center">
                  <WgButton onClick={() => wgAppObjects.functions.goToLink(wgRouteAddresses.account.registerChauffeur)} title="Hesap Oluştur" />
                </WgJustifyContent>
              </>
            )}
          </WgBox>
        </WgBox>

      </WgPageBody>

      {
        weatherItems && (
          <WgModal maxwidth="300px" open={modalSelectedWeatherShow} hiddenHeader={true} onClose={() => setModalSelectedWeatherShow(false)}>
            {
              weatherItems.items.map((item, index) => {
                return (
                  <WgJustifyContent key={index} justifyContent="flex-start">
                    <WgBox>
                      <WgAvatar src={weatherGetImageSrc(item.icon, 'svg')} style={{ width: '70px', height: '70px' }} />
                    </WgBox>
                    <WgBox>
                      <WgText variant="h6" text={(item.temp + '°C  / ' + item.description)} />
                      <WgText variant="info" text={item.date} />
                    </WgBox>
                  </WgJustifyContent>
                )
              })
            }
          </WgModal>
        )
      }
    </>
  )
}
export default WillGoMapView;