import { useState } from "react";
import { wgfAxiosPost, wgfIsNotNull } from "../functions/WgFunctions";
import WgJustifyContent from "./WgJustifyContent";
import WgBox from "../../components_only_this_platform/elements/WgBox";
import WgInput from "../../components_only_this_platform/elements/WgInput";
import { WgfSnackbarFunc } from "../../App";
import WgModal from "../../components_only_this_platform/elements/WgModal";
import { wgsButton } from "./WgStyles";
import WgButton from "../../components_only_this_platform/elements/WgButton";
const passwordInitial = {
  currentPassword: '',
  newPassword: '',
  newPasswordReEnter: '',
};
const WgPasswordChange = ({ showModal, setShowModal }) => {
  const [passwordData, setPassworData] = useState(passwordInitial);
  const changePassword = () => {
    if (wgfIsNotNull([passwordData.newPassword, passwordData.newPasswordReEnter], true)) {
      if (passwordData.newPassword === passwordData.newPasswordReEnter) {
        wgfAxiosPost('account/passwordChange', {
          CurrentPassword: passwordData.currentPassword,
          NewPassword: passwordData.newPassword
        }, null, null, null, () => {
          setPassworData(passwordInitial);
        }, null, true);
      }
      else {
        WgfSnackbarFunc('Yeni parola ve tekrarı aynı değil !!!', 'info');
      }
    }
  }

  return (
    <WgModal maxwidth="300px" open={showModal} onClose={() => setShowModal(false)}>
      <WgBox style={{ marginBottom: '10px' }}>
        <WgInput type="password" placeholder="Geçerli parolanız" propstate={[passwordData, setPassworData, "currentPassword"]} />
        <WgInput type="password" placeholder="Yeni parola" propstate={[passwordData, setPassworData, "newPassword"]} />
        <WgInput type="password" placeholder="Yeni parolayı tekrar yazınız" propstate={[passwordData, setPassworData, "newPasswordReEnter"]} />
      </WgBox>
      <WgJustifyContent justifyContent="center">
        <WgButton style={wgsButton.normal} onClick={() => changePassword()} title="Parolayı Değiştir" />
      </WgJustifyContent>
    </WgModal>
  )
}
export default WgPasswordChange;