import { useContext, useEffect } from "react";
import { WgAppContext } from "../../../App";
import WgBox from "../../../components_only_this_platform/elements/WgBox";
import WgWillGoCard from "../willGoDetails/WgWillGoCard";
import WgJustifyContent from "../WgJustifyContent";

import { wgfMapDatas } from "../../../components_only_this_platform/functions/WgMapScripts";
import { wgfFromToAddress } from "../../functions/WgFunctions";



import { wgsButton } from "../WgStyles";
import WgLogoSvg from "../../../components_only_this_platform/ui/WgLogoSvg";
import WgButton from "../../../components_only_this_platform/elements/WgButton";
import WgText from "../../../components_only_this_platform/elements/WgText";

const WgForWmvMyWillGos = ({ setActiveTab, createWillGoTabKey }) => {
    const { setWgGenMarkerWillGos, wgAppObjects, appData, wgGenWillGos } = useContext(WgAppContext);
    useEffect(() => {
        wgAppObjects.functions.getMyWillGos();
    }, [])
    useEffect(() => {
        if (wgGenWillGos) {
            let _markers = [];
            wgGenWillGos.map((item) => {
                _markers.push(
                    {
                        position: item.fromLocation,
                        iconUrl: appData.services.find(f => f.id == item.serviceId).logo,
                        iconSize: wgfMapDatas.markerIconSize,
                        callback: null,
                        popup: {
                            title: '',
                            body: (
                                <>
                                    <WgBox>{wgfFromToAddress(item)}</WgBox>
                                    <WgButton style={wgsButton.normal} size="small" onClick={() => {wgAppObjects.functions.getWillGoDetails(item, true)}} title="Detay"/>
                                </>
                            )
                        }
                    }
                )
            })
            setWgGenMarkerWillGos(_markers);
        }
    }, [wgGenWillGos])
    return (
        (wgGenWillGos && wgGenWillGos.length > 0) ? wgGenWillGos.map((item, index) => {
            return (
                <WgBox key={index} style={{ paddingBottom: '10px' }}>
                    <WgWillGoCard willGo={item} />
                </WgBox>
            );
        }) : (
            <>
                <WgJustifyContent justifyContent="center" mb="20px">
                    <WgLogoSvg width={77}  />
                </WgJustifyContent>
                <WgText variant="h5" align="center" text="Henüz ilanınız bulunmamaktadır." />
                <WgText variant="info" align="center" text="İlan ekleyip hizmet almaya başlayabilisiniz." />
                <WgJustifyContent justifyContent="center" mt="15px">
                    <WgButton style={wgsButton.normal} onClick={() => setActiveTab(createWillGoTabKey)} title="İlan Ekle"/>
                </WgJustifyContent>
            </>
        )
    )
}
export default WgForWmvMyWillGos;