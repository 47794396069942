import WgRating from "../elements/WgRating";
import WgText from "../../components_only_this_platform/elements/WgText";
import WgAvatar from "../elements/WgAvatar";
import WgJustifyContent from "./WgJustifyContent";
import WgBox from "../../components_only_this_platform/elements/WgBox";
import WgUserCardActionButtons from "./WgUserCardActionButtons";

import { useContext } from "react";
import { WgAppContext } from "../../App";
import { wgfMapGoToPosition } from "../../components_only_this_platform/functions/WgMapScripts";

/*
userData={
name:'name surname',
phone:null,
profileImage:null,
email:null,
rating:null,
roleIsChauffeur,
location:{lat:null,lng:null}
}
*/

const WgUserNameImageCard = ({ userData, roleIsChauffeur, hideCommentsOrProfile, hideEmail, hideMessage, hidePhone, hideLocation, showMyData, showLogOut }) => {
    const { wgAppObjects } = useContext(WgAppContext);
    const handleClick = () => {
        if (userData.roleIsChauffeur == true && userData.location) {
            wgfMapGoToPosition(wgAppObjects.mainMap.map, userData.location);
        }
    }
    return (
        (userData) && (
            <WgJustifyContent justifyContent="flex-start" mt="10px" mb="10px" alignItems="flex-start">
                <WgBox onClick={handleClick} style={{ width: '60px', paddingTop: '10px' }}>
                    <WgAvatar
                        src={(userData.profileImage)}
                        style={{ width: 50, height: 50, marginRight: 7 }}
                    />
                </WgBox>
                <WgBox style={{ width: 'calc(100% - 60px)' }}>
                    <WgJustifyContent justifyContent="space-between" alignItems="flex-start">
                        <WgBox onClick={handleClick}>
                            <WgRating value={userData.rating} />
                            <WgText variant="h5" text={userData.firstName + " " + userData.lastName}/>
                        </WgBox>
                        <WgBox>
                            <WgUserCardActionButtons userData={userData} roleIsChauffeur={roleIsChauffeur} hideCommentsOrProfile={hideCommentsOrProfile} hideEmail={hideEmail} hideMessage={hideMessage} hidePhone={hidePhone} hideLocation={hideLocation} showMyData={showMyData} showLogOut={showLogOut} />
                        </WgBox>
                    </WgJustifyContent>
                    {
                        (userData.phone && (hidePhone == null || hidePhone == false)) && (
                            <WgText variant="info" size="sm" text={userData.phone} />
                        )
                    }
                    {
                        (userData.email && (hideEmail == null || hideEmail == false)) && (
                            <WgText variant="info" size="sm" text={userData.email} />
                        )
                    }
                </WgBox>
            </WgJustifyContent>
        )
    )
}
export default WgUserNameImageCard;