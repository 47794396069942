import { createSlice } from "@reduxjs/toolkit"
export const userInfoInitialState = {
  isLogin: false,
  firstName:null,
  lastName:null,
  profileImage:null,
  roleIsChauffeur:false,
  loginKey:null,
  rating:0,
  showRoadStatus:false,
  showWeather:false
}
const rdxUserInfo = createSlice({
  name: 'reduxUserInfo',
  initialState:userInfoInitialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.isLogin = action.payload.isLogin;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.profileImage = action.payload.profileImage;
      state.roleIsChauffeur = action.payload.roleIsChauffeur;
      state.loginKey = action.payload.loginKey;
      state.rating = action.payload.rating;
      state.showRoadStatus = action.payload.showRoadStatus;
      state.showWeather = action.payload.showWeather;
    },
    deleteUserInfo: (state, action) => {
      state.isLogin = userInfoInitialState.isLogin;
      state.firstName = userInfoInitialState.firstName;
      state.lastName = userInfoInitialState.lastName;
      state.profileImage = userInfoInitialState.profileImage;
      state.roleIsChauffeur = userInfoInitialState.roleIsChauffeur;
      state.loginKey = userInfoInitialState.loginKey;
      state.rating = userInfoInitialState.rating;
      state.showRoadStatus = userInfoInitialState.showRoadStatus;
      state.showWeather = userInfoInitialState.showWeather;
    }
  }
})
export const { setUserInfo, deleteUserInfo } = rdxUserInfo.actions;
export default rdxUserInfo.reducer;